import axios, { AxiosInstance } from "axios";
import api from "./api";
import { ModeloUsuario } from "../models/usuario";
import { TipoMensagem } from "../config/tipoMensage";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";



class ApiAutenticação {

  private api: AxiosInstance;
  // private t: TFunction<"translation", undefined>;
  constructor() {
    this.api = axios.create({
      baseURL: (process.env.REACT_APP_BASE_BACK? process.env.REACT_APP_BASE_BACK+ "/api" : "")
    });
    

  }
  getInfoByEmail(email: string): Promise<ModeloUsuario> {
    return new Promise((resolve, reject) => {
      return api.get(`/usuario_email?email=${email}`).then((res) => {
        if (res.data) {
          return resolve(res.data);
        }
        else {
          return reject("E-mail não autorizado");
        }
      }).catch((error) => {
        return reject();
      });
    });
  }
  checkEmail(email: string): Promise<ModeloUsuario> {
    return new Promise((resolve, reject) => {
      return api.get(`/usuario_verifica_email?email=${email}`).then((res) => {
        if (res.data && res.data) {
          return resolve(res.data);
        }
        else {
          return reject(1);
        }
      }).catch((error) => {
        return reject();
      });
    });
  }

  current(): Promise<ModeloUsuario | null> {
    return new Promise((resolve, reject) => {
      return this.api.get("/.auth/me").then((res) => {
        if (res.data.clientPrincipal) {
          const usuario = new ModeloUsuario();
          usuario.des_email = res.data.clientPrincipal.userDetails;
          return resolve(usuario);
        } else {
          return resolve(null);
        }
      }).catch((error) => {
        return resolve(null);
      });
    });
  }
}


export default new ApiAutenticação();
