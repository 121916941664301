import moment from "moment";
import { ModeloBasico } from "../models/basico";
import { ModeloBandeira } from "../models/bandeira";
import { getI18n, useTranslation } from "react-i18next";

export function mergeSortBandeira(arr: ModeloBandeira[]): ModeloBandeira[] {
  if (arr.length <= 1) {
    return arr;
  }

  const meio = Math.floor(arr.length / 2);
  const metadeEsquerda = arr.slice(0, meio);
  const metadeDireita = arr.slice(meio);

  return merge(mergeSortBandeira(metadeEsquerda), mergeSortBandeira(metadeDireita));
}

function merge(arrEsquerda: ModeloBandeira[], arrDireita: ModeloBandeira[]) {
  let resultado: ModeloBandeira[] = [];
  let indexEsquerda = 0;
  let indexDireita = 0;

  while (indexEsquerda < arrEsquerda.length && indexDireita < arrDireita.length) {
    if (arrEsquerda[indexEsquerda].des_bandeira < arrDireita[indexDireita].des_bandeira) {
      resultado.push(arrEsquerda[indexEsquerda]);
      indexEsquerda++;
    } else {
      resultado.push(arrDireita[indexDireita]);
      indexDireita++;
    }
  }

  return resultado.concat(arrEsquerda.slice(indexEsquerda), arrDireita.slice(indexDireita));
}

export function converterArrayParaTextoCsv(array: Array<string>) {
  return array.map((d: any) => `'${d}'`).join(",");
}

export function clonarObjeto(item: any) {
  return JSON.parse(JSON.stringify(item));
}


export function formatarDecimal(item?: any, dinheiro = false, moeda = "BRL") {

  const i18n = getI18n();

  if (item) {
    item = Number.parseFloat(item);

    if (item === 0) return "-";

    if (dinheiro) {

      switch (i18n.language) {
        case 'pt':
          return item.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })

        default:
          return item.toLocaleString("en-US", { style: "currency", currency: 'USD' });
      }
    } else {

      return item.toLocaleString(i18n.language == 'pt' ? "pt-BR" : "en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      });
    }
  } else {
    return "-";
  }
}

export function calcularPorcentagem(item1?: any, item2?: any) {
  if (item1 && item2) {
    item1 = Number.parseFloat(item1);
    item2 = Number.parseFloat(item2);
    return (item1 - item2) / item2;
  } else {
    return 0;
  }
}

export function calcularVariacao(item_antigo?: any, item_novo?: any) {
  if (item_antigo && item_novo) {
    item_antigo = Number.parseFloat(item_antigo);
    item_novo = Number.parseFloat(item_novo);
    return item_novo / item_antigo - 1;
  } else {
    return 0;
  }
}

export function buscarNomeFiltro(
  lista: any,
  valor: string,
  id: string = "id",
  nome: string = "description"
) {
  valor = valor.toString();

  return lista
    .filter((x: any) => valor.split(",").find((y: any) => y == x[id]))
    ?.map((x: any) => x[nome])
    .join(", ");
}

export function formatarPorcentagem(item?: any, precisao = 2) {
  if (item) {
    item = Number.parseFloat(item) * 100;

    return (
      item.toLocaleString("en-US", {
        maximumFractionDigits: precisao,
        minimumFractionDigits: precisao,
      }) + "%"
    );
  } else {
    return "0%";
  }
}
export function formatarPeriodoData(periodo?: string, flg_ano_mes?: boolean, atual?: string) {
  if (!periodo)
    return ""

  var label = "";
  const formatString = periodo.toString().length === 5 ? "S/YYYY" : "SS/YYYY";

  if (flg_ano_mes) {
    label = moment(periodo, "YYYYM").format("MMM/YYYY");
  } else {
    label = moment(periodo, "YYYYS").format(formatString);
  }

  if (label === atual) {
    label += " (atual)";
  }

  return label
}

export function formatarPeriodo(item: string[], flg_ano_mes: boolean): ModeloBasico[] {
  var t = new Date().toISOString();
  var atual = moment(t).add(flg_ano_mes ? 0 : 1, "days").format(flg_ano_mes ? "MMM/YYYY" : "WW/YYYY");
  return item.map(x => {
    var aux = new ModeloBasico();
    aux.codigo = x.toString();
    aux.descricao = formatarPeriodoData(x, flg_ano_mes, atual);
    return aux
  })
}

export function verificarSemanaAtual(item?: string): boolean {
  if (!item)
    return false
  var t = new Date().toISOString()
  var atual = moment(t).add(1, 'days').format("YYYYWW")

  return atual == item
}

export function saoCenariosIguais(obj1: any, obj2: any) {
  obj1 = { ...obj1 }
  obj2 = { ...obj2 }
  const ignorar = ["des_status", "cod_cenario", "flg_tela_planta_cd", "flg_mostrar_resultado", "flg_alterado", "des_filtro_terceiro_centralizador", "des_filtro_terceiro", "dth_insercao", "num_de_produtos_alterado_capitao", "num_de_produtos_alterado_relativo", "num_total", "dth_alteracao", "flg_ativo"]

  ignorar.forEach(element => {
    delete obj1[element]
    delete obj2[element]
  });

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Número diferente de atributos
  }

  for (const key of keys1) {
    if (obj1[key] == "" || obj1[key] == 0) {
      obj1[key] = null
    }
    if (obj2[key] == "" || obj2[key] == 0) {
      obj2[key] = null
    }

    if ((obj1[key] != obj2[key])) {
      // console.log(`cenario diferentes por: ${key}`)
      return false; // Valor diferente em pelo menos um atributo
    }
  }
  return true; // Todos os atributos são iguais
}

export function converterParaFloat(valor: any): number {
  if (valor === undefined || valor === null || valor === "") {
    return 0;
  }

  return parseFloat(valor);
}

export function atualizaTamanhoTabela() {
  const elementoTabela = (document.getElementById("tabela")?.children[0] as HTMLElement);
  if (elementoTabela) {
    const alturaTabela = `calc(100vh - ${elementoTabela.getBoundingClientRect().top}px - 0px)`;
    elementoTabela.style.height = alturaTabela;
  }
}

export function atualizaTamanhoPaineis() {
  const elementoPaineis = (document.getElementById("paineis")?.children[0] as HTMLElement);
  if (elementoPaineis) {
    const alturaPaineis = `calc(100vh - ${elementoPaineis.getBoundingClientRect().top}px - 0px)`;
    elementoPaineis.style.height = alturaPaineis;
  }
}

export function gerarTituloCenario(filtro: any, titulo: string): string {
  const periodo = formatarPeriodoData(filtro.des_periodo_analisado, filtro.flg_ano_mes);

  return ((periodo != "" ? (periodo + " - ") : "") + titulo)
}



export function CalcMargin(item1?: any, item2?: any) {
  if (item1 && item2) {
    item1 = Number.parseFloat(item1);
    item2 = Number.parseFloat(item2);
    return (item1 - item2) / item1;
  } else {
    return 0;
  }
}

export function CalcTrend(item1?: any, item2?: any) {
  if (item1 && item2) {
    item1 = Number.parseFloat(item1);
    item2 = Number.parseFloat(item2);
    return (item1 - item2) / item2;
  } else {
    return 0;
  }
}