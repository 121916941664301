import React, { } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import "./index.scss";
import { useAutenticação } from '../../core/contexts/auth';

interface Props {
    show: boolean;
    planta: boolean;
    onHide: () => void;
    selecionar: (tipo: number) => void;
}

export const ModalExportar: React.FC<Props> = (props) => {

    const { usuario } = useAutenticação();


    return (
        <Modal onHide={props.onHide} show={props.show} centered className='ModalExportar'>
            <Modal.Header closeButton className="pb-0">
                <Modal.Title className='font-size-18 text-primary'>Tipo de exportação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ButtonGroup className='w-100'  >
                    <Button onClick={(e: any) => { props.selecionar(1) }} variant='outline-primary' >
                        Padrão
                    </Button >
                    <Button onClick={(e: any) => { props.selecionar(2) }} variant='outline-dark' >
                        Nível SKU
                    </Button >
                    <Button onClick={(e: any) => { props.selecionar(3) }} variant='outline-primary' >
                        SKU/Bandeira
                    </Button >
                </ButtonGroup >

            </Modal.Body>
            <Modal.Footer className="pt-0">
                <Button variant="light" onClick={props.onHide}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
