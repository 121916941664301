import { ModeloBase } from "./modeloBase";

export const ModeloCentroDistribuicaoGranularidade = {
  CODIGO: ["cod_empresa", "cod_familia_subgrupo_produto_capitao", "cod_linha_produto_capitao", "cod_produto_capitao", "cod_produto", "cod_divisao_comercial"],
  DESCRICAO: ["ini_empresa", "des_familia_subgrupo_produto_capitao", "des_linha_produto_capitao", "des_produto_capitao", "des_produto", "des_divisao_comercial"],
  INTERFACE: ["Empresa", "Família Subgrupo", "Linha", "Capitão", "Produto", "Divisão Comercial"],
}

export class ModeloCentroDistribuicao extends ModeloBase {
  id: string = "";
  flg_carregando_volume: boolean = false;
  flg_carregando_preco: boolean = false;
  edit: boolean = false;
  editado_usuario: "" | "preco" | "volume" = "";
  flg_aberto: boolean = false;
  oculta: boolean = false;
  flg_elasticidade?: boolean;
  num_nivel: number = 0;
  filhos: number = 0;

  cod_empresa: string = "";
  des_empresa: string = "";
  ini_empresa: string = "";
  ini_uf_terc: string = "";
  cod_familia_subgrupo_produto_capitao: string = "";
  des_familia_subgrupo_produto_capitao: string = "";
  cod_familia_subgrupo: string = "";
  des_familia_subgrupo: string = "";
  cod_linha_produto_capitao: string = "";
  des_linha_produto_capitao: string = "";
  cod_linha: string = "";
  des_linha: string = "";
  cod_divisao_comercial: string = "";
  des_divisao_comercial: string = "";
  cod_produto_capitao: string = "";
  des_produto_capitao: string = "";
  cod_produto: string = "";
  des_produto: string = "";
  cod_bandeira: string = "";

  cod_gerencia: string = "";
  cod_diretoria: string = "";
  cod_acabamento: string = "";
  cod_temperatura: number = 0;
  cod_cluster: string = "";
  cod_mercado: string = "";
  cod_tipo_produto: string = "";
  cod_negocio: string = "";

  num_volume_estoque_inicial: number = 0;
  num_cpv_estoque_inicial: number = 0;

  num_shelf_resfriado_0_30: number = 0;
  num_shelf_resfriado_30_40: number = 0;
  num_shelf_resfriado_40: number = 0;
  num_shelf_congelado: number = 0;

  num_volume_reposicao: number = 0;
  num_preco_reposicao: number = 0;

  num_gvv: number = 0;
  num_preco_sem_mkp: number = 0;

  num_volume_venda: number = 0;
  num_preco_venda: number = 0;
  num_volume_ia: number = 0;
  num_preco_ia: number = 0;
  num_coeficiente_elasticidade: number = 0;


  // num_transferencia_volume_mkt_novo?: number = 0;
  // num_transferencia_volume_ia?: number = 0;
   num_transferencia_preco_mkt_novo?: number = 0;
  // num_transferencia_preco_ia?: number = 0;

  num_volume_mkt_novo?: number = 0;
  num_preco_mkt_novo?: number = 0;
  num_volume_mkt_original?: number = 0;
  num_preco_mkt_original?: number = 0;
  num_preco?: number = 0;
  num_volume?: number = 0;


  num_preco_imposto?: number = 0;

  num_fator_decisao_sazonalidade?: number = 0;
  num_fator_decisao_tendencia?: number = 0;
  num_fator_decisao_elasticidade?: number = 0;
  num_fator_decisao_giro?: number = 0;

  num_frete: number = 0;

  flg_semanal_mensal: number = 0;
  flg_periodo_analisado: number = 0;

  des_ano_mes: string = "";
  num_semana_ano: string = "";

  quantidade: number = 0;
}

export class ModeloCentroDistribuicaoListaTabela {
  outros?: boolean = true;

  bar?: boolean = true;
  lif?: boolean = true;
  red?: boolean = true;
  nad?: boolean = true;
  agb?: boolean = true;
  mrb?: boolean = true;
}

export class ModeloCentroDistribuicaoData {
  date?: string;
  date_show: string = "";
}

export class ModeloCentroDistribuicaoTabela {
  table_price: string = "";
}

export class ModeloCentroDistribuicaoResultadoIA {
  ini_empresa!: string;
  num_ia_tonelada!: number;
  num_ia_faturamento!: number;
}

export class ModeloCentroDistribuicaoFatorDefinicao {
  ini_empresa!: string;
  num_fator_decisao_sazonalidade!: number;
  num_fator_decisao_tendencia!: number;
  num_fator_decisao_elasticidade!: number;
  num_fator_decisao_giro!: number;
  num_mkt_tonelada!: number;
  num_mkt_faturamento!: number;
  num_ia_tonelada!: number;
  num_ia_faturamento!: number;
}
