import { createContext, useState, useContext, useEffect } from "react";
// import Cookies from 'universal-cookie/es6';
import { FiltroPrecoBase, RepositorioPrecoBase } from "../repositories/precoBaseRepositorio";
import { RepositorioPrecoBaseEdicao } from "../repositories/precoBaseEdicaoRepositorio";
import { useAutenticação } from "./auth";
import { ModeloPrecoBase, ModeloPrecoBaseGranularidade } from "../models/precoBase";
import { ModeloPrecoBaseEdicao } from "../models/precoBaseEdicao";

// O que o context irá passar para o outros componentes
interface PrecoBaseInterface {
  lista: ModeloPrecoBase[] | [];
  carregando: boolean;
  carregandoFilho: boolean;
  filtroNoContexto: FiltroPrecoBase;
  carregarLista(filtro: FiltroPrecoBase): Promise<ModeloPrecoBase[]>;
  adicionarLista(filtro: FiltroPrecoBase, index: number): Promise<number | undefined>;
  removerLista(index: number): void;
  // salvarEditados(): Promise<number>;
  limparLista(): void;
  atualizarInterface(): void;
  atualizarUmaLinha(linha: ModeloPrecoBase, index: number): void;
  salvarLinhaEditada(produto: ModeloPrecoBase, index: number): Promise<void>;
  atualizarFiltroContexto(filtro: FiltroPrecoBase): void;
  setCarregando(carregando: boolean): void;
}

// Como o objeto de context vai iniciar
const PrecoBaseContexto = createContext<PrecoBaseInterface>({} as PrecoBaseInterface);

type Props = {
  children?: JSX.Element;
};

// Componente de context que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const PrecoBaseProvider: React.FC<Props> = ({ children }) => {
  const [carregando, setCarregando] = useState(false);
  const [carregandoFilho, setCarregandoFilho] = useState(false);

  var [lista, setLista] = useState<ModeloPrecoBase[]>([]);
  const [filtroNoContexto, setFiltroContexto] = useState(new FiltroPrecoBase());
  const [contador, setContador] = useState(0);

  const { usuario } = useAutenticação();

  const _PrecoBase = new RepositorioPrecoBase();
  const _PrecoBaseEdit = new RepositorioPrecoBaseEdicao();

  useEffect(() => {
    (async () => { })();
    // eslint-disable-next-line
  }, []);
  function addValueAux(x:ModeloPrecoBase){
    // x.amount_sold = getRandomNumberInRange(1000,50000)
    // x.price_competidor_moda = x.num_preco_sem_margem*getRandomNumberInRange(0.9,1.1)
    // x.price_competidor_media = x.num_preco_sem_margem*getRandomNumberInRange(0.9,1.1)
    // x.current_table_price = x.num_preco_sem_margem*getRandomNumberInRange(0.9,1.1)
    // x.demand_forecast =  getRandomNumberInRange(1000,50000)
    // x.num_preco_sugerido_ia  = x.num_preco_sem_margem*getRandomNumberInRange(0.9,1.1)
    // x.buyers_price = x.num_preco_sem_margem*getRandomNumberInRange(0.9,1.1)
    // x.buyers_stock = getRandomNumberInRange(1000,50000)
    // x.demand_trend_w3 = getRandomNumberInRange(1000,50000)
    // x.demand_trend_w2 = getRandomNumberInRange(1000,50000)
    // x.demand_trend_w1 = getRandomNumberInRange(1000,50000)
    // x.price_trend_w3 = x.num_preco_sem_margem*getRandomNumberInRange(0.9,1.1)
    // x.price_trend_w2 = x.num_preco_sem_margem*getRandomNumberInRange(0.9,1.1)
    // x.price_trend_w1 = x.num_preco_sem_margem*getRandomNumberInRange(0.9,1.1)
    

    return {...x}
  }

  function atualizarUmaLinha(linhaNova: ModeloPrecoBase, index: number) {
    lista[index] = linhaNova;
    atualizaLista(lista);
  }

  async function carregarLista(filtro: FiltroPrecoBase) {
    const _contador = contador + 1;

    setContador(_contador);
    setCarregando(true);

    const PrecoBaseReturn = await _PrecoBase.getFilter(filtro, "_post");

    if (contador === _contador - 1) {
      //para abrir quando carrega trocar aberto para true
      if (filtro.num_nivel == 0) {
        PrecoBaseReturn.map((x) => {
          x.flg_aberto = false;
          x.num_nivel = 0;

          return addValueAux(x);
        });
      }

      const lista = [...PrecoBaseReturn];
      atualizaLista(lista);
      setCarregando(false);


      // if (localStorage.getItem("editando") == "Sim" && window.location.hostname === 'localhost') {

			// 	for (let j = 0; j < 5; j++) {
			// 		for (let i = 0; i < lista.length && lista[i].num_nivel < 5; i++) {
			// 			await adicionarLista(filtro, i);
			// 		}
			// 		await new Promise(resolve => {
			// 			setTimeout(resolve, 1500);
			// 		});
			// 	}
			// }
      
      return [...PrecoBaseReturn];
    } else {
      return [...lista];
    }
  }


 
  function carregarItensAbaixo(index: number) {
    const produto = lista[index];
    if (produto.flg_aberto) {
      const nivel = produto.num_nivel;
      var indexFinal = index + 1;
      // carrega nivel abaixo do atual
      while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
        const produtoAux = lista[indexFinal];
        produtoAux.num_preco_edicao =  produto.num_preco_edicao || 0
        // const element = document.getElementById("numberFormat" + indexFinal) as any;
        // if (element) {
        //   element.value = formatarDecimal(produtoAux.price_edit, true)
        // }
        indexFinal++;
      }
    }
    atualizarInterface();
  }

  async function salvarLinhaEditada(produto: ModeloPrecoBase, index: number) {
    const item = new ModeloPrecoBaseEdicao();
    item.des_agencia = produto.des_agencia;
    item.des_departamento = produto.des_departamento;
    item.des_categoria = produto.des_categoria;
    item.des_subcategoria = produto.des_subcategoria;
    item.des_mesorregiao = produto.des_mesorregiao;
    item.des_produto = produto.des_produto;
    item.num_ano_semana = filtroNoContexto.num_ano_semana;
    item.num_preco_edicao = produto.num_preco_edicao;
    item.flg_automatizado = produto.flg_automatizado;
    item.flg_aceito = produto.flg_aceito;
    item.cod_usuario = usuario?.cod_usuario;
    lista[index].flg_salvando = true;
    // lista[index].quantity_math = produto.quantity;
    atualizaLista(lista);
    try {
      await _PrecoBaseEdit.salvar(item);
      carregarItensAbaixo(index);
      lista[index].num_preco_edicao = produto.num_preco_edicao;
      lista[index].num_preco_salvo = produto.num_preco_salvo;
    } catch (error) {
      throw error;
    } finally {
      lista[index].flg_salvando = false;
      atualizaLista(lista);
    }

    atualizaLista(lista);

  }


  function removerLista(index: number) {
    lista[index].flg_aberto = false;
    const nivel = lista[index].num_nivel;
    var indexFinal = index + 1;

    while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
      const element = lista[indexFinal];
      element.flg_oculto = true;
      element.flg_aberto = false;
      indexFinal++;
    }

    atualizaLista(lista);
  }

  async function adicionarLista(filtroGeral: FiltroPrecoBase, index: number) {
    try {
      const linha = lista[index];
      setCarregandoFilho(true);

      linha.flg_aberto = true;

      if (linha.sons > 0) {
        const nivel = lista[index].num_nivel;
        var indexFinal = index + 1;

        while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
          const element = lista[indexFinal];
          // para mostrar somente os que estão um nível abaixo
          element.flg_oculto = nivel + 1 != lista[indexFinal].num_nivel;
          indexFinal++;
        }
        atualizaLista(lista);
      } else {
        const filtro = { ...filtroGeral };
        filtro.num_nivel = linha.num_nivel + 1;

        for (let index = 0; index < filtro.num_nivel; index++) {
          (filtro as any)[ModeloPrecoBaseGranularidade.CODIGO[index]] = (linha as any)[
            ModeloPrecoBaseGranularidade.CODIGO[index]
          ];
        }
        const response = await _PrecoBase.getFilter(filtro, "_post");
        response.map((x) => {
          x.num_nivel = linha.num_nivel + 1;
          x = addValueAux(x)
        });
        linha.sons = response.length;
        lista.splice(index + 1, 0, ...response);

        atualizaLista(lista);
      }
      setCarregandoFilho(false);
      return linha.sons;
    } catch (error) {
      setCarregandoFilho(false);
      
      return Promise.reject(error);
    
    }
  }
 


  function limparLista() {
    atualizaLista([]);
  }

  function atualizarInterface() {
    setLista([...lista]);
  }

  function atualizaLista(nova: ModeloPrecoBase[]) {
    setLista(nova);
    lista = nova;
  }

  function atualizarFiltroContexto(filtro: FiltroPrecoBase) {
    setFiltroContexto({ ...filtro });
  }

  return (
    // eslint-disable-next-line
    <PrecoBaseContexto.Provider
      value={{
        lista,
        carregando,
        carregandoFilho,
        filtroNoContexto,
        carregarLista,
        adicionarLista,
        removerLista,
        // salvarEditados,
        limparLista,
        atualizarInterface,
        atualizarUmaLinha,
        salvarLinhaEditada,
        atualizarFiltroContexto,
        setCarregando,
      }}
    >
      {children}
    </PrecoBaseContexto.Provider>
  );
};

export function precoBaseContexto() {
  const context = useContext(PrecoBaseContexto);

  return context;
}
