import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloTemperatura } from "../models/temperatura";
import { FiltroBasico } from "../models/basico";
import { Repositorio } from "./repositorio";

export class FiltroTemperatura extends FiltroBasico<ModeloTemperatura> {}

export class RepositorioTemperatura extends Repositorio<ModeloTemperatura> {
  constructor() {
    super(ConstanteGlobal.TEMPERATURA);
  }
}
