import React, { useState } from "react";
import { Table } from "react-bootstrap";
import TableRow from "../TableRow";
import Skeleton from "react-loading-skeleton";
import { TabelaTr } from "./tabelaTr";
import { recuperacaoClienteContext } from "../../core/contexts/recuperacaoClienteContexto";
import './index.scss';
import { useTranslation } from "react-i18next";
import { ModeloRecuperacaoClienteGranularidade } from "../../core/models/recuperacaoCliente";
import { FiltroRecuperacaoCliente } from "../../core/repositories/recuperacaoClienteRepositorio";

interface Props {
  filtro: FiltroRecuperacaoCliente;
  aberto: boolean;
  salvouEdicao(): void;
}


export default function TabelaRecuperacaoCliente(props: Props) {
  const { t } = useTranslation();
  const [carregandoPrimeiraVez, setCarregandoPrimeiraVez] = useState(true);

  const { lista, carregando } = recuperacaoClienteContext();

  React.useEffect(() => {
    if (carregando && carregandoPrimeiraVez) {
      setCarregandoPrimeiraVez(false)
    }
  }, [carregando]);

  function title(item: any) {
    return t("pages." + ModeloRecuperacaoClienteGranularidade.INTERFACE[item.num_nivel]) + ": "
      + item[ModeloRecuperacaoClienteGranularidade.DESCRICAO[item.num_nivel]]?.toString()
  }


  return (
    <div className="tabela-recuperacao-cliente">

      <div id="tabela">
        <Table className="rounded " hover bordered striped responsive size="sm"  >
          <thead >
            <tr className="table-header">
              <TableRow classNameTh="width-400 granularidade"> {t("components.Granularidade")}</TableRow>


              <TableRow >  {t("components.Demanda Sugerida (Kg)")} </TableRow>
              <TableRow >{t("components.Preço Sugerido IA")}</TableRow>
              <TableRow >{t("components.Projeção de Faturamento")}</TableRow>


              <TableRow colSpan={3} classNameTh=" px-0" className="mescado faturamento">
                <Table className="mb-0"  >
                  <tbody>
                    <tr>
                      <th colSpan={4} className="text-center">
                        <label className="mr-2">  {t("components.Faturamento")} </label>
                      </th>
                    </tr>
                    <tr>
                      <TableRow >{t("components.Recência")} <br />{t("components.(dias)")}</TableRow>
                      <TableRow >{t("components.Data da Última Compra")}</TableRow>
                      <TableRow >{t("components.Ticket Médio")}</TableRow>
                    </tr>
                  </tbody>
                </Table>
              </TableRow>

              <TableRow colSpan={2} classNameTh=" px-0" className="mescado cluster">
                <Table className="mb-0"  >
                  <tbody>
                    <tr>
                      <th colSpan={4} className="text-center">
                        <label className="mr-2">   {t("pages.Cluster")}  </label>
                      </th>
                    </tr>
                    <tr>
                      <TableRow >{t("components.Recência")} <br />{t("components.(dias)")}</TableRow>
                      <TableRow >{t("components.Ticket Médio")}</TableRow>
                    </tr>
                  </tbody>
                </Table>
              </TableRow>


            </tr>
          </thead>
          {carregando ? (
            <tbody>
              {Array.from({ length: 8 }).map(
                (_, index) => (
                  <tr className="height-38" key={"skeleton-tr" + index}>
                    {Array.from({ length: 9 }).map((_, index) => (
                      <td key={"skeleton-td" + index}>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          ) : (
            <tbody>
              {(lista.length == 0) &&
                <tr>
                  <td colSpan={9} className="text-center">
                    {carregandoPrimeiraVez ? t("components.Clique em buscar para carregar os dados!") : t("pages.Nenhum registro encontrado!")}
                  </td>
                </tr>
              }

              {lista.map((item, index) => (
                !item.oculta &&
                <tr key={"tr-" + item.id + index} className={"font-size-" + (14 - item.num_nivel * 0)} title={title(item)}>
                  <TabelaTr linha={item} index={index} filtro={props.filtro} salvouEdicao={props.salvouEdicao} />
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </div>
    </div>
  );
}
