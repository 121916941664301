import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloSazonalidade } from "../models/sazonalidade";

import { Repositorio } from "./repositorio";

export class FiltroSazonalidade {
  editados = false;
  flg_aberto = true;
  num_nivel = 0;

  cod_produto_capitao?: string;
  cod_linha_produto_capitao?: string;
  cod_familia_subgrupo_produto_capitao?: string;
  cod_gerencia?: string;
  cod_acabamento?: string;
  cod_temperatura?: string;
  cod_mercado?: string;
  cod_tipo_produto?: string;


}

export class RepositorioSazonalidade extends Repositorio<ModeloSazonalidade> {
  constructor() {
    super(ConstanteGlobal.SAZONALIDADE);
  }




}
