import { Form, Button, Spinner, Alert } from "react-bootstrap";
import logo from "../../assets/images/logo-blue.png";

import React, { useEffect, useState } from "react";
import { useAutenticação } from "../../core/contexts/auth";
import "./index.scss";
import { TipoMensagem } from "../../core/config/tipoMensage";
import { FaRegCopyright } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Cookies from 'universal-cookie/es6';

export const Login: React.FC = () => {
    const { t } = useTranslation();

    const { checkEmail, signOut } = useAutenticação();
    const [validated, setValidated] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [error, setError] = useState(``);

    useEffect(() => {
        signOut(false);
    }, []);

    async function handleSubmit(event: any) {
        setValidated(true);
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setCarregando(true);

        try {
            await checkEmail(form[0].value);
            if (process.env.REACT_APP_BASE_BACK) {
				new Cookies().set('@RNauth:usuario', { name: "DEV_USER", email: form[0].value, des_email: form[0].value  }, {
					path: '/',
					httpOnly: false,
				});
				window.location.href = `${window.location.origin}/loginsuccess`;
			} else {
            window.location.href = `/.auth/login/aad?post_login_redirect_uri=${window.location.origin}/loginsuccess`;
            }
        } catch (error) {
            setCarregando(false);
            if (error && error == 1) {
                setError(t("pages.E-mail não autorizado"));
            } else {
                setError(t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"));
            }
        }
    }
    return (
        <section className="login">
            <div className="inner">
                <div className="text-center">
                    <img className="logo" src={logo} alt="Logo" />
                </div>
                <label className="mt-2 text-center gray" color="gray" >{t("pages.Insira suas credenciais para continuar com a demonstração do A2Go.")}</label>
                <Form validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control
                            className="mt-4"
                            required
                            type="email"
                            placeholder="Digite o e-mail"
                            onChange={() => setError("")}
                        />
                        <Form.Control.Feedback type="invalid">
                            
                            {t("pages.Obrigatório!")}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {error && (
                        <div className="error text-danger mb-1 font-size-12">
                            <label> {error}</label>
                        </div>
                    )}

                    <div className="d-grid gap-2 mt-1">
                        <Button type="submit" className="mt-2" disabled={carregando}>
                            {carregando ? (
                                <Spinner size="sm" animation="border" role="status" />
                            ) :t("pages.Continuar") }
                        </Button>
                    </div>
                </Form>
            </div>
            <footer>
                <div className="text-center">
                    <p>
                        <Alert.Link className="text-primary" href="https://analytics2go.com">
                            <FaRegCopyright />
                            2024 Analytics2Go
                        </Alert.Link>
                    </p>
                </div>
            </footer>
        </section>
    );
}
