import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico, ModeloBasico } from "../models/basico";
import { ModeloLinha } from "../models/linha";
import { Repositorio } from "./repositorio";

export class FiltroLinha extends FiltroBasico<ModeloLinha> {
  cod_familia_subgrupo?: string;
}

export class RepositorioLinha extends Repositorio<ModeloLinha> {
  constructor() {
    super(ConstanteGlobal.LINHA);
  }
}
