import { createContext, useState, useContext, useEffect } from 'react';
// import Cookies from 'universal-cookie/es6';
import { ModeloRecuperacaoCliente, ModeloRecuperacaoClienteGranularidade } from '../models/recuperacaoCliente';

import { ModeloEmpresa } from '../models/empresa';
import { RepositorioEmpresa } from '../repositories/empresaRepositorio';
import { ModeloCenario } from '../models/cenario';
import { useAutenticação } from './auth';
import { FiltroRecuperacaoCliente, RepositorioRecuperacaoCliente } from '../repositories/recuperacaoClienteRepositorio';

// O que o contexto irá passar para o outros componentes
interface RecuperacaoClienteContextoInterface {
	lista: ModeloRecuperacaoCliente[] | [];
	cenario: ModeloCenario;
	carregando: boolean;
	carregandoFilho: boolean;
	filtroNoContexto: FiltroRecuperacaoCliente;
	carregarLista(filtro: FiltroRecuperacaoCliente): Promise<ModeloRecuperacaoCliente[]>;
	adicionarLista(filtro: FiltroRecuperacaoCliente, index: number): Promise<number>;
	removerLista(index: number): void;
	limparLista(): void;
	atualizarInterface(): void;
	atualizarUmaLinha(linha: ModeloRecuperacaoCliente, index: number): void;
	atualizarCenario(cenario: ModeloCenario): void;
	atualizarFiltroContexto(filtro: FiltroRecuperacaoCliente): void;
	setCarregando(carregando: boolean): void;
}

// Como o objeto de contexto vai iniciar
const RecuperacaoClienteContexto = createContext<RecuperacaoClienteContextoInterface>({} as RecuperacaoClienteContextoInterface);

type Props = {
	children?: JSX.Element;
};

// Componente de contexto que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const RecuperacaoClienteProvider: React.FC<Props> = ({ children }) => {
	const [carregando, setCarregando] = useState(false);
	const [carregandoFilho, setCarregandoFilho] = useState(false);

	var [lista, setLista] = useState<ModeloRecuperacaoCliente[]>([]);
	const [filtroNoContexto, setFiltroContexto] = useState(new FiltroRecuperacaoCliente());
	const [plantas, setPlantas] = useState<ModeloEmpresa[]>([]);
	const [cenario, setCenario] = useState<ModeloCenario>(new ModeloCenario());
	const [contador, setContador] = useState(0);

	const { usuario } = useAutenticação();

	const _recuperacaoCliente = new RepositorioRecuperacaoCliente();

	useEffect(() => {
		(async () => {
			new RepositorioEmpresa().getPlantas().then((res) => {
				setPlantas(res);
			});
		})();
		// eslint-disable-next-line
	}, []);

	//retorna uma lista de atributos unicos
	function buscarPorNivelAux(itens: any[], atributo: string) {

		const uniqueValues = Array.from(new Set(itens.filter(x => x[atributo]).map(x => x[atributo])));
		return uniqueValues.toString();
	}

	function buscarPorNivel(nivel: number, filtro: FiltroRecuperacaoCliente) {
		// const itens = lista.filter(x => x.nivel === nivel && plantas.filter(y => y.selecionada).length != plantas.filter(x => x.selecionada).length)
		// para carregar somente os itens da lista que esta sem carregar 
		const itens = lista.filter(x => x.num_nivel === nivel)

		if (itens.length > 0) {
			const codigos = plantas.filter(x => x.selecionada).map(x => x.cod_empresa);
			var filtroAux = { ...filtro }
			for (let index = 0; index <= nivel; index++) {
				(filtroAux as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[index]] = buscarPorNivelAux(itens, ModeloRecuperacaoClienteGranularidade.CODIGO[index])
			}

			filtroAux.num_nivel = nivel;
			return filtroAux;
		} else {
			return null;
		}
	}


	function atualizarUmaLinha(linhaNova: ModeloRecuperacaoCliente, index: number) {
		lista[index] = linhaNova;
		atualizaLista(lista);
	}

	async function carregarLista(filtro: FiltroRecuperacaoCliente) {
		const _contador = contador + 1;
		setContador(_contador)
		setCarregando(true);

		const recuperacaoClienteRetorno = await _recuperacaoCliente.getFilter(filtro, "_post")

		if (contador === _contador - 1) {
			//para abrir quando carrega trocar aberto para true
			if (filtro.num_nivel == 0) {
				recuperacaoClienteRetorno.map(x => { x.flg_aberto = false; x.num_nivel = 0; return x; });
			}


			const listaAux = [...recuperacaoClienteRetorno]
			atualizaLista(listaAux);
			setCarregando(false);
			await carregarDados(filtro)

			// if (localStorage.getItem("editando") == "Sim" && window.location.hostname === 'localhost') {

			// 	for (let j = 0; j < 4; j++) {
			// 		for (let i = 0; i < lista.length && lista[i].num_nivel < 4; i++) {
			// 			await adicionarLista(filtro, i);
			// 		}

			// 	}
			// }

			return [...recuperacaoClienteRetorno]

		} else {
			return [...lista]
		}
	}

	async function carregarDados(filtro: FiltroRecuperacaoCliente) {
		//se nao tiver nenhuma selecionada, não carrega
		if (lista.length) {
			// carregarModelo(filtro)
			await Promise.all([
				// carregarModeloSugeridoMkt(filtro),
				// carregarEstoque(filtro),
				// carregarPlanoProducao(filtro)
			])

		}
	}

	// funcao para carregar todos os elementos que esta relacionado com a linha
	async function carregarNivelRelacionado(index: number) {
		const produto = lista[index]
		for (let i = 0; i < lista.length; i++) {
			if (i != index) {
				var igual = true;
				const produtoAux = lista[i];
				for (let j = 0; j <= produtoAux.num_nivel && igual; j++) {
					if ((produtoAux as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[j]] != (produto as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[j]]) {
						igual = false
					}
				}
				if (igual) {
					// produtoAux.num_fator_decisao_elasticidade = undefined
					// produtoAux.num_fator_decisao_giro = undefined
					// produtoAux.num_fator_decisao_sazonalidade = undefined
					// produtoAux.num_fator_decisao_tendencia = undefined
					produtoAux.flg_elasticidade = undefined
					produtoAux.flg_carregando_preco = true;
					produtoAux.flg_carregando_volume = true;
					produtoAux.editado_usuario = ""
				}
			}
		}
		if (produto.flg_aberto) {
			const nivel = lista[index].num_nivel;
			var indexFinal = index + 1
			while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
				const produtoAux = lista[indexFinal];
				// produtoAux.num_fator_decisao_elasticidade = undefined
				// produtoAux.num_fator_decisao_giro = undefined
				// produtoAux.num_fator_decisao_sazonalidade = undefined
				// produtoAux.num_fator_decisao_tendencia = undefined
				produtoAux.flg_elasticidade = undefined
				produtoAux.flg_carregando_preco = true;
				produtoAux.flg_carregando_volume = true;
				indexFinal++
			}
		}
		atualizarInterface()
		//carrega nivel abaixo
		for (let i = 0; i < lista.length; i++) {
			const produtoAux = lista[i];

			if ((produtoAux.flg_carregando_preco || produtoAux.flg_carregando_volume)) {
				const filtroAux = { ...filtroNoContexto }
				for (let j = 0; j <= produtoAux.num_nivel; j++) {
					(filtroAux as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[j]] = (produtoAux as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[j]]
				}
				filtroAux.num_nivel = produtoAux.num_nivel;
				const retorno = await _recuperacaoCliente.getFilter(filtroAux, "_modelo_sugerido_mkt")

				if (retorno.length) {
					// produtoAux.num_fator_decisao_elasticidade = retorno[0].num_fator_decisao_elasticidade || 0;
					// produtoAux.num_fator_decisao_giro = retorno[0].num_fator_decisao_giro || 0;
					// produtoAux.num_fator_decisao_sazonalidade = retorno[0].num_fator_decisao_sazonalidade || 0;
					// produtoAux.num_fator_decisao_tendencia = retorno[0].num_fator_decisao_tendencia || 0;
					produtoAux.flg_carregando_preco = false;
					produtoAux.flg_carregando_volume = false;


					produtoAux.flg_elasticidade = retorno[0].flg_elasticidade;

				} else {
					produtoAux.flg_carregando_preco = false;
					produtoAux.flg_carregando_volume = false;


				}
				atualizarInterface()

			} else {
				produtoAux.flg_carregando_preco = false;
				produtoAux.flg_carregando_volume = false;

				atualizarInterface()
			}
		}
	}


	function removerLista(index: number) {
		lista[index].flg_aberto = false;
		const nivel = lista[index].num_nivel;
		var indexFinal = index + 1

		while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
			const element = lista[indexFinal];
			element.oculta = true
			element.flg_aberto = false
			indexFinal++
		}

		atualizaLista(lista)
	}

	async function adicionarLista(filtroGeral: FiltroRecuperacaoCliente, index: number) {
		const linha = lista[index];
		setCarregandoFilho(true)
		linha.flg_aberto = true;
		try {
			if (linha.filhos > 0) {
				const nivel = lista[index].num_nivel;
				var indexFinal = index + 1

				while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
					const element = lista[indexFinal];
					// para mostrar somente os que estão um nível abaixo
					element.oculta = (nivel + 1) != lista[indexFinal].num_nivel
					indexFinal++
				}
				atualizaLista(lista)

			} else {
				const filtro = { ...filtroGeral }
				filtro.num_nivel = linha.num_nivel + 1

				for (let index = 0; index < filtro.num_nivel; index++) {
					(filtro as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[index]] = (linha as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[index]]
				}

				const response = await _recuperacaoCliente.getFilter(filtro, "_post")

				// await new Promise((resolve) => setTimeout(resolve, 2000));
				response.map((item) => {
					item.num_nivel = linha.num_nivel + 1;
				});
				linha.filhos = response.length;
				lista.splice(index + 1, 0, ...response);
				carregarDados(filtro)
				atualizaLista(lista)
			}
			setCarregandoFilho(false)
			return linha.filhos;
		} catch (error) {
			setCarregandoFilho(false)
			return 0;
		}
	}

	function filtroAuxAtributo(element: any, attr: string) {
		return lista.filter(x => (x as any)[attr] === undefined).find((x) => {
			var encontrado = true;
			for (let index = 0; index < ModeloRecuperacaoClienteGranularidade.CODIGO.length && encontrado; index++) {
				encontrado = (x as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[index]] == (element as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[index]]
			}
			return encontrado;
		})
	}




	function limparLista() {
		atualizaLista([])
	}

	function atualizarInterface() {
		setLista([...lista])
	}

	function atualizaLista(nova: ModeloRecuperacaoCliente[]) {
		console.log("listaaaaaaaaaaaaaaaaa", nova)
		setLista(nova)
		lista = nova;
	}

	function atualizarCenario(cenarioAux: ModeloCenario) {
		setCenario({ ...cenarioAux })
	}

	function atualizarFiltroContexto(filtro: FiltroRecuperacaoCliente) {
		setFiltroContexto({ ...filtro })
	}

	return (
		// eslint-disable-next-line
		<RecuperacaoClienteContexto.Provider
			value={{
				lista,
				cenario,
				carregando,
				carregandoFilho,
				filtroNoContexto,
				carregarLista,
				adicionarLista,
				removerLista,
				limparLista,
				atualizarInterface,
				atualizarUmaLinha,
				atualizarCenario,
				atualizarFiltroContexto,
				setCarregando
			}}
		>
			{children}
		</RecuperacaoClienteContexto.Provider>
	);
};

export function recuperacaoClienteContext() {
	const contexto = useContext(RecuperacaoClienteContexto);

	return contexto;
}
