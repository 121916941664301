import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import './index.scss';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

interface Props {
	atualizarPagina: (value: number) => void;
	paginaAtual: number;
	tamanhoPagina: number;
	contador: number;
}

const PaginacaoTabela: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const [objetoPagina, setObjetoPagina] = useState<any>({});

	useEffect(() => {
		atualizarPagina(props.paginaAtual);
	}, [props.contador, props.paginaAtual]);

	function atualizarPagina(pagina: number) {
		var { tamanhoPagina } = props;
		var pager = objetoPagina;

		if (pagina < 1 || pagina > props.contador) {
			return;
		}

		pager = getPager(props.contador, pagina, tamanhoPagina);
		setObjetoPagina(pager);

		if (pagina !== props.paginaAtual) {
			props.atualizarPagina(pagina);
		}
	}

	function getPager(totalItems: number, paginaAtual: number, tamanhoPagina: number) {
		paginaAtual = paginaAtual || 1;
		tamanhoPagina = tamanhoPagina || 10;
		var totalPaginas = Math.ceil(totalItems / tamanhoPagina);

		var paginaInicio = 0,
			paginaFim;
		if (totalPaginas <= 5) {
			paginaInicio = 1;
			paginaFim = totalPaginas;
		} else {
			if (paginaAtual <= 3) {
				paginaInicio = 1;
				paginaFim = 5;
			} else if (paginaAtual + 2 >= totalPaginas) {
				paginaInicio = totalPaginas - 4;
				paginaFim = totalPaginas;
			} else {
				paginaInicio = paginaAtual - 1;
				paginaFim = paginaAtual + 2;
			}
		}

		var indexInicio = (paginaAtual - 1) * tamanhoPagina;
		var indexFim = Math.min(indexInicio + tamanhoPagina - 1, totalItems - 1);

		var paginas = Object.keys([...Array(paginaFim + 1 - paginaInicio)]).map((i) => paginaInicio + Number.parseInt(i));

		return {
			totalItems: totalItems,
			tamanhoPagina: tamanhoPagina,
			totalPaginas: totalPaginas,
			paginaInicio: paginaInicio,
			paginaFim: paginaFim,
			indexInicio: indexInicio,
			indexFim: indexFim,
			paginas: paginas,
		};
	}

	return objetoPagina.totalItems ? (
		<Row className="table-pagination">
			<Col md="4" className="font-size-12 align-vertical justify-content-start align-items-end ">
				{objetoPagina.indexInicio + 1} a {objetoPagina.indexFim + 1} de {props.contador} &nbsp;{t("components.itens")}
			</Col>
			<Col md="8">
				<Pagination size="sm" className="mb-0 justify-content-end">
					<Pagination.Prev className={props.paginaAtual === 1 ? 'disabled' : ''} onClick={() => atualizarPagina(props.paginaAtual - 1)}></Pagination.Prev>

					{objetoPagina.paginaInicio !== 1 && <Pagination.Ellipsis className="min-width-38 text-center" onClick={() => atualizarPagina(1)} />}

					{objetoPagina.paginas.map((pagina: any, index: number) => (
						<Pagination.Item key={index} className={(props.paginaAtual === pagina ? 'active' : '') + ' min-width-38 text-center'} onClick={() => atualizarPagina(pagina)}>
							{pagina}
						</Pagination.Item>
					))}

					{objetoPagina.paginaFim !== objetoPagina.totalPaginas && <Pagination.Ellipsis className="min-width-38 text-center" onClick={() => atualizarPagina(objetoPagina.totalPaginas)} />}

					<Pagination.Next className={props.paginaAtual === objetoPagina.totalPaginas ? 'disabled' : ''} onClick={() => atualizarPagina(props.paginaAtual + 1)} />
				</Pagination>
			</Col>
		</Row>
	) : (
		<Skeleton className='height-24' />
	);
};

export default PaginacaoTabela;
