import { AxiosInstance, AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import api from '../services/api';
import { BaseModel } from '../models/base-model';

export abstract class RepositoryRequestFilter {
	page = 1;
	pageSize = 20;
	total = 0;

	filter: any = {};
	sort: any = {};
}

export abstract class Repository<T extends BaseModel> {
	protected api: AxiosInstance;

	constructor(public collectionName: string) {
		const cookies = new Cookies();
		this.api = api;
		this.api.interceptors.request.use((config: AxiosRequestConfig) => {
			// const manager = cookies.get("@RNauth:layout") as ManagerModel;
			// if (manager){
			//   config.params = { ...config.params, language_id: manager.LanguageId, product_type_id: manager.ProductTypeId };
			// }
			return config;
		});
	}

	public async get(): Promise<T> {
		return this.api.get(`${this.collectionName}_get`).then((res) => {
			return res.data;
		});
	}

	public async getAll(): Promise<T[]> {
		return this.api.get(`${this.collectionName}`).then((res) => {
			return res.data;
		});
	}

	download(search: any) {
		search = { ...search };
		search.pageSize = search.total + 1;
		return this.api.post(this.collectionName + '_download', search, { responseType: 'arraybuffer' });
	}
	getLastUpdate() {
		return this.api.get(`${this.collectionName}_last_update`).then((res) => {
			return res.data[0].date_creation;
		});
	}

	// public getById(id: string): Promise<T | null> {
	//   return this.api.get(`${this.collectionName}/${id}`);
	// }

	public async add(model: T) {
		return this.api.post(`${this.collectionName}_create_post`, model);
	}

	public async upload(model: T) {
		return this.api.post(`${this.collectionName}_update`, model);
	}

	protected toObject(document: any): T {
		let data = { id: document.id, ...document.data() };
		return data;
	}

	protected filterSort(data: any) {
		var filter: any = { ...data };
		let aux = '';
		// filter.sort
		if (filter.sort) {
			Object.keys(filter.sort).forEach((key) => {
				if (filter.sort[key] != '') {
					aux += `${aux?.length > 0 ? ',' : ''} ${key} ${filter.sort[key] === 'asc' ? 'ASC' : 'DESC'} `;
				}
			});
		}
		filter.order = aux;
		delete filter.sort;
		return filter;
	}

	public async getFilter(data: any, prefix: string = ''): Promise<T[]> {
		return this.api.post(this.collectionName + prefix + '', this.filterSort(data)).then((res: any) => {
			return res.data;
		});
	}
}
