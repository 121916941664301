import React, { FormEvent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useModal } from '../../core/contexts/modalContexto';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    nomeCenario: string;
    show: boolean;
    onHide: () => void;
    salvar: (nome: string) => void;
    verificarNome: (nome: string) => Promise<boolean>;
}

export const ModalCenarioNome: React.FC<Props> = (props) => {

    const { t } = useTranslation();
    const { mostrarModal } = useModal();
    const [nomeCenario, setNomeCenario] = useState('');
    const [carregarBtnSalvar, setCarregarBtnSalvar] = useState(false);

    React.useEffect(() => {
        setNomeCenario(props.nomeCenario)
    }, [props.nomeCenario,props.show])

    function handleChangeNomeCenario(event: React.ChangeEvent<HTMLInputElement>) {
        setNomeCenario(event.target.value);
    };

    async function salvar(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault();

        setCarregarBtnSalvar(true)

        const existe = await props.verificarNome(nomeCenario)

        if (existe) {
            mostrarModal({
                titulo: t("components.Cenário já existe") ,
                subTitulo: t("components.Já existe um cenário com esse nome. Por favor, mude o nome para salvar esse cenário!") 
            })
        } else {
            props.salvar(nomeCenario);
        }
        
        setCarregarBtnSalvar(false)
        props.onHide();
        setNomeCenario('');
    };

    return (
        <Modal onHide={props.onHide} show={props.show} centered>
            <Modal.Header closeButton className="pb-0">
                <Modal.Title className="font-size-18 text-primary">{t("components.Cenário")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={salvar} id="cenario">
                    <Form.Group  controlId="cenarioId">
                        <Form.Label>{t("components.Nome do cenário")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("components.Digite aqui o nome do cenário")}
                            autoFocus
                            required
                            minLength={3}
                            value={nomeCenario}
                            onChange={handleChangeNomeCenario}
                            autoComplete='off'
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="pt-0">
                <Button variant="secondary" onClick={props.onHide}>
                {t("components.Fechar")} 
                </Button>
                <Button variant="primary" type="submit" form="cenario" style={{ width: 70 }} disabled={carregarBtnSalvar}>
                    {carregarBtnSalvar ?
                        <Spinner animation="border" size="sm" /> :
                        t("components.Salvar")
                    }
                </Button>
            </Modal.Footer>
        </Modal>     
    );
};
