import React, { useEffect } from 'react';
import Pagina404 from "../pages/Pagina404";
import { Routes, Route, useNavigate } from 'react-router-dom';
import Carregando from '../pages/Carregando';
import { Login } from '../pages/Login';
import { LoginSuccess } from '../pages/LoginSuccess';
import { Sair } from '../pages/Sair';


const AutenticaçãoRoutes: React.FC = () => {
  const navigate = useNavigate();

 

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/loginsuccess" element={<LoginSuccess />} />
      <Route path="/logout" element={<Sair />} />
      <Route path="/pagina-404" element={<Pagina404 />} />
      <Route path="*" element={<Login />} />
      {/* <Route path="/" element={<Login />} /> */}
    </Routes>
  )
};

export default AutenticaçãoRoutes;
