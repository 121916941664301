import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico, ModeloBasico } from "../models/basico";
import { ModeloCanal } from "../models/canal";
import { Repositorio } from "./repositorio";

export class FiltroCanal extends FiltroBasico<ModeloCanal> {}

export class RepositorioCanal extends Repositorio<ModeloCanal> {
  constructor() {
    super(ConstanteGlobal.CANAL);
  }
}
