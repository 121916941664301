import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import LayoutPrincipal from '../components/Layout';
import Pagina404 from '../pages/Pagina404';
import PlantaIndustrial from '../pages/PlantaIndustrial';
import { PlantaIndustrialProvider } from '../core/contexts/plantaIndustrialContexto';
import CentroDistribuicao from '../pages/CentroDistribuicao';
import { CentroDistribuicaoProvider } from '../core/contexts/centroDistribuicaoContexto';
import Sazonalidade from '../pages/Sazonalidade';
import { SazonalidadeProvider } from '../core/contexts/sazonalidadeContexto';
import GerenciadorUsuario from '../pages/GerenciadorUsuario';
import PlantaIndustrialMockup from '../pages/PlantaIndustrialMockup';
import { RecuperacaoClienteProvider } from '../core/contexts/recuperacaoClienteContexto';
import { PrecoBaseProvider } from '../core/contexts/precoBaseContexto';
import PrecoBase from '../pages/PrecoBase';
import { CatalogoPrecoBaseProvider } from '../core/contexts/catalogoPrecoBaseContexto';
import CatalogoPrecoBase from '../pages/CatalogoPrecoBase';

const AppRoutes: React.FC = () => {
	const navigate = useNavigate();

	return (
		<Routes>
			<Route element={<LayoutPrincipal />}>
				<Route path="/vendas-diretas" element={
					<PlantaIndustrialProvider>
						<PlantaIndustrial />
					</PlantaIndustrialProvider>
				} />
				<Route path="/centro-distribuicao" element={
					<CentroDistribuicaoProvider>
						<CentroDistribuicao />
					</CentroDistribuicaoProvider>
				} />

				<Route path="/sazonalidade" element={
					<SazonalidadeProvider>
						<Sazonalidade />
					</SazonalidadeProvider>
				} />
				<Route path="/inteligencia-mercado" element={
					<RecuperacaoClienteProvider>
						<PlantaIndustrialMockup />
					</RecuperacaoClienteProvider>
				} />

				<Route path="/preco-base" element={
					<PrecoBaseProvider>
						<PrecoBase />
					</PrecoBaseProvider>
				} />



				<Route path="/catalago-preco-base" element={
					<CatalogoPrecoBaseProvider>
						<CatalogoPrecoBase />
					</CatalogoPrecoBaseProvider>
				} />

				<Route path="/gerenciamento-usuarios" element={<GerenciadorUsuario />} />
				<Route path="/pagina-404" element={<Pagina404 />} />
				<Route path="*" element={<Navigate to="/catalago-preco-base" />} />
			</Route>
		</Routes>
	)
};

export default AppRoutes;
