import React, { useRef, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { TipoMensagem } from "../../core/config/tipoMensage";
import { useToast } from "../../core/contexts/toast";
import { ModeloCentroDistribuicaoFatorDefinicao, ModeloCentroDistribuicaoListaTabela, ModeloCentroDistribuicaoResultadoIA } from "../../core/models/centroDistribuicao";
import { RepositorioCentroDistribuicao, FiltroCentroDistribuicao } from "../../core/repositories/centroDistrubuicaoRepositorio";
import { BiCloudDownload, BiSave } from "react-icons/bi";
import TabelaCentroDistribuicao from "../../components/TabelaCentroDistribuicao";
import { centroDistribuicaoContext } from "../../core/contexts/centroDistribuicaoContexto";
import CentroDistribuicaoPainelResultadoIA from "./painelResultadoIA";
import CentroDistribuicaoPainelFatores from "./painelFatores";
import CentroDistribuicaoPainelDefinicao from "./painelDefinicao";
import { useAutenticação } from "../../core/contexts/auth";
import { RepositorioCenario } from "../../core/repositories/cenarioRepositorio";
import { ModeloCenario, atualizarAtributosCenario } from "../../core/models/cenario";
import { ModalCenarioNome } from "../../components/ModalCenario/modalCenarioNome";
import { ModalCenarioTabela } from "../../components/ModalCenario/modalCenarioTabela";
import { ModalCenarioSalvar } from "../../components/ModalCenario/modalCenarioSalvar";
import { useModal } from "../../core/contexts/modalContexto";
import { RepositorioCentroDistribuicaoEdicao } from "../../core/repositories/centroDistribuicaoEdicaoRepositorio";
import { atualizaTamanhoPaineis, atualizaTamanhoTabela, formatarPeriodoData, gerarTituloCenario, saoCenariosIguais } from "../../core/utils/funcoes";
import FiltroCD from "./filtro";
import { ModeloBasico } from "../../core/models/basico";
import "./index.scss";
import moment from "moment";
import { filtroContexto } from "../../core/contexts/filtroContexto";
import { useTranslation } from "react-i18next";


var contador: number = 0;

export function atualizarAlturaDosPaineisCentroDistribuicao() {
  setTimeout(() => {
    atualizaTamanhoPaineis()
  }, 200);
}

export default function CentroDistribuicao() {
  const { t } = useTranslation();
  const bodyRef = useRef(null);

  const { lista, carregarLista, atualizarInterface, salvarEditados, cenario, atualizarCenario, atualizarFiltroContexto, filtroNoContexto, setCarregando } = centroDistribuicaoContext();
  const { periodoCarregado, listaPeriodoMes, listaPeriodoSemana } = filtroContexto()

  const { mostrarToast } = useToast();
  const { mostrarModal } = useModal();
  const { usuario } = useAutenticação()

  const [cenarioOriginal, setCenarioOriginal] = useState(new ModeloCenario())

  const [filtro, setFiltro] = useState(new FiltroCentroDistribuicao());
  const [listaResultadoIA, setListaResultadoIA] = useState<ModeloCentroDistribuicaoResultadoIA[]>([]);
  const [listaFatorDefinicao, setListaFatorDefinicao] = useState<ModeloCentroDistribuicaoFatorDefinicao[]>([]);
  const [listaTabela, setListaTabela] = useState(new ModeloCentroDistribuicaoListaTabela());

  const [carregarTabelaResultadoIA, setCarregarTabelaResultadoIA] = useState(false);
  const [carregarTabelaFatorDefinicao, setCarregarTabelaFatorDefinicao] = useState(false);
  const [mostrarCenarioNome, setMostrarCenarioNome] = useState(false);
  const [mostrarCenarioTabela, setMostrarCenarioTabela] = useState(false);
  const [mostrarCenarioSalvar, setMostrarCenarioSalvar] = useState(false);
  const [mostrarCenarioSalvarAposNome, setMostrarCenarioSalvarAposNome] = useState(false);
  const [carregarBtnExportar, setCarregarBtnExportar] = useState(false);
  const [carregarBtnSalvar, setCarregarBtnSalvar] = useState(false);
  const [carregandoPrimeiraVez, setCarregandoPrimeiraVez] = useState(true);

  const [listaPeriodo, setListaPeriodo] = useState<ModeloBasico[]>([]);
  const _centroDistribuicao = new RepositorioCentroDistribuicao();

  React.useEffect(() => {

    if (filtro.flg_mostrar_resultado) {
      atualizarAlturaDosPaineisCentroDistribuicao();
    }
  }, [filtro.flg_mostrar_resultado, filtro.flg_aberto]);

  React.useEffect(() => {

    window.onbeforeunload = function () {
      if (cenario.flg_alterado && window.location.hostname !== 'localhost') {
        return t("pages.Deseja mesmo sair sem salvar o cenário?")
      } else {
        return null
      }
    };

    // Seu código a ser executado quando a altura da página mudar para atualizar o tamanho da tabela
    const observer = new MutationObserver(atualizaTamanhoTabela);
    if (bodyRef.current) {
      observer.observe(bodyRef.current, { attributes: true, childList: true, subtree: true });
    }
    return () => {
      observer.disconnect();
    };
  });

  React.useEffect(() => {
    carregarPeriodo()
  }, [periodoCarregado, listaPeriodoMes, listaPeriodoSemana])
  // para toda vez que o usuario atualizar algum atributo da lista atualizar as qts no cenario
  React.useEffect(() => {
    verificaCenarioModificou(filtro)
  }, [lista])

  React.useEffect(() => {
    carregarPainel(filtro)
  }, [filtro.flg_mostrar_resultado])

  function carregarPeriodo() {
    if (periodoCarregado && listaPeriodoMes && listaPeriodoSemana) {
      var aux = filtro.flg_ano_mes ? listaPeriodoMes : listaPeriodoSemana
      setListaPeriodo(aux);
      atualizarPeriodoPadrao(aux);
    }
  }

  async function atualizarPeriodoPadrao(lista: ModeloBasico[], filtroAux = filtro) {
    const aux = { ...filtroAux }
    if (lista.length > 0) {
      var t = new Date().toISOString();
      var atual = moment(t).add(aux.flg_ano_mes ? 0 : 1, "days").format(aux.flg_ano_mes ? "YYYYM" : "YYYYWW");
      aux.des_periodo_analisado = lista.find(x => x.codigo == atual)?.codigo || lista[0].codigo;
      const elemento = (document.getElementById("Período Analisado") as any)
      elemento.value = aux.des_periodo_analisado
    } else {
      aux.des_periodo_analisado = ""
    }

    setTimeout(() => {
      atualizarFiltro(aux)
    }, 500);
  }

  function carregarPainel(filtroAplicado: FiltroCentroDistribuicao) {
    if (filtro.flg_mostrar_resultado) {
      if (!filtroAplicado.des_periodo_analisado) {
        filtroAplicado = filtro
      }

      const _contador = contador++;
      setCarregarTabelaResultadoIA(true);
      setCarregarTabelaFatorDefinicao(true);

      if (_contador === contador - 1) {
        _centroDistribuicao.getResultadoIA(filtroAplicado).then(listaResultadoIA => {
          setListaResultadoIA(listaResultadoIA.sort((a, b) => a.ini_empresa.localeCompare(b.ini_empresa)));
          setCarregarTabelaResultadoIA(false);
          carregarFatorDefinicao(filtroAplicado)
        }).catch(() => {
          mostrarToast({
            titulo: t("pages.Erro!"),
            mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
            tipo: "error",
          });
          setCarregarTabelaResultadoIA(false);
        });
      }

      filtro.flg_carregar_painel = false
      setFiltro({ ...filtro })
    }
  }

  function carregarFatorDefinicao(filtroAplicado: FiltroCentroDistribuicao) {
    if (filtro.flg_mostrar_resultado) {
      const _contador = contador++;
      setCarregarTabelaFatorDefinicao(true);
      if (_contador === contador - 1) {
        _centroDistribuicao.getFatorDifinicao(filtroAplicado).then(listaFatorDefinicao => {
          setListaFatorDefinicao(listaFatorDefinicao.sort((a, b) => a.ini_empresa.localeCompare(b.ini_empresa)));
          setCarregarTabelaFatorDefinicao(false);
        }).catch(() => {
          mostrarToast({
            titulo: t("pages.Erro!"),
            mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
            tipo: "error",
          });
          setCarregarTabelaFatorDefinicao(false);
        });
      }
    }
  }

  async function verificaMudança() {
    if (cenario.num_de_produtos_alterado_capitao || cenario.num_de_produtos_alterado_relativo) {
      const modalResultado = await mostrarModal({
        titulo: t("pages.Aviso Importante"),
        subTitulo: t("pages.Caso você continue, perderá a simulação dos volumes/preços. Esta ação é irreversível!"),
        acao: t("Continuar")
      })
      return modalResultado
    }
    return Promise.resolve(true)
  }

  async function carregarDados(filtro: FiltroCentroDistribuicao) {
    try {
      if (usuario) {
        await new RepositorioCentroDistribuicaoEdicao().delete(usuario.cod_usuario)
      }
      const _contador = contador++;
      if (_contador === contador - 1) {
        await carregarLista(filtro);
        atualizarInterface();
      }
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }
  function limparFiltro() {

    const _filtro = new FiltroCentroDistribuicao();
    _filtro.flg_ano_mes = filtro.flg_ano_mes;
    _filtro.des_periodo_analisado = filtro.des_periodo_analisado;
    _filtro.flg_aberto = filtro.flg_aberto;
    _filtro.flg_mostrar_resultado = filtro.flg_mostrar_resultado;
    atualizarFiltro(_filtro)
    atualizarPeriodoPadrao(listaPeriodo, _filtro);
  }
  function carregarCenario(cenario: ModeloCenario) {

    setMostrarCenarioTabela(false)

    verificaMudança().then(res => {
      if (res) {
        // limparLista();
        cenario.flg_alterado = false;
        atualizarCenario(cenario);
        setCenarioOriginal({ ...cenario })

        var filtroAux = new FiltroCentroDistribuicao();
        filtroAux.flg_mostrar_resultado = cenario.flg_mostrar_resultado;
        filtroAux.cod_acabamento = cenario.des_filtro_acabamento;
        filtroAux.cod_empresa = cenario.des_filtro_empresa;
        filtroAux.cod_cluster = cenario.des_filtro_cluster;
        filtroAux.cod_diretoria = cenario.des_filtro_diretoria;
        filtroAux.cod_familia_subgrupo_produto_capitao = cenario.des_filtro_familia_subgrupo_produto_capitao;
        filtroAux.cod_linha_produto_capitao = cenario.des_filtro_linha_produto_capitao;
        filtroAux.des_periodo_analisado = cenario.num_semana_ano ? cenario.num_semana_ano.toString() : cenario.des_ano_mes;
        filtroAux.cod_produto = cenario.des_filtro_produto;
        filtroAux.cod_produto_capitao = cenario.des_filtro_produto_capitao;
        filtroAux.cod_temperatura = cenario.des_filtro_temperatura;
        filtroAux.cod_tipo_produto = cenario.des_filtro_tipo_produto;
        filtroAux.cod_mercado = cenario.des_filtro_mercado;
        filtroAux.cod_divisao_comercial = cenario.des_filtro_divisao_comercial;
        filtroAux.cod_bandeira = cenario.des_filtro_bandeira;
        filtroAux.ini_uf_destino = cenario.des_filtro_uf_destino;
        filtroAux.flg_ano_mes = !cenario.num_semana_ano;
        filtroAux.cod_cenario = cenario.cod_cenario

        filtrarDados(filtroAux)
      }
    })
  }

  function verificaCenarioModificou(data: FiltroCentroDistribuicao) {
    var cenarioAux = atualizarAtributosCenario(cenario, data)

    cenario.num_de_produtos_alterado_capitao = lista.filter(produto =>
      produto.num_nivel == 3 &&
      ((produto.num_preco_mkt_novo != produto.num_preco_mkt_original) || (produto.num_volume_mkt_novo != produto.num_volume_mkt_original))).length

    cenario.num_de_produtos_alterado_relativo = lista.filter(produto =>
      produto.num_nivel > 3 &&
      ((produto.num_preco_mkt_novo != produto.num_preco_mkt_original) || (produto.num_volume_mkt_novo != produto.num_volume_mkt_original))).length

    cenario.flg_alterado = !saoCenariosIguais(cenarioOriginal, cenarioAux)

    if (!cenario.flg_alterado && (cenario.num_de_produtos_alterado_capitao > 0 || cenario.num_de_produtos_alterado_relativo > 0)) {
      cenario.flg_alterado = true
    }

    atualizarCenario(cenario)
  }


  function atualizarFiltro(novoFiltro: FiltroCentroDistribuicao) {
    setFiltro({ ...novoFiltro });

    verificaCenarioModificou(novoFiltro);
  }

  async function filtrarDados(novoFiltro: FiltroCentroDistribuicao) {
    verificaMudança().then(res => {
      if (res) {
        // limparLista();
        setCarregando(true)
        novoFiltro.cod_usuario = usuario?.cod_usuario
        novoFiltro.flg_carregar_painel = true
        novoFiltro.flg_aberto = false
        setFiltro({ ...novoFiltro });

        atualizarFiltroContexto(novoFiltro)

        carregarDados(novoFiltro);
        carregarPainel(novoFiltro);
      }
    })
  }

  async function salvarCenario() {
    try {
      setCarregarBtnSalvar(true);
      setMostrarCenarioSalvar(false);

      if (cenario.flg_editar) {
        // cenario.des_nome = usuario?.displayName;
        cenario.cod_usuario = usuario?.cod_usuario;

        var cenarioAux = atualizarAtributosCenario(cenario, filtro)
        cenarioAux.flg_tela_planta_cd = false

        cenarioAux = await new RepositorioCenario().salvar(cenarioAux)
        cenarioAux.flg_editar = true;
        cenarioAux.flg_alterado = false;
        cenario.flg_alterado = false;
        cenario.cod_cenario = cenarioAux.cod_cenario;
        filtro.cod_cenario = cenarioAux.cod_cenario;
        filtroNoContexto.cod_cenario = cenarioAux.cod_cenario;

        atualizarCenario(cenarioAux);
        setCenarioOriginal({ ...cenarioAux });

        atualizarFiltro(filtro)
        atualizarFiltroContexto(filtroNoContexto)

        const totalSalvos = await salvarEditados();
        atualizarInterface()
        setCarregarBtnSalvar(false);

        if (usuario) {
          new RepositorioCentroDistribuicaoEdicao().delete(usuario.cod_usuario)
        }

        mostrarToast({
          titulo: t("pages.Sucesso!"),
          mensagem: `${t("pages.Cenário foi salvo e")} ${totalSalvos} ${totalSalvos > 0 ? t("pages.produtos foram atualizados") : t("pages.produto foi atualizado")} ! `,
          tipo: "success",
        });
      }
    } catch (error) {
      setCarregarBtnSalvar(false);
      cenario.flg_alterado = true;

      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }

  function exportarDados() {
    setCarregarBtnExportar(true);

    _centroDistribuicao.baixar(filtroNoContexto).then((req) => {
      var blob = new Blob([req.data], {
        type: req.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `centro_distribuicao_${new Date().toISOString()}.xlsx`;
      link.click();

      setCarregarBtnExportar(false);
    }).catch(() => {
      setCarregarBtnExportar(false);
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    });
  }

  return (
    <div className="centro-distribuicao" ref={bodyRef}>
      <FiltroCD
        titulo={t("pages.Centro de Distribuição")}
        filtro={filtro}
        cenario={cenario}
        listaTabela={listaTabela}
        listaPeriodo={listaPeriodo}
        atualizarFiltro={atualizarFiltro}
        filtrarDados={filtrarDados}
        setListaTabela={setListaTabela}
        mostrarCenarioNome={() => setMostrarCenarioNome(true)}
        setListaPeriodo={setListaPeriodo}
        carregarPeriodo={carregarPeriodo}
      />

      {filtro.flg_mostrar_resultado ? (
        <div id="paineis" style={{ overflowY: 'auto' }}>
          <Row className="mx-0">
            <Col md={4} className="ps-0">
              <CentroDistribuicaoPainelResultadoIA carregar={carregarTabelaResultadoIA} listaResultadoIA={listaResultadoIA} />
            </Col>
            <Col md={4}>
              <CentroDistribuicaoPainelFatores carregar={carregarTabelaFatorDefinicao} listaFatorDecisaoIA={listaFatorDefinicao} />
            </Col>
            <Col md={4}>
              <CentroDistribuicaoPainelDefinicao carregar={carregarTabelaFatorDefinicao} listaDefinicaoMkt={listaFatorDefinicao} />
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <div className="botao-cds my-1">
            <div>
              <Button className="me-1" variant="outline-primary" size="sm" onClick={limparFiltro}              >
                {t("pages.Limpar")}
              </Button>
              <Button disabled={lista.length == 0 || carregarBtnExportar} className="me-1"
                onClick={exportarDados} size='sm' variant="outline-dark">
                {carregarBtnExportar ?
                  <Spinner animation="border" size="sm" className="me-1" /> :
                  <BiCloudDownload className="me-1" />
                }
                {t("pages.Exportar")}
              </Button>
            </div>

            <div>

              <Button disabled={carregarBtnSalvar} onClick={() => setMostrarCenarioTabela(true)} size='sm' variant="primary">
                {t("pages.Cenários")}
              </Button>


              <Button disabled={!cenario.flg_editar || !cenario.flg_alterado}
                onClick={() => {
                  if (cenario.flg_editar) {
                    if (cenario.des_cenario) {
                      setMostrarCenarioSalvar(true)
                    } else {
                      setMostrarCenarioSalvarAposNome(true)
                      setMostrarCenarioNome(true)
                    }
                  } else {
                    filtro.cod_cenario = -1;
                    filtroNoContexto.cod_cenario = -1;
                    atualizarCenario(new ModeloCenario());
                  }

                }} size='sm' variant="dark" className="ms-1">
                {carregarBtnSalvar ?
                  <Spinner animation="border" size="sm" className="me-1" /> :
                  <BiSave className="me-1" />
                }

                {cenario.cod_cenario > 0 ? t("pages.Atualizar") : t("pages.Salvar")}
              </Button>

              <Button disabled={!cenario.cod_cenario && !cenario.flg_editar} title="Criar novo cenário"
                onClick={() => {
                  filtro.cod_cenario = -1;
                  filtroNoContexto.cod_cenario = -1;
                  atualizarCenario(new ModeloCenario());
                }} size='sm' variant="outline-dark" className="ms-1">
                {t("pages.Novo")}
              </Button>


              <Button className="ms-1" variant="primary" size="sm" disabled={listaPeriodo.length == 0}
                onClick={() => {
                  // setFiltroAberto
                  filtrarDados(filtro)
                }}
              >
                {t("pages.Buscar")}
              </Button>
            </div>
          </div>

          <div className="table-100 " >
            <TabelaCentroDistribuicao
              filtro={filtroNoContexto}
              aberto={filtro.flg_aberto}
              salvouEdicao={() => {
                // carregarFatorDefinicao(filtroNoContexto)
              }}
              carregandoPrimeiraVez={carregandoPrimeiraVez}
              setCarregandoPrimeiraVez={(valor: boolean) => {
                setCarregandoPrimeiraVez(valor)
              }}
            />
          </div>
        </>
      )}

      <ModalCenarioNome
        show={mostrarCenarioNome}
        nomeCenario={cenario.des_cenario || gerarTituloCenario(filtro, t("pages.CDs"))}
        onHide={() => {
          if (mostrarCenarioSalvarAposNome && cenario.des_cenario) {
            setMostrarCenarioSalvar(true);
          }
          setMostrarCenarioNome(false)
        }}
        salvar={(nome) => {
          if (cenario.des_cenario != nome) {
            cenario.flg_alterado = true
          }
          cenario.des_cenario = nome;
          atualizarCenario(cenario)
          if (mostrarCenarioSalvarAposNome && nome) {
            setMostrarCenarioSalvar(true);
            setMostrarCenarioSalvarAposNome(false);
          }
        }}
        verificarNome={async (nome) => {
          try {
            const cenarioAux = { ...cenario }
            cenarioAux.des_cenario = nome;
            cenarioAux.flg_tela_planta_cd = false
            const existe = await new RepositorioCenario().verificarCenarioPorNome(cenarioAux)
            return existe
          } catch (error) {
            mostrarToast({
              titulo: t("pages.Erro!"),
              mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
              tipo: "error",
            });
            return true
          }

        }}
      />

      <ModalCenarioTabela
        show={mostrarCenarioTabela}
        flg_ano_mes={filtro.flg_ano_mes}
        telaCenario={"CD"}
        abrirCenario={carregarCenario}
        onHide={() => setMostrarCenarioTabela(false)}
      />

      <ModalCenarioSalvar
        cenario={atualizarAtributosCenario(cenario, filtro)}
        show={mostrarCenarioSalvar}
        onHide={() => setMostrarCenarioSalvar(false)}
        des_periodo_analisado={formatarPeriodoData(filtro.des_periodo_analisado || "", filtro.flg_ano_mes)}
        editarNome={() => {
          setMostrarCenarioSalvarAposNome(true);
          setMostrarCenarioNome(true);
          setMostrarCenarioSalvar(false)
        }}
        salvarCenario={salvarCenario}
      />
    </div>
  );
}
