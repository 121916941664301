import { ConstanteGlobal } from "../config/constanteGlobal";
import { ProductCategoryModel } from "../models/product_category";
import { Repository } from "./repository";


export class FilterProductCategory {

  id = "";
  description = "";

  sort: Partial<ProductCategoryModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class ProductCategoryRepository extends Repository<ProductCategoryModel> {
  constructor() {
    super(ConstanteGlobal.PRODUCT_CATEGORY);
  }
}
