import { ModeloBase } from "./modeloBase";

export const ModeloSazonalidadeGranularidade = {
  CODIGO: [ "cod_familia_subgrupo_produto_capitao", "cod_linha_produto_capitao", "cod_produto_capitao","cod_gerencia"],
  DESCRICAO: [ "des_familia_subgrupo_produto_capitao", "des_linha_produto_capitao", "des_produto_capitao",  "des_gerencia"],
  INTERFACE: [ "Família Subgrupo", "Linha", "Capitão",  "Regional"],
}

export class ModeloSazonalidade extends ModeloBase {
  id: string = "";
  flg_aberto: boolean = false;
  oculta: boolean = false;
  num_nivel = 0;
  filhos = 0;

  cod_familia_subgrupo_produto_capitao: string = "";
  des_familia_subgrupo_produto_capitao: string = "";
  cod_linha_produto_capitao: string = "";
  des_linha_produto_capitao: string = "";
  cod_produto_capitao: string = "";
  des_produto_capitao: string = "";

 
  cod_gerencia: string = "";
  des_gerencia: string = "";
  cod_acabamento: string = "";
  cod_temperatura: string = "";

  num_volume_ia?: number = 0;
  num_fator_decisao_sazonalidade_preco_1?: number = 0;
  num_fator_decisao_sazonalidade_preco_2?: number = 0;
  num_fator_decisao_sazonalidade_preco_3?: number = 0;
  num_fator_decisao_sazonalidade_preco_4?: number = 0;
  num_fator_decisao_sazonalidade_preco_5?: number = 0;
  num_fator_decisao_sazonalidade_preco_6?: number = 0;
  num_fator_decisao_sazonalidade_preco_7?: number = 0;
  num_fator_decisao_sazonalidade_preco_8?: number = 0;
  num_fator_decisao_sazonalidade_preco_9?: number = 0;
  num_fator_decisao_sazonalidade_preco_10?: number = 0;
  num_fator_decisao_sazonalidade_preco_12?: number = 0;
  
  quantidade: number = 0;

  
}
