import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico } from "../models/basico";
import { ModeloGerencia } from "../models/gerencia";
import { Repositorio } from "./repositorio";


export class FiltroGerencia extends FiltroBasico<ModeloGerencia> {
  cod_canal ?: string;
  cod_diretoria ?: string;
}

export class RepositorioGerencia extends Repositorio<ModeloGerencia> {
  constructor() {
    super(ConstanteGlobal.GERENCIA);
  }
}
