import React, { createContext, useState, useContext, useRef } from "react";
import { Modal, Button, FormControl } from "react-bootstrap";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { AG_GRID_LOCALE_BR } from "../config/AgGridReact";
import { BaseModel } from "../models/base-model";

// Interface do modal
interface ModalInterface {
  titulo: string;
  acao?: string;
  itens?: BaseModel[];
  multiple?: boolean;
  selecionados?: string[];
}

// Interface do contexto do modal
interface ModalContextoInterface {
  modal: ModalInterface | null;
  mostrarModal(event: ModalInterface): Promise<BaseModel[]>;
  onHide: () => void;
}

// Contexto do modal
const ModalContexto = createContext<ModalContextoInterface>({} as ModalContextoInterface);

type Props = {
  children?: JSX.Element;
};

// Provedor de contexto do modal
export const ModalSelectProvider: React.FC<Props> = ({ children }) => {
  const [modal, setModal] = useState<ModalInterface | null>(null);
  const [show, setShow] = useState(false);
  const [promiseResolve, setPromiseResolve] = useState<((value: BaseModel[]) => void) | null>(null);
  const [rowData, setRowData] = useState<BaseModel[]>([]);
  const gridRef = useRef<AgGridReact<BaseModel>>(null);

  const columnDefs: ColDef[] = [
    {
      headerName: "Id",
      field: "id",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      flex: 1,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: false,
    },
    {
      headerName: "Valor",
      field: "description",
      flex: 3,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: false,
    },
  ];

  // Ocultar o modal
  function onHide() {
    setModal(null);
    setShow(false);
  }

  // Mostrar o modal
  const mostrarModal = (event: ModalInterface): Promise<BaseModel[]> => {
    return new Promise<BaseModel[]>((resolve) => {
      setModal(event);
      setShow(true);
      setRowData(event.itens || []);
      setPromiseResolve(() => resolve);
    });
  };

  // Executar ação do modal
  function executarAcao() {
    if (promiseResolve) {
      const selectedNodes = gridRef.current?.api.getSelectedNodes() || [];
      const selectedData = selectedNodes.map((node) => node.data).filter((data) => data !== undefined) as BaseModel[];
      promiseResolve(selectedData);
      setPromiseResolve(null);
    }
    setModal(null);
    setShow(false);
  }

  return (
    <ModalContexto.Provider value={{ modal, mostrarModal, onHide }}>
      <>
        {children}
        <Modal onHide={onHide} show={show} centered size="lg">
          <Modal.Header closeButton className="pb-0">
            <Modal.Title className="font-size-18 text-primary">{modal?.titulo}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="ag-theme-quartz w-100" style={{ height: 500 }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                embedFullWidthRows={true}
                rowSelection={modal && modal.multiple ? "multiple" : "single"}
                suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={20}
                localeText={AG_GRID_LOCALE_BR}
                onGridReady={(params) => {
                  const selectedIds = modal?.selecionados || [];
                  params.api.forEachNode((node) => {
                    if (selectedIds.includes(node.data.id)) {
                      node.setSelected(true);
                    }
                  });
                }}
                onRowClicked={(x) => {
                  x.node.setSelected(!x.node.isSelected());
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button variant={modal?.acao ? "secondary" : "primary"} onClick={onHide}>
              Fechar
            </Button>
            <Button variant="secondary" onClick={executarAcao}>
              Selecionar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </ModalContexto.Provider>
  );
};

// Hook personalizado para usar o contexto do modal
export function useModalSelect() {
  const contexto = useContext(ModalContexto);
  return contexto;
}
