import { AxiosInstance, AxiosRequestConfig } from "axios";
import { ModeloBase } from "../models/modeloBase";
import api from "../services/api";

export abstract class RepositorioFiltroRequest {
  num_pagina = 1;
  num_tamanho_pagina = 10;
  num_total = 0;

  filter: any = {};
  sort: any = {};
}

export abstract class Repositorio<T extends ModeloBase> {
  protected api: AxiosInstance;

  constructor(public nomeRepositorio: string) {
    this.api = api;
    this.api.interceptors.request.use((config: any) => {
      return config;
    });
  }

  public async get(): Promise<T> {
    return this.api.get(`${this.nomeRepositorio}_get`).then((res) => {
      return res.data;
    });
  }

  public async getAll(): Promise<T[]> {
    return this.api.get(`${this.nomeRepositorio}_get_all`).then((res) => {
      return res.data;
    });
  }

  baixar(filtro: any) {
    filtro = { ...filtro };
 
    return this.api.post(this.nomeRepositorio + "_baixar", filtro, {
      responseType: "arraybuffer",
    });
  }

  getLastUpdate() {
    return this.api.get(`${this.nomeRepositorio}_last_update`).then((res) => {
      return res.data[0].date_creation;
    });
  }


  public async salvar(model: T):Promise<T> {
    return this.api.post(`${this.nomeRepositorio}_salvar`, model).then(res=>res.data);
  }


  protected toObject(document: any): T {
    let data = { id: document.id, ...document.data() };
    return data;
  }

  protected filterSort(data: any) {
    var filter: any = { ...data };
    let aux = "";
    if (filter.sort) {
      Object.keys(filter.sort).forEach((key) => {
        if (filter.sort[key] != "") {
          aux += `${aux?.length > 0 ? "," : ""} ${key} ${
            filter.sort[key] === "asc" ? "ASC" : "DESC"
          } `;
        }
      });
    }
    filter.des_ordem = aux;
    delete filter.sort;
    return filter;
  }

  public async getFilter(data: any, prefix: string = ""): Promise<T[]> {
    return this.api
      .post(this.nomeRepositorio + prefix + "", this.filterSort(data))
      .then((res: any) => {
        return res.data;
      });
  }
}
