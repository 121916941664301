import { ConstanteGlobal } from '../config/constanteGlobal';
import { BaseModel } from '../models/base-model';
import { Repository } from './repository';


export class MesorregionRepositorio extends Repository<BaseModel> {
	constructor() {
		super(ConstanteGlobal.MESORREGION);
	}
}
