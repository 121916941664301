import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico, ModeloBasico } from "../models/basico";
import { ModeloUF } from "../models/uf";
import { Repositorio } from "./repositorio";

export class RepositorioUF extends Repositorio<ModeloUF> {
  constructor() {
    super(ConstanteGlobal.UF);
  }
}
