
import { ConstanteGlobal } from "../config/constanteGlobal";
import { ProductDepartmentModel } from "../models/product_department";
import { Repository } from "./repository";

export class FilterProductDepartment {

  id = "";
  description = "";

  sort: Partial<ProductDepartmentModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class ProductDepartmentRepository extends Repository<ProductDepartmentModel> {
  constructor() {
    super(ConstanteGlobal.PRODUCT_DEPARTMENT);
  }
}
