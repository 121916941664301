import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico } from "../models/basico";
import { ModeloCenario } from "../models/cenario";
import { FiltroCentroDistribuicao } from "./centroDistrubuicaoRepositorio";
import { Repositorio } from "./repositorio";

export class FiltroCenario extends FiltroBasico<ModeloCenario> {
  cod_usuario?: string;
  flg_tela_planta_cd = true
  flg_ano_mes = false
  des_cenario?: string;
  des_status?: string;
  num_quantidade_pagina = 7;
}

export class RepositorioCenario extends Repositorio<ModeloCenario> {
  constructor() {
    super(ConstanteGlobal.CENARIO);
  }

  carregarUsuario(flg_tela_planta_cd: boolean): Promise<ModeloCenario[]> {
    return this.api.get(this.nomeRepositorio + `_usuario_get_all?flg_tela_planta_cd=${flg_tela_planta_cd ? 1 : 0}`).then((res) => {
      return res.data
    });
  }

  excluirCenario(cenario: ModeloCenario): Promise<boolean> {
    return this.api.post(this.nomeRepositorio + '_excluir', cenario).then((res) => {
      return res.data
    })
  }

  finalizarCenario(cenario: ModeloCenario): Promise<boolean> {
    return this.api.post(this.nomeRepositorio + '_finalizar', cenario).then((res) => {
      return res.data
    })
  }

  verificarCenarioPorPeriodo(cenario: ModeloCenario): Promise<boolean> {
    return this.api.post(this.nomeRepositorio + '_verificar_por_periodo', cenario).then((res) => {
      return res.data
    })
  }

  verificarCenarioPorNome(cenario: ModeloCenario): Promise<boolean> {
    return this.api.post(this.nomeRepositorio + '_verificar_por_nome', cenario).then((res) => {
      return res.data
    })
  }

  buscarCenarioAplicado(filtro:FiltroCentroDistribuicao): Promise<ModeloCenario> {
    return this.api.post(this.nomeRepositorio + '_aplicado', filtro).then((res) => {
      return res.data && res.data[0].cod_cenario? res.data[0]: null
    })
  }
}
