import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

import { FilterCatalogoPrecoBase } from "../../core/repositories/CatalagoPrecoBaseRepositorio";
import { CatalogoPrecoBaseEditRepositorio } from "../../core/repositories/CatalagoPrecoBaseEdicaoRepositorio";
import moment from "moment";
import "./index.scss";
import { t } from "i18next";
import { TipoFormatacao } from "../../core/config/tipoFormatacao";
import { useToast } from "../../core/contexts/toast";
import { CatalogoPrecoBaseModel, CatalogoPrecoBaseModelGranularidade } from "../../core/models/catalagoPrecoBase";
import { formatarDecimal } from "../../core/utils/funcoes";
import { AG_GRID_LOCALE_BR } from "../../core/config/AgGridReact";
import { OrdemCompraModel } from "../../core/models/ordemCompra";
import { FilterOrdemCompra } from "../../core/repositories/ordemCompraRepositorio";

interface Props {
  show: boolean;
  data?: CatalogoPrecoBaseModel;
  search: FilterCatalogoPrecoBase;
  subTitle?: string;
  onHide: () => void;
}

export const ModalEdits: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState<string>("");
  const gridRef = React.useRef<AgGridReact<CatalogoPrecoBaseModel>>(null);

  const [rowData, setRowData] = React.useState<OrdemCompraModel[]>([]);
  const { mostrarToast } = useToast();

  React.useEffect(() => {
    setLoading(true);
    console.log(props.data);
    if (props.show && props.data) {
      // setRowData([])
      showLoadingOverlay();
      setRowData(Array.from({ length: 20 }, () => new OrdemCompraModel()));

      setTitle((props.data as any)[CatalogoPrecoBaseModelGranularidade.DESCRICAO[props.data.num_level]]?.toString());

      const filter = new FilterOrdemCompra();
      filter.branch_code = props.data.branch_code;
      filter.category_code = props.data.category_code;
      filter.subcategory_code = props.data.subcategory_code;
      filter.department_code = props.data.department_code;
      filter.mesorregion_code = props.data.mesorregion_code;
      filter.product_code = props.data.product_code;
      filter.year_week = props.search.year_week;

      console.log(filter);
      new CatalogoPrecoBaseEditRepositorio().getFilter(filter, "_post").then((res) => {
        setRowData(res ? res : []);
      }).catch(() => {
     mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
      }).finally(() => {
        setLoading(false);
      });
    }

    // setTimeout(() => {
    //   setLoading(false);
    //   setRowData(generateFakeData(30));
    // }, 1500);
  }, [props.show, props.data]);

  const CustomSkeleton = (props: { value: any; money?: boolean; date?: boolean }) => {
    return (
      <>
        {loading ? (
          <Skeleton />
        ) : props.date ? (
          moment(props.value).format(TipoFormatacao.FORMATO_DATA_HORA)
        ) : typeof props.value === "number" ? (
          formatarDecimal(props.value, props.money)
        ) : typeof props.value === "boolean" ? (
          props.value ? (
            "Sim"
          ) : (
            "Não"
          )
        ) : (
          props.value
        )}
      </>
    );
  };
  function getCoDef(headerName: string, field: string, filter = "agTextColumnFilter", co_width: number = 150) {
    return {
      headerName: headerName,
      field: field,
      minWidth: co_width,
      flex: 1,
      floatingFilter: true,
      filter,
      resizable: true,
      cellRenderer: CustomSkeleton,
    };
  }
  const columnDefs: ColDef[] = [
    getCoDef("Filial", "branch_description"),
    getCoDef("Departamento", "department_description"),
    getCoDef("Categoria", "category_description"),
    getCoDef("Subcategoria", "subcategory_description"),
    getCoDef("Mesorregião", "mesorregion_description"),
    getCoDef("Produto", "product_description", undefined, 350),
    {
      headerName: "Preço",
      field: "price_edit",
      minWidth: 150,
      flex: 1,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      resizable: false,
      cellRenderer: (params: any) => <CustomSkeleton value={params.value} money={true} />,
    },
    getCoDef("Usuário", "user_name"),
    getCoDef("Ação", "price_type"),
    {
      headerName: "Data",
      field: "time_created",
      minWidth: 150,
      flex: 1,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: false,
      cellRenderer: (params: any) => <CustomSkeleton value={params.value} date={true} />,
    },
    getCoDef("Ativo", "is_active", "agSetColumnFilter"),
  ];

  function getColumnDefs() {
    const aux = [...columnDefs];
    if (props.data) {
      if (props.data?.num_level >= 0) delete aux[0];
      if (props.data?.num_level >= 1) delete aux[1];
      if (props.data?.num_level >= 2) delete aux[2];
      if (props.data?.num_level >= 3) delete aux[3];
      if (props.data?.num_level >= 4) delete aux[4];
    }

    return aux.filter((item) => item !== undefined);
  }


  function showLoadingOverlay() {
    if (props.show && gridRef.current && gridRef.current.api) {
      gridRef.current.componentDidUpdate = () => {
        if (loading && gridRef.current && gridRef.current.api) {
          gridRef.current.api.showLoadingOverlay();
        } else if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.hideOverlay();
        }
      };
    }
  }

  return (
    <>
      <Modal centered aria-labelledby="contained-modal-title-vcenter" size="xl" {...props}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center">
            Histórico de alterações: {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ag-theme-quartz w-100" style={{ height: 500 }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              gridId="modalBuyers"
              columnDefs={getColumnDefs()}
              embedFullWidthRows={true}
              pagination={true}
              paginationPageSize={20}
              localeText={AG_GRID_LOCALE_BR}
              overlayLoadingTemplate='<span class="">Carregando...</span>'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              props.onHide();
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
