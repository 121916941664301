import * as React from "react";
import PrecoBaseTable from "./table";
import { useEffect, useState } from "react";
import "./index.scss";
import { FiltroPrecoBase } from "../../core/repositories/precoBaseRepositorio";
import { Filter } from "./filtro";
import { useToast } from "../../core/contexts/toast";
import { useTranslation } from "react-i18next";
import { useAutenticação } from "../../core/contexts/auth";
import { precoBaseContexto } from "../../core/contexts/precoBaseContexto";

var count: number = 0;

const BasePrice: React.FC = () => {
  const { mostrarToast } = useToast();
  const { usuario } = useAutenticação()

  const { t } = useTranslation();

  const { filtroNoContexto, carregarLista, atualizarInterface, atualizarFiltroContexto, setCarregando } = precoBaseContexto();

  const [search, setSearch] = useState(new FiltroPrecoBase());
  const [loadingFirstTime, setLoadingFirstTime] = useState(true);
  const hasFetchedData = React.useRef(false); // Usando useRef para armazenar o estado de carregamento

  useEffect(() => {
    console.log("useEffect 1");
    if (hasFetchedData.current) {
      console.log("useEffect 2");
      return;
    } // Verifica se os dados já foram buscados
    setCarregando(true);
    hasFetchedData.current = true; // Atualiza o estado de carregamento
    console.log("useEffect 3");
  }, []);

  async function getData(search: FiltroPrecoBase) {
    console.log("getData");
    try {
      // if (usuario) {
      //   await new RepositorioCentroDistribuicaoEdicao().delete(usuario.cod_usuario)
      // }
      const _count = count++;

      if (_count === count - 1) {
        await carregarLista(search);
        atualizarInterface();
      }
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
      return Promise.reject(error);
    }
  }

  function updateSearch(newSearch: FiltroPrecoBase) {
    setSearch({ ...newSearch });
  }

  async function filtrarDados(newSearch: FiltroPrecoBase) {
    console.log("searchData");
    // limparLista();
    setCarregando(true);

    // newSearch.cod_usuario = usuario?.cod_usuario;
    setSearch({ ...newSearch });

    atualizarFiltroContexto(newSearch);

    getData(newSearch).then((res) => {
      // debugger;
      // newSearch.flg_aberto = false;
      // setSearch({ ...newSearch });
      // atualizarFiltroContexto(newSearch);
    }).catch(() => {});

  }

  return (
    <div className="BasePrice">
      <Filter filtro={search} atualizarFiltro={updateSearch} filtrarDados={filtrarDados} />
      {/* <div className="d-flex"> */}
      <div>
        <PrecoBaseTable
          search={filtroNoContexto}
          open={search.flg_aberto}
          loadingFirstTime={loadingFirstTime}
          setLoadingFirstTime={(value: boolean) => {
            setLoadingFirstTime(value);
          }}
        />

      </div>
    </div>
  );
};

export default BasePrice;


