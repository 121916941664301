import { ModeloBase } from "./modeloBase";

export const ModeloPrecoBaseGranularidade = {
  CODIGO: ["des_agencia", "des_departamento", "des_categoria", "des_subcategoria", "des_produto", "des_mesorregiao"],
  DESCRICAO: ["des_agencia", "des_departamento", "des_categoria", "des_subcategoria", "des_produto", "des_mesorregiao"],
  INTERFACE: ["Filial", "Departamento", "Categoria", "SubCategoria", "Produto", "Mesorregião"],
};

export class ModeloPrecoBase extends ModeloBase {
  id: number = 0;
  des_agencia: string = '';
  des_departamento: string = '';
  des_categoria: string = '';
  des_subcategoria: string = '';
  des_mesorregiao: string = '';
  des_produto: string = '';
  num_estoque: number = 0;
  num_custo_cont: number = 0;
  num_volume_vendido: number = 0;
  num_preco_executado: number = 0;
  num_preco_sem_margem: number = 0;
  num_demanda_sugerida_ia: number = 0;
  flg_obrigatorio: number = 0;
  num_estoque_compradores: number = 0;
  num_preco_compradores: number = 0;
  num_preco_atual_tabela: number = 0;
  num_preco_sugerido_ia: number = 0;
  num_tendencia_demanda_s1: number = 0;
  num_tendencia_preco_s1: number = 0;
  num_preco_concorrente_media: number = 0;

  num_nivel: number = 0;
  flg_aberto: boolean = false;
  quantity: number = 0;
  flg_aceito: boolean = false;
  flg_automatizado: boolean = false;
  num_preco_edicao?: number;
  num_preco_salvo?: number;
  flg_editado: boolean = false;
  flg_oculto: boolean = false;
  flg_salvando: boolean = false;

  num_ano_semana: string = "";
  sons: number = 0;

  // id: number = 0;
  // des_agencia: number = 0;
  // des_agencia: string = "";
  // des_departamento: number = 0;
  // des_departamento: string = "";
  // des_categoria: number = 0;
  // des_categoria: string = "";
  // des_subcategoria: number = 0;
  // des_subcategoria: string = "";
  // des_status_subcategoria: string = "";
  // cod_preco_tabela: number = 0;
  // des_produto: number = 0;
  // des_produto: string = "";
  // ano_semana: string = "";
  // num_preco_atual_tabela: number = 0;
  // num_previsao_demanda: number = 0;
  // num_tendencia_preco: number = 0;
  // num_preco_sugerido_ia: number = 0;
  // num_preco_sem_margem: number = 0;
  // num_quantidade_vendida: number = 0;
  // num_preco_vendido: number = 0;
  // num_estoque: number = 0;
  // num_preco_estoque: number = 0;
  // num_estoque_compradores: number = 0;
  // num_preco_compradores: number = 0;

  // num_nivel: number = 0;

  // num_preco_edicao?: number;
  // is_aceito: boolean = false;
  // flg_automatizado: boolean = false;
  // is_obrigatorio: boolean = false;
  // // is_salvo: boolean = false;

  // num_tendencia_demanda_s1: number = 0;
  // num_tendencia_demanda_s2: number = 0;
  // num_tendencia_demanda_s3: number = 0;
  // num_id_centro_distribuicao: number = 0;
  // num_id_categoria_produto: number = 0;
  // des_nome_centro_distribuicao: string = "";
  // num_penalidade_custos: number = 0;
  // num_penalidade_estoque: number = 0;
  // num_penalidade_margem: number = 0;
  // num_penalidade_quantidade: number = 0;
  // num_penalidade_vendas: number = 0;
  // num_preco_competidor_moda: number = 0;
  // num_preco_competidor_media: number = 0;
  // num_preco_atual: number = 0;
  // num_preco_previsao: number = 0;
  // num_preco_anterior_d2: number = 0;
  // num_preco_anterior_d3: number = 0;
  // num_tendencia_preco_s1: number = 0;
  // num_tendencia_preco_s2: number = 0;
  // num_tendencia_preco_s3: number = 0;

  // des_produto_id: number = 0;
  // des_produto_nome: string = "";
  // des_nome_estado: string = "";
  // cod_estado_id: number = 0;
  // num_custo_estoque: number = 0;
  // num_quantidade_estoque: number = 0;
  // num_preco_tabela: number = 0;
  // num_preco_salvo?: number;

  // is_carregado?: boolean;
  // num_avaliacao: number = 0;
  // num_preco_salvo_edicao?: number;
  // margem?: any;

  // is_aberto: boolean = false;
  // is_editar: boolean = false;
  // num_nivel: number = 0;
  // num_quantidade: number = 0;
  // num_quantidade_matematica: number = 0;
  // is_oculto: boolean = false;
  // num_sons: number = 0;
  // is_salvando: boolean = false;
  // flg_aberto = false;
}

export class PrecoBaseDateModel {
  des_data?: string;
  des_data_exibicao: string = "";
}

export class PrecoBaseTableModel {
  des_preco_tabela: string = "";
}
