import { ModeloBase } from "./modeloBase";

export class ModeloBasico extends ModeloBase {
  codigo!: string;
  descricao!: string;
}

export abstract class FiltroBasico<T> {
  sort: Partial<T> = {};

  num_pagina_atual = 1;
  num_quantidade_pagina = 10;
  num_total = 0;
}
