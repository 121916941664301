import { createContext, useState, useContext, useEffect } from 'react';
// import Cookies from 'universal-cookie/es6';
import { ModeloPlantaIndustrial, ModeloPlantaIndustrialGranularidade } from '../models/plantaIndustrial';
import { FiltroPlantaIndustrial, RepositorioPlantaIndustrial } from '../repositories/plantaIndustrialRepositorio';
import { ModeloPlantaIndustrialEdicao } from '../models/plantaIndustrialEdicao';
import { RepositorioPlantaIndustrialEdicao } from '../repositories/plantaIndustrialEdicaoRepositorio';
import { FiltroPlantaIndustrialPlantas, RepositorioPlantaIndustrialPlantas } from '../repositories/plantaIndustrialPlantasRepositorio';
import { ModeloEmpresa } from '../models/empresa';
import { RepositorioEmpresa } from '../repositories/empresaRepositorio';
import { ModeloPlantaIndustrialPlantas } from '../models/plantaIndustrialPlantas';
import { ModeloCenario } from '../models/cenario';
import { useAutenticação } from './auth';

// O que o contexto irá passar para o outros componentes
interface PlantaIndustrialContextoInterface {
	lista: ModeloPlantaIndustrial[] | [];
	plantas: ModeloEmpresa[] | [];
	cenario: ModeloCenario;
	carregando: boolean;
	carregandoFilho: boolean;
	filtroNoContexto: FiltroPlantaIndustrial;
	carregarLista(filtro: FiltroPlantaIndustrial): Promise<ModeloPlantaIndustrial[]>;
	adicionarLista(filtro: FiltroPlantaIndustrial, index: number): Promise<number>;
	removerLista(index: number): void;
	salvarEditados(): Promise<number>;
	limparLista(): void;
	atualizarInterface(): void;
	atualizarUmaLinha(linha: ModeloPlantaIndustrial, index: number): void;
	atualizarPlantas(plantas: ModeloEmpresa[]): void;
	carregarPlantas(filtro: FiltroPlantaIndustrial): Promise<void>;
	atualizarCenario(cenario: ModeloCenario): void;
	salvarLinhaEditada(produto: ModeloPlantaIndustrial, cod_cenario: number, index: number, flg_nivel_relacionado: boolean): Promise<void>;
	atualizarFiltroContexto(filtro: FiltroPlantaIndustrial): void;
	setCarregando(carregando: boolean): void;
}

// Como o objeto de contexto vai iniciar
const PlantaIndustrialContexto = createContext<PlantaIndustrialContextoInterface>({} as PlantaIndustrialContextoInterface);

type Props = {
	children?: JSX.Element;
};

// Componente de contexto que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const PlantaIndustrialProvider: React.FC<Props> = ({ children }) => {
	const [carregando, setCarregando] = useState(false);
	const [carregandoFilho, setCarregandoFilho] = useState(false);

	var [lista, setLista] = useState<ModeloPlantaIndustrial[]>([]);
	const [filtroNoContexto, setFiltroContexto] = useState(new FiltroPlantaIndustrial());
	const [plantas, setPlantas] = useState<ModeloEmpresa[]>([]);
	const [cenario, setCenario] = useState<ModeloCenario>(new ModeloCenario());
	const [contador, setContador] = useState(0);

	const { usuario } = useAutenticação();

	const _plantaIndustrial = new RepositorioPlantaIndustrial();
	const _plantaIndustrialEdicao = new RepositorioPlantaIndustrialEdicao();

	useEffect(() => {
		(async () => {
			new RepositorioEmpresa().getPlantas().then((res) => {
				setPlantas(res);
			});
		})();
		// eslint-disable-next-line
	}, []);

	function atualizarPlantas(plantas: ModeloEmpresa[]) {

		setPlantas(plantas);
	}
	//retorna uma lista de atributos unicos
	function buscarPorNivelAux(itens: any[], atributo: string) {

		const uniqueValues = Array.from(new Set(itens.filter(x => x[atributo]).map(x => x[atributo])));
		return uniqueValues.toString();
	}

	function buscarPorNivel(nivel: number, filtro: FiltroPlantaIndustrial) {
		// const itens = lista.filter(x => x.nivel === nivel && plantas.filter(y => y.selecionada).length != plantas.filter(x => x.selecionada).length)
		// para carregar somente os itens da lista que esta sem carregar 
		const itens = lista.filter(x => x.num_nivel === nivel && plantas.filter(y => {
			var auxAntigo = x.plantas?.filter(z => y.selecionada && z.cod_empresa == y.cod_empresa && z.num_preco == 0)
			return auxAntigo && auxAntigo.length > 0
		}).length > 0)

		if (itens.length > 0) {
			const codigos = plantas.filter(x => x.selecionada).map(x => x.cod_empresa);
			var filtroAux = { ... new FiltroPlantaIndustrialPlantas(), ...filtro }
			for (let index = 0; index <= nivel; index++) {
				(filtroAux as any)[ModeloPlantaIndustrialGranularidade.CODIGO[index]] = buscarPorNivelAux(itens, ModeloPlantaIndustrialGranularidade.CODIGO[index])
			}
			filtroAux.des_ano_mes = lista[0].des_ano_mes
			filtroAux.num_semana_ano = lista[0].num_semana_ano
			filtroAux.cod_empresa = codigos.toString()
			filtroAux.num_nivel = nivel;
			return filtroAux;
		} else {
			return null;
		}
	}

	// a busca de plantas é feita por nivel
	async function carregarPlantas(filtro: FiltroPlantaIndustrial) {
		try {
			//se nao tiver nenhuma selecionada, não carrega
			if (!plantas.some(x => x.selecionada)) {
				return
			}
			const _repositorio = new RepositorioPlantaIndustrialPlantas();
			//buscar para todos os niveis
			for (let index = 0; index < ModeloPlantaIndustrialGranularidade.CODIGO.length; index++) {
				// verifica se tem alguma linha daquele nivel
				if (lista.filter(x => x.num_nivel === index).length > 0) {
					// cria uma lista das plantas que estao selecionada
					const plantasAux = plantas.filter(x => x.selecionada).map(x => {
						var modelo = new ModeloPlantaIndustrialPlantas();
						modelo.cod_empresa = x.cod_empresa;
						// modelo.des_empresa = x.des_empresa;
						return modelo
					})

					lista.filter(x => x.num_nivel === index).map(x => {
						const plantasAntigas = x.plantas;
						x.plantas = plantasAux.map(y => {
							var auxAntigo = plantasAntigas?.find(z => z.cod_empresa == y.cod_empresa)
							return auxAntigo ? auxAntigo : { ...y }
						})
					})

					const filtroAux = buscarPorNivel(index, filtro)
					if (filtroAux) {
						// filtroAux.des_periodo_analisado = filtro.des_periodo_analisado
						const res = await _repositorio.getFilter(filtroAux)
						res.forEach(element => {
							const item = lista.find((x) => {
								var encontrado = x.num_nivel == index;
								for (let index = 0; index < ModeloPlantaIndustrialGranularidade.CODIGO.length && encontrado; index++) {
									encontrado = (x as any)[ModeloPlantaIndustrialGranularidade.CODIGO[index]] == (element as any)[ModeloPlantaIndustrialGranularidade.CODIGO[index]]
								}
								return encontrado;
							})

							if (item) {
								var planta = item.plantas?.find(x => x.cod_empresa == element.cod_empresa);
								if (planta) {
									planta.num_imposto_perc = element.num_imposto_perc;
									planta.num_frete = element.num_frete;
									planta.num_preco = element.num_preco;
									planta.carregando = false;
								}
							}
						});
					}
					lista.filter(x => x.num_nivel === index).map(x => x.plantas?.map(y => y.carregando = false))
				}
				atualizarInterface()
			}
		} catch (error) {
			lista.map(x => x.plantas?.map(y => y.carregando = false))
			atualizarInterface()
			return Promise.reject(error)
		}
	}

	function atualizarUmaLinha(linhaNova: ModeloPlantaIndustrial, index: number) {
		lista[index] = linhaNova;
		atualizaLista(lista);
	}

	async function carregarLista(filtro: FiltroPlantaIndustrial) {
		const _contador = contador + 1;
		setContador(_contador)
		setCarregando(true);

		const plantaIndustrialRetorno = await _plantaIndustrial.getFilter(filtro, "_post")

		if (contador === _contador - 1) {
			//para abrir quando carrega trocar aberto para true
			if (filtro.num_nivel == 0) {
				plantaIndustrialRetorno.map(x => { x.flg_aberto = (localStorage.getItem("editando") == "Sim" && window.location.hostname === 'localhost'); x.num_nivel = 0; return x; });
			}


			const listaAux = [...plantaIndustrialRetorno]
			atualizaLista(listaAux);
			setCarregando(false);
			await carregarDados(filtro)
			await carregarPlantas(filtro)

			if (localStorage.getItem("editando") == "Sim" && window.location.hostname === 'localhost') {

				for (let j = 0; j < 5; j++) {
					for (let i = 0; i < lista.length && lista[i].num_nivel < 5; i++) {
						await adicionarLista(filtro, i);
					}
					await new Promise(resolve => {
						setTimeout(resolve, 1500);
					});
				}
			}

			return [...plantaIndustrialRetorno]

		} else {
			return [...lista]
		}
	}

	async function carregarDados(filtro: FiltroPlantaIndustrial) {
		//se nao tiver nenhuma selecionada, não carrega
		if (lista.length) {
			// carregarModelo(filtro)
			await Promise.all([
				carregarModeloSugeridoMkt(filtro),
				carregarEstoque(filtro),
				carregarPlanoProducao(filtro)
			])

		}
	}

	// funcao para carregar todos os elementos que esta relacionado com a linha
	async function carregarNivelRelacionado(index: number) {
		const produto = lista[index]
		for (let i = 0; i < lista.length; i++) {
			if (i != index) {
				var igual = true;
				const produtoAux = lista[i];
				for (let j = 0; j <= produtoAux.num_nivel && igual; j++) {
					if ((produtoAux as any)[ModeloPlantaIndustrialGranularidade.CODIGO[j]] != (produto as any)[ModeloPlantaIndustrialGranularidade.CODIGO[j]]) {
						igual = false
					}
				}
				if (igual) {
					produtoAux.num_fator_decisao_elasticidade = undefined
					produtoAux.num_fator_decisao_giro = undefined
					produtoAux.num_fator_decisao_sazonalidade = undefined
					produtoAux.num_fator_decisao_tendencia = undefined
					produtoAux.flg_elasticidade = undefined
					produtoAux.flg_carregando_preco = true;
					produtoAux.flg_carregando_volume = true;
					produtoAux.editado_usuario = ""
				}
			}
		}
		if (produto.flg_aberto) {
			const nivel = lista[index].num_nivel;
			var indexFinal = index + 1
			while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
				const produtoAux = lista[indexFinal];
				produtoAux.num_fator_decisao_elasticidade = undefined
				produtoAux.num_fator_decisao_giro = undefined
				produtoAux.num_fator_decisao_sazonalidade = undefined
				produtoAux.num_fator_decisao_tendencia = undefined
				produtoAux.flg_elasticidade = undefined
				produtoAux.flg_carregando_preco = true;
				produtoAux.flg_carregando_volume = true;
				produtoAux.plantas?.map(x => { x.num_preco = 0; x.carregando = true; return x })
				indexFinal++
			}
		}
		atualizarInterface()
		//carrega nivel abaixo
		for (let i = 0; i < lista.length; i++) {
			const produtoAux = lista[i];

			if ((produtoAux.flg_carregando_preco || produtoAux.flg_carregando_volume)) {
				const filtroAux = { ...filtroNoContexto }
				for (let j = 0; j <= produtoAux.num_nivel; j++) {
					(filtroAux as any)[ModeloPlantaIndustrialGranularidade.CODIGO[j]] = (produtoAux as any)[ModeloPlantaIndustrialGranularidade.CODIGO[j]]
				}
				filtroAux.num_nivel = produtoAux.num_nivel;
				const retorno = await _plantaIndustrial.getFilter(filtroAux, "_modelo_sugerido_mkt")

				if (retorno.length) {
					produtoAux.num_fator_decisao_elasticidade = retorno[0].num_fator_decisao_elasticidade || 0;
					produtoAux.num_fator_decisao_giro = retorno[0].num_fator_decisao_giro || 0;
					produtoAux.num_fator_decisao_sazonalidade = retorno[0].num_fator_decisao_sazonalidade || 0;
					produtoAux.num_fator_decisao_tendencia = retorno[0].num_fator_decisao_tendencia || 0;
					produtoAux.flg_carregando_preco = false;
					produtoAux.flg_carregando_volume = false;


					produtoAux.num_volume_mkt_novo = retorno[0].num_volume_mkt_novo || 0;
					produtoAux.num_preco_mkt_novo = retorno[0].num_preco_mkt_novo || 0;
					produtoAux.num_preco = retorno[0].num_preco_mkt_novo || 0;
					produtoAux.num_volume = retorno[0].num_volume_mkt_novo || 0;
					produtoAux.flg_elasticidade = retorno[0].flg_elasticidade;
					produtoAux.plantas?.map(x => { x.num_preco = 0; x.carregando = true; return x })

				} else {
					produtoAux.flg_carregando_preco = false;
					produtoAux.flg_carregando_volume = false;

					produtoAux.num_fator_decisao_elasticidade = 0;
					produtoAux.num_fator_decisao_giro = 0;
					produtoAux.num_fator_decisao_sazonalidade = 0;
					produtoAux.num_fator_decisao_tendencia = 0;
				}
				atualizarInterface()

			} else {
				produtoAux.flg_carregando_preco = false;
				produtoAux.flg_carregando_volume = false;
				produtoAux.num_fator_decisao_elasticidade = 0;
				produtoAux.num_fator_decisao_giro = 0;
				produtoAux.num_fator_decisao_sazonalidade = 0;
				produtoAux.num_fator_decisao_tendencia = 0;
				atualizarInterface()
			}
		}
	}

	async function salvarLinhaEditada(produto: ModeloPlantaIndustrial, cod_cenario: number, index: number, flg_nivel_relacionado: boolean) {
		if ((produto.num_preco_mkt_novo != produto.num_preco_mkt_original) || (produto.num_volume_mkt_novo != produto.num_volume_mkt_original)) {
			const item = new ModeloPlantaIndustrialEdicao();
			item.cod_usuario = usuario?.cod_usuario;

			item.cod_canal = produto.cod_canal;
			item.cod_gerencia = produto.cod_gerencia || filtroNoContexto.cod_gerencia;
			// item.cod_linha = produto.cod_linha_produto_capitao;
			item.cod_produto = produto.cod_produto || filtroNoContexto.cod_produto;
			item.cod_produto_capitao = produto.cod_produto_capitao;
			if (filtroNoContexto.flg_ano_mes) {
				item.des_ano_mes = filtroNoContexto.des_periodo_analisado;
			} else {
				item.num_semana_ano = filtroNoContexto.des_periodo_analisado;
			}

			//só considedera a flg_elasticidade quando é capitao
			item.flg_elasticidade = produto.num_nivel != 3 ? true : !!produto.flg_elasticidade;
			item.cod_linha_produto_capitao = produto.cod_linha_produto_capitao;
			item.cod_familia_subgrupo_produto_capitao = produto.cod_familia_subgrupo_produto_capitao;
			// item.cod_cenario = cod_cenario || filtroNoContexto.cod_cenario;
			item.cod_cenario = cod_cenario;

			if (produto.editado_usuario == "preco") {
				item.num_preco_mkt_novo = produto.num_preco_mkt_novo;
				item.num_volume_mkt_novo = undefined
				if (flg_nivel_relacionado) {
					produto.flg_carregando_preco = true;
					produto.num_preco = produto.num_preco_mkt_novo;
				}
			} else {
				item.num_volume_mkt_novo = produto.num_volume_mkt_novo;
				item.num_preco_mkt_novo = undefined
				if (flg_nivel_relacionado) {
					produto.flg_carregando_volume = true;
					produto.num_volume = produto.num_volume_mkt_novo;
				}
			}
			produto.plantas?.map(x => { x.num_preco = 0; x.carregando = true; return x })
			atualizaLista(lista)
			await _plantaIndustrialEdicao.salvar(item);
			if (flg_nivel_relacionado) {
				await carregarNivelRelacionado(index)
			}
			carregarPlantas(filtroNoContexto)
		}
	}

	async function salvarEditados() {
		var totalSalvos = 0;
		for (let index = 0; index < lista.length; index++) {
			const produto = lista[index];

			if (produto.num_nivel > 2 && produto.editado_usuario && ((produto.num_preco_mkt_novo != produto.num_preco_mkt_original) || (produto.num_volume_mkt_novo != produto.num_volume_mkt_original))) {
				if (produto.flg_elasticidade == false) {
					const produto1 = { ...produto }
					const produto2 = { ...produto }
					produto.editado_usuario
					produto1.editado_usuario = "preco"
					produto2.editado_usuario = "volume"
					await salvarLinhaEditada(produto1, cenario.cod_cenario, index, false)
					await salvarLinhaEditada(produto2, cenario.cod_cenario, index, false)
				} else {
					await salvarLinhaEditada(produto, cenario.cod_cenario, index, false)
				}
				produto.flg_carregando_preco = false
				produto.flg_carregando_volume = false
				totalSalvos++;
			}
			produto.num_preco_mkt_original = produto.num_preco_mkt_novo;
			produto.num_volume_mkt_original = produto.num_volume_mkt_novo;
		}
		atualizaLista(lista)
		return totalSalvos
	}

	function removerLista(index: number) {
		lista[index].flg_aberto = false;
		const nivel = lista[index].num_nivel;
		var indexFinal = index + 1

		while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
			const element = lista[indexFinal];
			element.oculta = true
			element.flg_aberto = false
			indexFinal++
		}

		atualizaLista(lista)
	}

	async function adicionarLista(filtroGeral: FiltroPlantaIndustrial, index: number) {
		const linha = lista[index];
		setCarregandoFilho(true)
		linha.flg_aberto = true;
		try {
			if (linha.filhos > 0) {
				const nivel = lista[index].num_nivel;
				var indexFinal = index + 1

				while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
					const element = lista[indexFinal];
					// para mostrar somente os que estão um nível abaixo
					element.oculta = (nivel + 1) != lista[indexFinal].num_nivel
					indexFinal++
				}
				atualizaLista(lista)

			} else {
				const filtro = { ...filtroGeral }
				filtro.num_nivel = linha.num_nivel + 1

				for (let index = 0; index < filtro.num_nivel; index++) {
					(filtro as any)[ModeloPlantaIndustrialGranularidade.CODIGO[index]] = (linha as any)[ModeloPlantaIndustrialGranularidade.CODIGO[index]]
				}

				const response = await _plantaIndustrial.getFilter(filtro, "_post")

				// await new Promise((resolve) => setTimeout(resolve, 2000));
				response.map((item) => {
					item.num_nivel = linha.num_nivel + 1;
				});
				linha.filhos = response.length;
				lista.splice(index + 1, 0, ...response);
				carregarDados(filtro)
				atualizaLista(lista)
				carregarPlantas(filtroGeral)
			}
			setCarregandoFilho(false)
			return linha.filhos;
		} catch (error) {
			setCarregandoFilho(false)
			return 0;
		}
	}

	function filtroAuxAtributo(element: any, attr: string) {
		return lista.filter(x => (x as any)[attr] === undefined).find((x) => {
			var encontrado = true;
			for (let index = 0; index < ModeloPlantaIndustrialGranularidade.CODIGO.length && encontrado; index++) {
				encontrado = (x as any)[ModeloPlantaIndustrialGranularidade.CODIGO[index]] == (element as any)[ModeloPlantaIndustrialGranularidade.CODIGO[index]]
			}
			return encontrado;
		})
	}



	async function carregarEstoque(filtro: FiltroPlantaIndustrial) {
		const res = await _plantaIndustrial.getFilter(filtro, "_estoque")
		res.forEach(element => {
			var item = filtroAuxAtributo(element, "num_saldo_estoque_inicial")
			if (item) {
				item.num_saldo_estoque_inicial = element.num_saldo_estoque_inicial
			}
		})
		lista.filter(x => x.num_saldo_estoque_inicial === undefined && x.num_nivel == filtro.num_nivel).map(x => {
			x.num_saldo_estoque_inicial = 0
			return x
		})
		atualizarInterface()
	}

	async function carregarPlanoProducao(filtro: FiltroPlantaIndustrial) {
		const res = await _plantaIndustrial.getFilter(filtro, "_plano_producao")
		res.forEach(element => {
			var item = filtroAuxAtributo(element, "num_plano_producao_periodo_atual")
			if (item) {
				item.num_plano_producao_periodo_atual = element.num_plano_producao_periodo_atual
				item.num_plano_producao_proximo_periodo = element.num_plano_producao_proximo_periodo
			}
		})
		lista.filter(x => x.num_plano_producao_periodo_atual === undefined && x.num_nivel == filtro.num_nivel).map(x => {
			x.num_plano_producao_periodo_atual = 0
			x.num_plano_producao_proximo_periodo = 0
			return x
		})
		atualizarInterface()
	}

	// para carregar o Modelo e o sugerido mkt
	async function carregarModeloSugeridoMkt(filtro: FiltroPlantaIndustrial) {
		const res = await _plantaIndustrial.getFilter(filtro, "_modelo_sugerido_mkt")
		res.forEach(element => {
			var item = filtroAuxAtributo(element, "num_volume_mkt_novo")
			if (item) {
				item.num_preco_ia = element.num_preco_ia
				item.num_volume_ia = element.num_volume_ia
				item.num_volume_mkt_novo = element.num_volume_mkt_novo
				item.num_preco_mkt_novo = element.num_preco_mkt_novo
				item.num_volume_mkt_original = element.num_volume_mkt_novo
				item.num_preco_mkt_original = element.num_preco_mkt_novo
				item.num_volume = element.num_volume_mkt_novo
				item.num_preco = element.num_preco_mkt_novo
				item.num_fator_decisao_elasticidade = element.num_fator_decisao_elasticidade
				item.num_fator_decisao_giro = element.num_fator_decisao_giro
				item.num_fator_decisao_sazonalidade = element.num_fator_decisao_sazonalidade
				item.num_fator_decisao_tendencia = element.num_fator_decisao_tendencia
				item.flg_elasticidade = element.flg_elasticidade
			}
		})

		lista.filter(x => x.num_volume_mkt_novo === undefined && x.num_nivel == filtro.num_nivel).map(x => {
			x.num_preco_ia = 0
			x.num_volume_ia = 0
			x.num_volume_mkt_novo = 0
			x.num_preco_mkt_novo = 0
			x.num_volume_mkt_original = 0
			x.num_preco_mkt_original = 0
			x.num_volume = 0
			x.num_preco = 0
			x.num_fator_decisao_elasticidade = 0
			x.num_fator_decisao_giro = 0
			x.num_fator_decisao_sazonalidade = 0
			x.num_fator_decisao_tendencia = 0
			x.flg_elasticidade = true
			return x
		})

		atualizarInterface()
	}

	function limparLista() {
		atualizaLista([])
	}

	function atualizarInterface() {
		setLista([...lista])
	}

	function atualizaLista(nova: ModeloPlantaIndustrial[]) {
		setLista(nova)
		lista = nova;
	}

	function atualizarCenario(cenarioAux: ModeloCenario) {
		setCenario({ ...cenarioAux })
	}

	function atualizarFiltroContexto(filtro: FiltroPlantaIndustrial) {
		setFiltroContexto({ ...filtro })
	}

	return (
		// eslint-disable-next-line
		<PlantaIndustrialContexto.Provider
			value={{
				lista,
				plantas,
				carregando,
				carregandoFilho,
				cenario,
				filtroNoContexto,
				carregarLista,
				adicionarLista,
				removerLista,
				salvarEditados,
				limparLista,
				atualizarInterface,
				atualizarUmaLinha,
				atualizarPlantas,
				carregarPlantas,
				atualizarCenario,
				salvarLinhaEditada,
				atualizarFiltroContexto,
				setCarregando
			}}
		>
			{children}
		</PlantaIndustrialContexto.Provider>
	);
};

export function plantaIndustrialContext() {
	const contexto = useContext(PlantaIndustrialContexto);

	return contexto;
}
