import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloCentroDistribuicaoEdicao } from "../models/centroDistribuicaoEdicao";
import { Repositorio } from "./repositorio";

export class RepositorioCentroDistribuicaoEdicao extends Repositorio<ModeloCentroDistribuicaoEdicao> {
  constructor() {
    super(ConstanteGlobal.CENTRO_DISTRIBUICAO_EDICAO);
  }

  delete(cod_usuario: number) {
    return this.api.delete(`${this.nomeRepositorio}_delete?cod_usuario=${cod_usuario}`)
  }
}
