import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import TableRow from "../../components/TableRow";
import FatorDecisao from "../../components/FatorDecisao";
import Skeleton from "react-loading-skeleton";
import { ModeloCentroDistribuicaoFatorDefinicao } from "../../core/models/centroDistribuicao";
import FatorDecisaoElasticidade from "../../components/FatorDecisaoElasticidade";
import { useTranslation } from "react-i18next";

interface Props {
  carregar: boolean;
  listaFatorDecisaoIA: ModeloCentroDistribuicaoFatorDefinicao[];
}

export default function CentroDistribuicaoPainelFatores(props: Props) {
  const { t } = useTranslation();
  const [tamanho, setTamanho] = useState(2);

  useEffect(() => {
    
    setTamanho(props.listaFatorDecisaoIA.length || 1)

  }, [props.listaFatorDecisaoIA])

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-1">
        <h5 className="mb-0">Fatores de Decisão IA</h5>
      </div>
      <Table className="rounded mb-0" hover bordered striped responsive style={{ overflow: 'hidden' }}>
        <thead>
          <tr>
            {/* <TableRow >Empresa</TableRow> */}
            <TableRow >{t("pages.Sazonal")}</TableRow>
            <TableRow >{t("pages.Tendência")}</TableRow>
            <TableRow >{t("pages.Elasticidade")}</TableRow>
            <TableRow >{t("pages.Giro")}</TableRow>
          </tr>
        </thead>

        <tbody>
          {props.carregar ?
            Array.from({ length: tamanho }).map(
              (_, index) => (
                <tr className="height-26" key={"skeleton-tr" + index}>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <td key={"skeleton-td" + index}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))
            :
            <>
              {props.listaFatorDecisaoIA.map((_, index) => (
                <tr key={"painelDefinicao" + index}>
                  {/* <td>{_.des_empresa}</td> */}
                  <td className="text-center" ><FatorDecisao minValue={-10} maxValue={10} valor={_.num_fator_decisao_sazonalidade} /></td>
                  <td className="text-center"><FatorDecisao minValue={-10} maxValue={10} valor={_.num_fator_decisao_tendencia} /></td>
                  <td className="text-center" ><FatorDecisao minValue={0} maxValue={500} valor={_.num_fator_decisao_elasticidade} /></td>
                  <td className="text-center"><FatorDecisao minValue={-5} maxValue={5} valor={_.num_fator_decisao_giro} /></td>
                </tr>
              ))}
            </>
          }
        </tbody>
        {!props.carregar &&
          <tfoot className="fw-bold">
            <tr className="height-26">
              <td colSpan={4}> &nbsp;
              </td>
            </tr>
          </tfoot>
        }
      </Table>
    </div>
  );
}
