import * as React from 'react';
import { Navbar, Container, Nav, Dropdown, Modal, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import avatar from '../../assets/images/avatar.jpg';
import './index.scss';
import Image from 'react-bootstrap/Image'
import { useAutenticação } from '../../core/contexts/auth';
import { FaUsers, FaSignOutAlt, FaEdit, FaLanguage } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { RepositorioUsuario } from '../../core/repositories/usuarioRepositorio';
import moment from "moment";
import 'moment/locale/es'
import 'moment/locale/pt'


const BarraNavegacao: React.FC = () => {
	const { t } = useTranslation();
	const { usuario, signOut } = useAutenticação()
	const [modalShow, setModalShow] = React.useState(false);
	const { i18n } = useTranslation();


	React.useEffect(() => {
		if (usuario)
			atualizaIdiona(usuario.cod_idioma)
	}, [])


	function atualizaIdiona(cod_idioma: number) {
		switch (cod_idioma) {
			case 1:
				i18n.changeLanguage('pt');
				localStorage.setItem('language', 'pt');
				moment.locale("pt-BR")
				break;
			case 2:
				i18n.changeLanguage('en');
				moment.locale("en")
				localStorage.setItem('language', 'en');
		}
	}

	async function trocarIdioma(cod_idioma: number) {
		atualizaIdiona(cod_idioma)
		if (usuario) {
			usuario.cod_idioma = cod_idioma;
			new RepositorioUsuario().salvar(usuario)
			window.location.reload();
		}
	}


	return (
		<Navbar fixed="top" className="barra-navegacao py-1 " bg="white">
			<Container fluid className="my-0 border-bottom px-2">

				<Navbar.Brand href="inicio" className='py-0'>
					<Image className="d-inline-block align-top " height="55" alt="Logo" src={logo} />
				</Navbar.Brand>
				<div className="pb-1 text-end">

					<Nav variant="pills" className="justify-content-end" >


						<Dropdown className="justify-content-end align-items-center">
							<Dropdown.Toggle as={Nav.Link} variant="clear">
								<label className='me-1 fw-normal' >
									{t("components.Olá,")} {usuario?.des_nome?.split(" ")[0]}
								</label>
								<Image src={avatar} roundedCircle className="ml-2 width-30 height-30" />
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{(usuario?.des_email == "rsilva@analytics2go.com" || usuario?.des_email == "lbueno@analytics2go.com") &&

									<Dropdown.Item className={"text-primary bold " + (localStorage.getItem("editando") ? "active" : "")} onClick={
										() => {
											localStorage.setItem("editando", localStorage.getItem("editando") ? "" : "Sim");
											window.location.reload();
										}}  >
										<FaEdit className="me-2" />
										{t("components.Editar Tabela")}
									</Dropdown.Item>
								}
								<Dropdown.Item className="text-primary bold" onClick={() => { setModalShow(!modalShow) }}>
									<FaLanguage className="me-2" />
									{t("components.Idioma")}
								</Dropdown.Item>

								<Dropdown.Item className="text-primary bold" as={Link} to="/gerenciamento-usuarios">
									<FaUsers className="me-2" />
									{t("components.Usuários")}
								</Dropdown.Item>

								<Dropdown.Item className="text-primary bold" onClick={() => { signOut(true) }}>
									<FaSignOutAlt className="me-2 " />
									{t("components.Sair")}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>

					<div>

						<Nav variant="pills" defaultActiveKey="/catalago-preco-base" className="justify-content-end" >



							<NavLink className="nav-link btn-sm " to="/catalago-preco-base" >
								Catalógo Preço Base
							</NavLink>

							 <NavLink className="nav-link btn-sm ms-2" to="/preco-base" >
								Preço Base
							</NavLink>

							<NavLink className="nav-link btn-sm ms-2" to="/vendas-diretas" >
								{t("components.Vendas Diretas")}
							</NavLink>

							<NavLink className="nav-link btn-sm ms-2" to="/centro-distribuicao" >
								{t("components.Centro de Distribuição")}
							</NavLink>

							<NavLink className="nav-link btn-sm ms-2" to="/sazonalidade" >
								{t("components.Sazonalidade")}
							</NavLink>
							
							<NavLink className="nav-link btn-sm ms-2" to="/inteligencia-mercado" >
								{t("components.Inteligência de Mercado")}
							</NavLink> 
						</Nav>
					</div>
				</div>


			</Container>

			<Modal onHide={() => setModalShow(false)} centered size="lg" aria-labelledby="contained-modal-title-vcenter" show={modalShow}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					{usuario &&
						<ToggleButtonGroup className='w-100' type="radio" name="language" value={usuario?.cod_idioma} >
							<ToggleButton id="ingles1" onClick={(e: any) => { trocarIdioma(2) }} className="w-50" variant="outline-primary" value={2}>
								{t("components.Inglês")}
							</ToggleButton>
							<ToggleButton id="ingles2" onClick={(e: any) => { trocarIdioma(1) }} className="w-50 text-capitalize" variant="outline-primary" value={1}>
								{t("components.Português")}
							</ToggleButton>
						</ToggleButtonGroup>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={() => { setModalShow(false) }} size='sm' variant="outline-dark" >
						{t("pages.Salvar")}
					</Button>

				</Modal.Footer>
			</Modal>
		</Navbar >
	);
};

export default BarraNavegacao;
