import { ModeloBasico } from "./basico";


export class ModeloPrecoBaseEdicao extends ModeloBasico {
 

  des_agencia: string = "";
  des_departamento: string = "";
  des_categoria: string = "";
  des_subcategoria: string = "";
  des_mesorregiao: string = "";
  des_produto: string = "";

  num_ano_semana: string = "";

  num_preco_edicao?: number;
  flg_automatizado: boolean = false;
  flg_aceito: boolean = false;
  cod_usuario?: number;

}
