import { ConstanteGlobal } from "../config/constanteGlobal";
import { OrdemCompraModel } from "../models/ordemCompra";
import { Repositorio } from "./repositorio";


export class FilterOrdemCompra {
  branch_code?: number;
  department_code?: number;
  category_code?: number;
  subcategory_code?: string;
  mesorregion_code?: number;
  product_code?: number;
  year_week: string = "";
}

export class OrdemCompraRepositorio extends Repositorio<OrdemCompraModel> {
  constructor() {
    super(ConstanteGlobal.PURCHASE_ORDER);
  }
}
