import * as React from "react";
import { formatarDecimal } from "../../core/utils/funcoes";
import "./index.scss";
import { Badge, Spinner } from "react-bootstrap";

interface Props {
  valor: number;
  nivel: number;
  className?: string;
}

const CelulaShelf: React.FC<Props> = (props) => {
  return (
    <div
      className={(props.className || "") + " " +
        (props.nivel === 0
          ? "resfriado-0"
          : props.nivel === 1
            ? "resfriado-30"
            : props.nivel === 2
              ? "resfriado-40"
              : "congelado")
      }
    >
      <Badge pill bg="light" text="dark">
        {props.valor == -1 ?
          <Spinner size="sm" /> :
          formatarDecimal(props.valor)
        }

      </Badge>
    </div>
  );
};

export default CelulaShelf;
