import React, { useState } from "react";
import SelecionarBusca from "../../components/SelecionarBusca";
import { Row, Col } from "react-bootstrap";
import { FiltroCabecalho } from "../../components/FiltroCabecalho";
import { ModeloBasico } from "../../core/models/basico";
import { ModeloCanal } from "../../core/models/canal";
import { ModeloDiretoria } from "../../core/models/diretoria";
import { ModeloGerencia } from "../../core/models/gerencia";
import { ModeloLinha } from "../../core/models/linha";
import { ModeloProduto } from "../../core/models/produto";
import { ModeloCenario } from "../../core/models/cenario";
import { ModeloPlantaIndustrialListaTabela } from "../../core/models/plantaIndustrial";
import { RepositorioCanal } from "../../core/repositories/canalRepositorio";
import { FiltroDiretoria, RepositorioDiretoria } from "../../core/repositories/diretoriaRepositorio";
import { FiltroGerencia, RepositorioGerencia } from "../../core/repositories/gerenciaRepositorio";
import { FiltroLinha, RepositorioLinha } from "../../core/repositories/linhaRepositorio";
import { FiltroProduto, RepositorioProduto } from "../../core/repositories/produtoRepositorio";
import { FiltroPlantaIndustrial } from "../../core/repositories/plantaIndustrialRepositorio";
import { filtroContexto } from "../../core/contexts/filtroContexto";
import { plantaIndustrialContext } from "../../core/contexts/plantaIndustrialContexto";
import { verificarSemanaAtual } from "../../core/utils/funcoes";
import { TipoMensagem } from "../../core/config/tipoMensage";
import { useToast } from "../../core/contexts/toast";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
  titulo: string;
  filtro: FiltroPlantaIndustrial;
  cenario: ModeloCenario;
  listaTabela: ModeloPlantaIndustrialListaTabela;
  listaPeriodo: ModeloBasico[];
  atualizarFiltro: (filtro: FiltroPlantaIndustrial) => void;
  filtrarDados: (filtro: FiltroPlantaIndustrial) => void;
  setListaTabela: (listaTabela: ModeloPlantaIndustrialListaTabela) => void;
  mostrarCenarioNome: (mostrar: boolean) => void;
  carregarPeriodo: () => void;
}

export default function FiltroPI(props: Props) {
  const { t } = useTranslation();

  const { mostrarToast } = useToast();

  const { lista } = plantaIndustrialContext();
  const { listaAcabamento, listaBandeira, listaCluster, listaDivisaoComercial, listaFamiliaSubgrupo, listaMercado, listaTemperatura, listaTipoProduto, listaUFDestino, listaPeriodoSemana, listaPeriodoMes, periodoCarregado } = filtroContexto()

  const [listaCanal, setListaCanal] = useState<ModeloCanal[]>([]);
  const [listaDiretoria, setListaDiretoria] = useState<ModeloDiretoria[]>([]);
  const [listaGerencia, setListaGerencia] = useState<ModeloGerencia[]>([]);
  const [listaLinha, setListaLinha] = useState<ModeloLinha[]>([]);
  const [listaProduto, setListaProduto] = useState<ModeloProduto[]>([]);
  const [listaPeriodo, setListaPeriodo] = useState<ModeloBasico[]>([]);

  const [habilitarCheckboxPeriodo, setHabilitarCheckboxPeriodo] = useState(false)
  const [checkboxPeriodo, setCheckboxPeriodo] = useState(false)


  React.useEffect(() => {
    carregarPeriodo()
  }, [periodoCarregado, listaPeriodoMes, listaPeriodoSemana])

  React.useEffect(() => {
    if (props.filtro.des_periodo_analisado) {
      const flg_atual = verificarSemanaAtual(props.filtro.des_periodo_analisado)

      // Forçar não checado
      if (habilitarCheckboxPeriodo == false && flg_atual == true) {
        props.filtro.flg_semanal_mensal = "" // N Aplicar
        setCheckboxPeriodo(false)
      }
      // É semana atual
      else if (flg_atual)
        if (checkboxPeriodo)
          props.filtro.flg_semanal_mensal = "0" // False
        else
          props.filtro.flg_semanal_mensal = "" // N Aplicar
      // Não é Semana Atual (para outras semanas e periodos mensais)
      else
        props.filtro.flg_semanal_mensal = "" // N Aplicar

      props.atualizarFiltro(props.filtro)
      setHabilitarCheckboxPeriodo(flg_atual)
    }
  }, [props.filtro.des_periodo_analisado])

  React.useEffect(() => {
    try {
      new RepositorioCanal().getAll().then((res) => {
        setListaCanal(res);
      });

      carregarDiretoria();
      carregarGerencia();
      carregarLinha();
      carregarProduto();
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }, []);

  function carregarGerencia() {
    props.filtro.cod_gerencia = ""
    const filtro = new FiltroGerencia();
    filtro.cod_canal = props.filtro.cod_canal;
    filtro.cod_diretoria = props.filtro.cod_diretoria;
    new RepositorioGerencia().getFilter(filtro).then((res) => {
      setListaGerencia(res);
    });
  }

  function carregarDiretoria() {
    props.filtro.cod_diretoria = ""
    const filtro = new FiltroDiretoria();
    filtro.cod_canal = props.filtro.cod_canal;
    new RepositorioDiretoria().getFilter(filtro).then((res) => {
      setListaDiretoria(res);
    });
  }

  function carregarProduto() {
    props.filtro.cod_produto = ""

    const filtroProduto = new FiltroProduto();
    filtroProduto.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo_produto_capitao;
    filtroProduto.cod_linha = props.filtro.cod_linha_produto_capitao;
    new RepositorioProduto().getFilter(filtroProduto).then((res) => {
      setListaProduto(res);
    });
  }

  function carregarLinha() {
    props.filtro.cod_linha_produto_capitao = ""

    const filtroLinha = new FiltroLinha();
    filtroLinha.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo_produto_capitao;

    new RepositorioLinha().getFilter(filtroLinha).then((res) => {
      setListaLinha(res);
    });
  }

  function atualizarAnoMes(valor: any) {
    props.filtro.flg_ano_mes = valor;
    props.atualizarFiltro(props.filtro);
    carregarPeriodo();
  }

  function carregarPeriodo() {
    if (periodoCarregado && listaPeriodoMes && listaPeriodoSemana) {
      var aux = props.filtro.flg_ano_mes ? listaPeriodoMes : listaPeriodoSemana
      setListaPeriodo(aux);
      atualizarPeriodoPadrao(aux);
    }
  }



  async function atualizarPeriodoPadrao(lista: ModeloBasico[], filtro = props.filtro) {
    const aux = { ...filtro }
    if (lista.length > 0) {
      const t = new Date().toISOString();
      const atual = moment(t).add(aux.flg_ano_mes ? 0 : 1, "days").format(aux.flg_ano_mes ? "YYYYM" : "YYYYWW");

      aux.des_periodo_analisado = lista.find(x => x.codigo == atual)?.codigo || lista[0].codigo;
      const elemento = (document.getElementById("Período Analisado") as any)
      elemento.value = aux.des_periodo_analisado

    } else {
      aux.des_periodo_analisado = ""
    }

    setTimeout(() => {
      props.atualizarFiltro(aux)
    }, 500);
  }

  return (
    <div className="stick">
      <FiltroCabecalho
        titulo={props.titulo}
        filtro={props.filtro}
        atualizarFiltro={props.atualizarFiltro}
        atualizarAnoMes={atualizarAnoMes}
        cenario={props.cenario}
        lista={lista}
        platasIndustrial={true}
        mostrarCenarioNome={props.mostrarCenarioNome}
      >
        <>
          <Row className="row-cols-2 row-cols-lg-7">
            <Col>
              {habilitarCheckboxPeriodo ?
                <SelecionarBusca
                  id="Período Analisado"
                  titulo={t("pages.Período Analisado")}
                  optionValue="codigo"
                  optionLabel="descricao"
                  multiple={false}
                  className="flex-fill"
                  value={props.filtro.des_periodo_analisado}
                  options={listaPeriodo}
                  onChange={(value) => {
                    props.filtro.des_periodo_analisado = value;
                    props.atualizarFiltro(props.filtro)
                  }}
                  checkbox={checkboxPeriodo}
                  alterarCheckbox={(valor: boolean) => {
                    if (valor)
                      props.filtro.flg_semanal_mensal = "0" // False
                    else
                      props.filtro.flg_semanal_mensal = "" // N Aplicar
                    props.atualizarFiltro(props.filtro)
                    setCheckboxPeriodo(valor)
                  }}
                /> :
                <SelecionarBusca
                  id="Período Analisado"
                  titulo={t("pages.Período Analisado")}
                  optionValue="codigo"
                  optionLabel="descricao"
                  multiple={false}
                  className="flex-fill"
                  value={props.filtro.des_periodo_analisado}
                  options={listaPeriodo}
                  onChange={(value) => {
                    props.filtro.des_periodo_analisado = value;
                    props.atualizarFiltro(props.filtro)
                  }}
                />
              }
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Canal de Venda")}
                optionLabel="des_canal"
                optionValue="cod_canal"
                className="flex-fill"
                multiple
                value={props.filtro.cod_canal}
                options={listaCanal}
                onChange={(value) => {
                  props.filtro.cod_canal = value;
                  carregarGerencia();
                  carregarDiretoria();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Diretoria")}
                optionLabel="des_diretoria"
                optionValue="cod_diretoria"
                className="flex-fill"
                multiple
                value={props.filtro.cod_diretoria}
                options={listaDiretoria}
                onChange={(value) => {
                  props.filtro.cod_diretoria = value
                  carregarGerencia();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Regional")}
                optionLabel="des_gerencia"
                optionValue="cod_gerencia"
                className="flex-fill"
                multiple
                value={props.filtro.cod_gerencia}
                options={listaGerencia}
                onChange={(value) => {
                  props.filtro.cod_gerencia = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Divisão Comercial")}
                optionLabel="des_divisao_comercial"
                optionValue="cod_divisao_comercial"
                className="flex-fill"
                multiple
                value={props.filtro.cod_divisao_comercial}
                options={listaDivisaoComercial}
                onChange={(value) => {
                  props.filtro.cod_divisao_comercial = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Mercado")}
                optionLabel="des_mercado"
                optionValue="cod_mercado"
                className="flex-fill"
                multiple
                value={props.filtro.cod_mercado}
                options={listaMercado}
                onChange={(value) => {
                  props.filtro.cod_mercado = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Tipo de Produto")}
                optionLabel="des_tipo_produto"
                optionValue="cod_tipo_produto"
                className="flex-fill"
                multiple
                value={props.filtro.cod_tipo_produto}
                options={listaTipoProduto}
                onChange={(value) => {
                  props.filtro.cod_tipo_produto = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Família Subgrupo")}
                optionLabel="des_familia_subgrupo"
                optionValue="cod_familia_subgrupo"
                multiple
                value={props.filtro.cod_familia_subgrupo_produto_capitao}
                options={listaFamiliaSubgrupo}
                onChange={(value) => {
                  props.filtro.cod_familia_subgrupo_produto_capitao = value
                  carregarProduto();
                  carregarLinha();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Linha")}
                optionLabel="des_linha"
                optionValue="cod_linha"
                multiple
                value={props.filtro.cod_linha_produto_capitao}
                options={listaLinha}
                onChange={(value) => {
                  props.filtro.cod_linha_produto_capitao = value
                  carregarProduto();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Produto")}
                optionLabel="des_produto"
                optionValue="cod_produto"
                panelClassName="min-width-600"
                multiple
                logicaFiltroCodigo
                value={props.filtro.cod_produto}
                options={listaProduto}
                onChange={(value) => {
                  props.filtro.cod_produto = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            {/* <Col>
              <SelecionarBusca
                titulo={t("pages.Acabamento")}
                optionLabel="des_acabamento"
                optionValue="cod_acabamento"
                multiple
                value={props.filtro.cod_acabamento}
                options={listaAcabamento}
                onChange={(value) => {
                  props.filtro.cod_acabamento = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Temperatura")}
                optionLabel="des_temperatura"
                optionValue="cod_temperatura"
                multiple
                options={listaTemperatura}
                value={props.filtro.cod_temperatura}
                onChange={(value) => {
                  props.filtro.cod_temperatura = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col> */}

            <Col>
              <SelecionarBusca
                titulo={t("pages.Cluster")}
                optionLabel="des_cluster_label"
                optionValue="cod_cluster"
                multiple
                value={props.filtro.cod_cluster}
                options={listaCluster}
                onChange={(value) => {
                  props.filtro.cod_cluster = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.Bandeira")}
                optionLabel="des_bandeira"
                optionValue="cod_bandeira"
                multiple
                value={props.filtro.cod_bandeira}
                options={listaBandeira}
                onChange={(value) => {
                  props.filtro.cod_bandeira = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

            <Col>
              <SelecionarBusca
                titulo={t("pages.UF Destino")}
                optionLabel="ini_uf_destino"
                optionValue="ini_uf_destino"
                multiple
                value={props.filtro.ini_uf_destino}
                options={listaUFDestino}
                onChange={(value) => {
                  props.filtro.ini_uf_destino = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>
          </Row>
        </>
      </FiltroCabecalho>
    </div>
  );
}


