import React, { createContext, useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";

interface ModalInterface {
  titulo: string;
  subTitulo: string;
  acao?: string;
}

// O que o contexto irá passar para o outros componentes
interface ModalContextoInterface {
  modal: ModalInterface | null;
  mostrarModal(event: ModalInterface): Promise<boolean>;
  onHide: () => void;
}

// Como o objeto de contexto vai iniciar
const ModalContexto = createContext<ModalContextoInterface>({} as ModalContextoInterface);

type Props = {
  children?: JSX.Element,
};
// Componente de contexto que irá por volta de todos os outros componentes
export const ModalProvider: React.FC<Props> = ({ children }) => {
  const [modal, setModal] = useState<ModalInterface | null>(null);
  const [show, setShow] = useState(false);
  const [promiseResolve, setPromiseResolve] = useState<((value: boolean) => void) | null>(null);

  async function onHide() {
    if (promiseResolve) {
      promiseResolve(false);
      setPromiseResolve(null);
    }
    setModal(null);
    setShow(false)
  }

  async function mostrarModal(event: ModalInterface): Promise<boolean> {
    setModal(event);
    setShow(true)
    return new Promise<boolean>((resolve, reject) => {
      setPromiseResolve(() => resolve);
    });
  }

  async function executarAcao() {
    if (promiseResolve) {
      promiseResolve(true);
      setPromiseResolve(null);
    }
    setModal(null);
    setShow(false)
  }

  return (
    <ModalContexto.Provider value={{ modal, mostrarModal, onHide }}>
      <>
        {children}
        <Modal onHide={onHide} show={show} centered >
          <Modal.Header closeButton className="pb-0">
            <Modal.Title className='font-size-18 text-primary'>{modal?.titulo}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <p>{modal?.subTitulo}</p>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button variant={modal?.acao ? "secondary" : "primary"} onClick={onHide}>
              Fechar
            </Button>
            {modal?.acao && (
              <Button variant="primary" onClick={() => {
                executarAcao()
              }}>
                {modal?.acao}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
    </ModalContexto.Provider>
  );
};

export function useModal() {
  const contexto = useContext(ModalContexto);

  return contexto;
}
