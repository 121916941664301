import React, { useEffect, useRef, useState } from "react";
import { formatarDecimal, formatarPorcentagem, calcularVariacao } from "../../core/utils/funcoes";
import { NumericFormat } from 'react-number-format';
import { useToast } from "../../core/contexts/toast";
import Image from "react-bootstrap/Image";
import spinner from "../../assets/icons/spinner-black-sm.svg";
import { FaEdit, FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import FatorDecisao from "../FatorDecisao";
import { TipoMensagem } from "../../core/config/tipoMensage";
import { centroDistribuicaoContext } from "../../core/contexts/centroDistribuicaoContexto";
import { ModeloCentroDistribuicao, ModeloCentroDistribuicaoGranularidade, ModeloCentroDistribuicaoListaTabela } from "../../core/models/centroDistribuicao";
import { FiltroCentroDistribuicao, RepositorioCentroDistribuicao } from "../../core/repositories/centroDistrubuicaoRepositorio";
import Shelf from "../Shelf";
import { useTranslation } from "react-i18next";


interface Props {
  linha: ModeloCentroDistribuicao;
  index: number;
  filtro: FiltroCentroDistribuicao;
  salvouEdicao(): void;
}

export const TabelaTr: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  const { carregandoFilho, adicionarLista, removerLista, atualizarInterface, atualizarUmaLinha, salvarLinhaEditada } = centroDistribuicaoContext();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { mostrarToast } = useToast();
  const [carregar, setCarregar] = useState(false);
  const [flgElasticidade, setFlgElasticidade] = useState<boolean>();

  useEffect(() => {

  })
  useEffect(() => {
    if (props.linha.flg_elasticidade != flgElasticidade) {
      setFlgElasticidade(props.linha.flg_elasticidade)
    }
  }, [props.linha.flg_elasticidade])
  async function carregarDados() {
    try {
      setCarregar(true);

      if (props.linha.flg_aberto) {
        removerLista(props.index);
      } else {
        await adicionarLista(props.filtro, props.index)
      }

      atualizarInterface()
      setCarregar(false);
    } catch (error) {
      mostrarToast({
        titulo: "Erro",
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });

      setCarregar(false);
    }
  }
  //fazer o calculo da elasticidade aqui
  async function calculoElasticidadeVolume() {
    try {
      const produto1 = { ...props.linha }
      const produto2 = { ...props.linha }
      produto1.editado_usuario = "preco"
      produto2.editado_usuario = "volume"

      if (props.linha.flg_elasticidade && props.linha.num_volume_mkt_novo) {
        props.linha.flg_carregando_preco = true;
        props.linha.num_preco_mkt_novo = undefined;
      }
      atualizarUmaLinha(props.linha, props.index)

      if (props.linha.flg_elasticidade == false && props.linha.num_nivel == 3) {
        produto2.num_preco_mkt_novo = produto2.num_preco
        await salvarLinhaEditada(produto1, -1, props.index, false)
        await salvarLinhaEditada(produto2, -1, props.index, true)
      } else {
        await salvarLinhaEditada(props.linha, -1, props.index, true)
      }

    } catch (error) {
      props.linha.flg_carregando_preco = false;
      props.linha.flg_carregando_volume = false;
      atualizarUmaLinha(props.linha, props.index)

      mostrarToast({
        titulo: "Erro",
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
    props.salvouEdicao()
    atualizarInterface();
  }

  function onMudarVolume(event: { floatValue: number | undefined; }) {
    props.linha.num_volume_mkt_novo = event.floatValue;
    props.linha.editado_usuario = "volume";
    atualizarUmaLinha(props.linha, props.index)
    atualizarInterface();
    // Cancelar o timer anterior, se houver um
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      calculoElasticidadeVolume();
    }, 2000);
  }

  //fazer o calculo da elasticidade aqui
  async function calculoElasticidadePreco() {
    try {

      const produto1 = { ...props.linha }
      const produto2 = { ...props.linha }
      produto1.editado_usuario = "preco"
      produto2.editado_usuario = "volume"

      if (props.linha.flg_elasticidade && (props.linha.num_preco_mkt_novo != props.linha.num_preco_mkt_original)) {
        props.linha.flg_carregando_volume = true;
        props.linha.num_volume_mkt_novo = undefined;
      } else {
        // props.linha.num_volume_mkt_novo = undefined;
      }
      atualizarUmaLinha(props.linha, props.index)

      if (props.linha.flg_elasticidade == false && props.linha.num_nivel == 3) {
        await salvarLinhaEditada(produto2, -1, props.index, false)
        await salvarLinhaEditada(produto1, -1, props.index, true)
      } else {
        await salvarLinhaEditada(props.linha, -1, props.index, true)
      }

    } catch (error) {
      props.linha.flg_carregando_volume = false;
      props.linha.flg_carregando_preco = false;
      atualizarUmaLinha(props.linha, props.index)

      mostrarToast({
        titulo: "Erro",
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });

    }
    props.salvouEdicao()
    atualizarInterface();

  }
  function onMudarPreco(event: { floatValue: number | undefined; }) {
    props.linha.num_preco_mkt_novo = event.floatValue;
    props.linha.editado_usuario = "preco";
    atualizarUmaLinha(props.linha, props.index)
    atualizarInterface();
    // Cancelar o timer anterior, se houver um
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      calculoElasticidadePreco();
    }, 2000);
  }

  function copiar() {
    var label = ""
    for (let i = 0; i <= props.linha.num_nivel; i++) {
      label += `${ModeloCentroDistribuicaoGranularidade.INTERFACE[i]?.toString()}:(${(props.linha as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[i]]?.toString()}) - ${(props.linha as any)[ModeloCentroDistribuicaoGranularidade.DESCRICAO[i]]?.toString()}\n`;
    }
    const inputTemporario = document.createElement("textarea");
    inputTemporario.value = label;
    document.body.appendChild(inputTemporario);
    inputTemporario.select();
    document.execCommand("copy");
    document.body.removeChild(inputTemporario);
  }

  function carregandoValor(valor: number | undefined, money: boolean = false, atributo = "") {
    if (valor === undefined) {
      return <td className="text-center"> <Spinner size="sm" /> </td>
    }
    return <td>{formatarDecimal(valor, money)}

      {(props.linha.num_nivel == 5 && localStorage.getItem("editando"))
        && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>}
    </td>
  }

  function num_preco_sem_mkp() {
    return (props.linha.num_cpv_estoque_inicial * props.linha.num_volume_estoque_inicial + props.linha.num_preco_reposicao * props.linha.num_volume_reposicao) /
      (props.linha.num_volume_estoque_inicial + props.linha.num_volume_reposicao)
  }

  function desabilitarCarregamento() {
    const carregaPos = ["num_volume_ia", "num_preco_ia", "num_volume_mkt_novo", "num_preco_mkt_novo"]

    return carregandoFilho || carregaPos.map(x => (props.linha as any)[x] === undefined).some(x => x == true)
  }

  async function atualizarValor(atributo: string) {
    const valor = prompt("Por favor, insira um valor:");
    if (valor) {
      const _centro = new RepositorioCentroDistribuicao();
      _centro.atualizarValor(atributo, valor, props.linha).then((atualizadas) => {
        if (atualizadas) {
          (props.linha as any)[atributo] = valor
          atualizarUmaLinha(props.linha, props.index)
        }
        mostrarToast({
          titulo: "Sucesso",
          mensagem: `${atualizadas} linha(s) atualizada(s)`,
          tipo: "success",
        });
      }).catch(err => {
        mostrarToast({
          titulo: "Erro",
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });

    }

  }
  function add_edit(atributo: string) {
    return (props.linha.num_nivel == 5 && localStorage.getItem("editando"))
      && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>
  }
  return (
    <>
      {/* Granularidade */}
      <td width={200} className={props.linha.edit ? "is-edit " : " bg-white  text-start"}>
        <div className="drilldown ">
          <div
            className="text-end"
            style={{ minWidth: `${20 + ((props.linha.num_nivel == ModeloCentroDistribuicaoGranularidade.DESCRICAO.length - 1 && props.linha.quantidade <= 1) ? (props.linha.num_nivel - 1) : (props.linha.num_nivel)) * 15}px` }}
          >
            {(props.linha.num_nivel < ModeloCentroDistribuicaoGranularidade.DESCRICAO.length - 2 || (props.linha.num_nivel == ModeloCentroDistribuicaoGranularidade.DESCRICAO.length - 2 && props.linha.quantidade > 0)
              && (props.linha.num_nivel == 4 && props.linha.num_volume_venda > 0)
            ) && (
                <Button variant="light" size="sm" onClick={carregarDados} disabled={desabilitarCarregamento()}>
                  {carregar ?
                    <Image src={spinner} className="baixar-icon" /> :
                    props.linha.flg_aberto ? <FaMinusCircle /> : <FaPlusCircle />
                  }
                </Button>
              )}
          </div>
          <span className="ms-1 w-100" onDoubleClick={copiar}>
            {(props.linha as any)[ModeloCentroDistribuicaoGranularidade.DESCRICAO[props.linha.num_nivel]]?.toString()}
          </span>
        </div>
      </td>

      {/* Volume Estoque */}
      {carregandoValor(props.linha.num_volume_estoque_inicial, false, "num_volume_estoque_inicial")}
      {/* CPV Estoque Inicial */}
      {carregandoValor(props.linha.num_cpv_estoque_inicial, true, "num_cpv_estoque_inicial")}
      {/* Shelf */}
      <Shelf linha={props.linha} index={props.index} />
      {/* Volume de Reposição */}
      {carregandoValor(props.linha.num_volume_reposicao, false, "num_volume_reposicao")}
      {/* Preço da Reposição */}
      {props.linha.num_preco_reposicao === undefined ?
        <td className="text-center"> <Spinner size="sm" /> </td> :
        <td>
          <OverlayTrigger
            overlay={
              <Tooltip id="button-tooltip" hidden={!props.linha.num_preco_reposicao}>
                <div className="text-start">
                  {props.linha.num_transferencia_preco_mkt_novo ? "Preço Transferência" : "Preço IA"}
                </div>
              </Tooltip>
            }
          >
            <div>
              {formatarDecimal(props.linha.num_preco_reposicao, true)} {add_edit("num_preco_reposicao")}
            </div>
          </OverlayTrigger>
        </td>
      }
      {/* Volume Projetado */}
      {props.linha.num_volume_estoque_inicial === undefined || props.linha.num_volume_reposicao === undefined ?
        <td className="text-center"> <Spinner size="sm" /> </td> :
        <td>{formatarDecimal(props.linha.num_volume_estoque_inicial + props.linha.num_volume_reposicao)}</td>
      }
      {/* Custo do Produto Projetado */}
      {props.linha.num_gvv === undefined || props.linha.num_cpv_estoque_inicial === undefined || props.linha.num_volume_estoque_inicial === undefined ?
        <td className="text-center"> <Spinner size="sm" /> </td> :
        <td>{formatarDecimal(num_preco_sem_mkp(), true)}</td>
      }
      {/* GVV */}
      {carregandoValor(props.linha.num_gvv, true, "num_gvv")}
      {/* Preço Sem Markup */}

      {props.linha.num_gvv === undefined || props.linha.num_cpv_estoque_inicial === undefined || props.linha.num_volume_estoque_inicial === undefined ?
        <td className="text-center"> <Spinner size="sm" /> </td> :
        <td>{formatarDecimal(num_preco_sem_mkp() + props.linha.num_gvv, true)}</td>
      }
      {/* Volume Vendido */}
      {carregandoValor(props.linha.num_volume_venda, false, "num_volume_venda")}

      {/* Preço Praticado */}
      {carregandoValor(props.linha.num_preco_venda, true, "num_preco_venda")}
      {/* Demanda Sugestão IA */}
      {carregandoValor(props.linha.num_volume_ia, false, "num_volume_ia")}
      {/* Preço Sugerido IA */}
      {carregandoValor(props.linha.num_preco_ia, true, "num_preco_ia")}
      {/* Margem Projetada */}
      {props.linha.num_gvv === undefined || props.linha.num_cpv_estoque_inicial === undefined || props.linha.num_volume_estoque_inicial === undefined ?
        <td className="text-center"> <Spinner size="sm" /> </td>
        :
        <td>{(num_preco_sem_mkp() + props.linha.num_gvv) && props.linha.num_preco_ia ? formatarPorcentagem(props.linha.num_preco_ia / (num_preco_sem_mkp() + props.linha.num_gvv) - 1) : "-"}</td>
      }

      {/* Fatores de Decisão */}
      <td className="width-55"><FatorDecisao minValue={-10} maxValue={10} valor={props.linha.num_fator_decisao_sazonalidade} />{add_edit("num_fator_decisao_sazonalidade")}</td>
      <td className="width-55"><FatorDecisao minValue={-10} maxValue={10} valor={props.linha.num_fator_decisao_tendencia} />   {add_edit("num_fator_decisao_tendencia")}</td>
      <td className="width-55"><FatorDecisao minValue={0} maxValue={500} valor={props.linha.num_fator_decisao_elasticidade} />   {add_edit("num_fator_decisao_elasticidade")}</td>
      <td className="width-55"><FatorDecisao minValue={-5} maxValue={5} valor={props.linha.num_fator_decisao_giro} />   {add_edit("num_fator_decisao_giro")}</td>

      {/* Ativar Elasticidade */}
      <td className="text-center">
        {props.linha.num_nivel == ModeloCentroDistribuicaoGranularidade.DESCRICAO.length - 3 && (
          flgElasticidade === undefined ?
            <Spinner size="sm" /> :
            <Form.Check
              checked={!!flgElasticidade}
              onChange={(event) => {
                setFlgElasticidade(event.currentTarget.checked)
                props.linha.flg_elasticidade = event.currentTarget.checked;
                atualizarUmaLinha(props.linha, props.index)
              }}
            />
        )}
      </td>
      {/* Volume Sugerido Mkt */}
      {props.linha.flg_carregando_volume || props.linha.num_volume_mkt_novo === undefined ?
        <td className="text-center"> <Spinner size="sm" /> </td> :
        <td>
          {props.linha.num_nivel > 2 ? (
            <div className="d-flex justify-content-between align-items-center">
              <div className="position-relative">
                <NumericFormat
                  type="tel"
                  className="form-control-sm"
                  tabIndex={props.index * 2 + 1}
                  disabled={props.linha.flg_carregando_preco}
                  decimalSeparator={t("components.,")}
                  thousandSeparator={t("components..")}
                  decimalScale={2}
                  placeholder={formatarDecimal(props.linha.num_volume_ia)}
                  id={"numberFormat-1" + props.linha.num_nivel}
                  value={props.linha.num_volume_ia != props.linha.num_volume_mkt_novo ? props.linha.num_volume_mkt_novo : undefined}
                  onValueChange={onMudarVolume}
                />
                {props.linha.num_volume_mkt_novo != props.linha.num_volume_mkt_original &&
                  <OverlayTrigger
                    overlay={(props) => (
                      <Tooltip  {...props}>
                        {t("pages.O conteúdo foi editado, mas ainda não foi salvo!")}
                      </Tooltip>
                    )}
                  >
                    <span className="position-absolute width-12 height-12 bg-warning  rounded-circle top-0 right-0" />
                  </OverlayTrigger>
                }
              </div>
            </div>
          ) : (
            formatarDecimal(props.linha.num_volume_mkt_novo || props.linha.num_volume_ia)
          )}
        </td>
      }
      {/* Var.IA Vs Mkt */}
      <td>{formatarPorcentagem(calcularVariacao(props.linha.num_volume_ia, props.linha.num_volume_mkt_novo))}</td>
      {/* Preço Sugerido Mkt */}
      {props.linha.flg_carregando_preco || props.linha.num_preco_mkt_novo === undefined ?
        <td className="text-center"> <Spinner size="sm" /> </td> :
        <td>
          {props.linha.num_nivel > 2 ? (
            <div className="d-flex justify-content-between align-items-center">
              <div className="position-relative">
                <NumericFormat
                  type="tel"
                  className="form-control-sm"
                  tabIndex={props.index * 2 + 2}
                  disabled={props.linha.flg_carregando_volume}
                  decimalSeparator={t("components.,")}
                  thousandSeparator={t("components..")}
                  decimalScale={2}
                  prefix={t("components.R$ ")}
                  placeholder={formatarDecimal(props.linha.num_preco_ia, true)}
                  id={"numberFormat" + props.linha.num_nivel}
                  value={props.linha.num_preco_ia != props.linha.num_preco_mkt_novo ? props.linha.num_preco_mkt_novo : undefined}
                  onValueChange={onMudarPreco}
                />
                {props.linha.num_preco_mkt_novo != props.linha.num_preco_mkt_original &&
                  <OverlayTrigger
                    overlay={(props) => (
                      <Tooltip  {...props}>
                        {t("pages.O conteúdo foi editado, mas ainda não foi salvo!")}
                      </Tooltip>
                    )}
                  >
                    <span className="position-absolute width-12 height-12 bg-warning  rounded-circle top-0 right-0" />
                  </OverlayTrigger>
                }
              </div>
            </div>
          ) : (
            formatarDecimal(props.linha.num_preco_mkt_novo || props.linha.num_preco_ia, true)
          )}
        </td>
      }
      {/* Var.IA Vs Mkt */}
      <td>{formatarPorcentagem(calcularVariacao(props.linha.num_preco_ia, props.linha.num_preco_mkt_novo))}</td>
      {/* Preço Com Imposto */}
      {carregandoValor(props.linha.num_preco_imposto, true, "num_preco_imposto")}
    </>
  );
};
