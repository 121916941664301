
import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloPrecoBaseEdicao } from "../models/precoBaseEdicao";
import { Repositorio } from "./repositorio";

export class FiltroPrecoBaseEdit {
  user_id: string = "";
  product_category_id: string = "";
  product_id: string = "";
  state_id: string = "";

  date_start!: any;
  date_end!: any;

  page = 1;
  pageSize = 60;
  total = 0;

  constructor() {
    this.date_start = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    );
    this.date_end = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 2,
      0
    );
  }
}

export class RepositorioPrecoBaseEdicao extends Repositorio<ModeloPrecoBaseEdicao> {
  constructor() {
    super(ConstanteGlobal.PRECO_BASE_EDICAO);
  }


  deleteAll(): Promise<Boolean> {
    return this.api.post(this.nomeRepositorio + "_delete").then((res: any) => {
      return res.data.success;
    });
  }
}
