import { ConstanteGlobal } from "../config/constanteGlobal";
import { ProductSubcategoryModel } from "../models/product_subcategory";
import { Repository } from "./repository";

export class FilterProductSubcategory {
  id = "";
  description = "";

  sort: Partial<ProductSubcategoryModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class ProductSubcategoryRepositorio extends Repository<ProductSubcategoryModel> {
  constructor() {
    super(ConstanteGlobal.PRODUCT_SUBCATEGORY);
  }
}
