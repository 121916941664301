import { ConstanteGlobal } from "../config/constanteGlobal";

import { ModeloPlantaIndustrialPlantas } from "../models/plantaIndustrialPlantas";
import { FiltroPlantaIndustrial } from "./plantaIndustrialRepositorio";
import { Repositorio } from "./repositorio";

export class FiltroPlantaIndustrialPlantas extends FiltroPlantaIndustrial {
  carregando = true;

  cod_canal?: string;
  cod_cluster?: string;
  cod_diretoria?: string;
  cod_familia_subgrupo?: string;
  cod_linha?: string;
  des_periodo_analisado?: string;
  cod_produto?: string;
  cod_gerencia?: string;
  cod_produto_capitao?: string;
  cod_empresa?: string;
  des_ano_mes?: string;
  num_semana_ano?: string;
  num_nivel = 0;
  cod_terceiro_centralizador?: string;
  cod_mercado?: string;
  cod_divisao_comercial?: string;
}

export class RepositorioPlantaIndustrialPlantas extends Repositorio<ModeloPlantaIndustrialPlantas> {
  constructor() {
    super(ConstanteGlobal.PLANTA_INDUSTRIAL_PLANTAS);
  }
}
