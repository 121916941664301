import { ModeloBase } from './modeloBase';

export class ModeloUsuario extends ModeloBase {
	// sAMAccountName: string = "";
	cod_usuario: any = 0;
	des_nome: string = "";
	des_email: string = "";
	flg_ativo: boolean = true;
	cod_usuario_criador?: number;
	// givenName?: string;
	// displayName?: string;

	des_nome_criador: string = "";
	cod_idioma: number = 1;

}
