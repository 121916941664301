import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico } from "../models/basico";
import { ModeloEmpresa } from "../models/empresa";
import { Repositorio } from "./repositorio";

export class FiltroEmpresa extends FiltroBasico<ModeloEmpresa> {}

export class RepositorioEmpresa extends Repositorio<ModeloEmpresa> {
  constructor() {
    super(ConstanteGlobal.EMPRESA);
  }

  getDiretoria(){
    return this.api.get(`${this.nomeRepositorio}_diretoria_get_all`).then((res) => {
      return res.data;
    });
  }

  getRegional(){
    return this.api.get(`${this.nomeRepositorio}_gerencia_get_all`).then((res) => {
      return res.data;
    });
  }
  getPlantas():Promise<ModeloEmpresa[]>{
    return this.api.get(`${this.nomeRepositorio}_plantas_get_all`).then((res) => {
      return res.data;
    });
  }
}
