import React, { createContext, useState, useContext } from "react";

interface ToastInterface {
  titulo: string;
  mensagem: string;
  tipo: "success" | "info" | "error";
}

// O que o contexto irá passar para o outros componentes
interface ToastContextoInterface {
  toast: ToastInterface | null;
  mostrarToast(toast: ToastInterface): void;
  limparToast(): void;
}

// Como o objeto de contexto vai iniciar
const ToastContexto = createContext<ToastContextoInterface>({} as ToastContextoInterface);

type Props = {
  children?: JSX.Element,
};
// Componente de contexto que irá por volta de todos os outros componentes
export const ToastProvider: React.FC<Props> = ({ children }) => {
  const [toast, setToast] = useState<ToastInterface | null>(null);

  async function mostrarToast(event: ToastInterface) {
    setToast(event);
  }

  async function limparToast() {
    setToast(null);
  }

  return (
    <ToastContexto.Provider value={{ toast, mostrarToast, limparToast }}>
      {children}
    </ToastContexto.Provider>
  );
};

export function useToast() {
  const contexto = useContext(ToastContexto);

  return contexto;
}
