import React, { useEffect, useRef, useState } from "react";
import { formatarDecimal } from "../../core/utils/funcoes";
import { useToast } from "../../core/contexts/toast";
import Image from "react-bootstrap/Image";
import spinner from "../../assets/icons/spinner-black-sm.svg";
import { FaEdit, FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Button, Spinner } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { ModeloRecuperacaoCliente, ModeloRecuperacaoClienteGranularidade } from "../../core/models/recuperacaoCliente";
import { FiltroRecuperacaoCliente, RepositorioRecuperacaoCliente } from "../../core/repositories/recuperacaoClienteRepositorio";
import moment from "moment";
import { recuperacaoClienteContext } from "../../core/contexts/recuperacaoClienteContexto";

interface Props {
  linha: ModeloRecuperacaoCliente;
  index: number;
  filtro: FiltroRecuperacaoCliente;
  salvouEdicao(): void;
}

// export default function TabelaTr(props: Props) {

export const TabelaTr: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { mostrarToast } = useToast();
  const [carregar, setCarregar] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [flgElasticidade, setFlgElasticidade] = useState<boolean>();
  const [permissaoEditar, setPermissaoEditar] = useState<boolean>(true)
  const { carregandoFilho, adicionarLista, removerLista, atualizarInterface, atualizarUmaLinha, cenario } = recuperacaoClienteContext();

  useEffect(() => {

  })
  useEffect(() => {
    if (props.linha.flg_elasticidade != flgElasticidade) {
      setFlgElasticidade(props.linha.flg_elasticidade)
    }
  }, [props.linha.flg_elasticidade])

  async function carregarDados() {
    try {
      setCarregar(true);

      if (props.linha.flg_aberto) {
        removerLista(props.index);
      } else {
        await adicionarLista(props.filtro, props.index)
      }

      atualizarInterface()
      setCarregar(false);
    } catch (error) {
      console.log(error)
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });

      setCarregar(false);
    }
  }

  async function atualizarValor(atributo: string) {
    var valor = prompt("Por favor, insira um valor:");
    if (valor) {
      var valor_aux = ""
      if (atributo == "num_preco_sugerido_porcentagem") {
        valor_aux = (Number.parseFloat(valor) / props.linha.num_preco_ia).toString()
      }
      const _centro = new RepositorioRecuperacaoCliente();
      _centro.atualizarValor(atributo, valor_aux || valor?.toString(), props.linha).then((atualizadas) => {
        if (atualizadas) {
          (props.linha as any)[atributo] = valor
          atualizarUmaLinha(props.linha, props.index)
        }
        mostrarToast({
          titulo: "Sucesso",
          mensagem: `${atualizadas} linha(s) atualizada(s)`,
          tipo: "success",
        });
      }).catch(err => {
        mostrarToast({
          titulo: "Erro",
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
    }
  }
  function add_edit(atributo: string) {
    return (props.linha.num_nivel == 5 && localStorage.getItem("editando"))
      && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>
  }

  function copiar() {
    // var label = ""
    // for (let index = 0; index <= props.linha.num_nivel; index++) {
    //   label += `${ModeloRecuperacaoClienteGranularidade.INTERFACE[index]?.toString()}:(${(props.linha as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[index]]?.toString()}) - ${(props.linha as any)[ModeloRecuperacaoClienteGranularidade.DESCRICAO[index]]?.toString()}\n`;
    // }
    // label += `\n AND ${props.filtro.flg_ano_mes ? "des_ano_mes = '" + props.filtro.des_periodo_analisado + "'" : 'num_semana_ano = ' + props.filtro.des_periodo_analisado} \n`
    // for (let i = 0; i <= props.linha.num_nivel; i++) {
    //   label += `AND ${ModeloRecuperacaoClienteGranularidade.CODIGO[i]?.toString()} = ${(props.linha as any)[ModeloRecuperacaoClienteGranularidade.CODIGO[i]]?.toString()}\n`;
    // }
    // const inputTemporario = document.createElement("textarea");
    // inputTemporario.value = label;
    // document.body.appendChild(inputTemporario);
    // inputTemporario.select();
    // document.execCommand("copy");
    // document.body.removeChild(inputTemporario);
  }


  function carregandoValor(valor: number, money: boolean = false, atributo = "") {
    if (valor === undefined) {
      return <td className="text-center"> <Spinner size="sm" /> </td>
    }
    return <td>{formatarDecimal(valor, money)}
      {(props.linha.num_nivel == ModeloRecuperacaoClienteGranularidade.CODIGO.length - 1 && localStorage.getItem("editando") && atributo)
        && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>}

    </td>
  }



  return (
    <>
      {/* Granularidade */}
      <td width={200} className={(props.linha.edit ? "is-edit " : "") + " bg-white text-start granularidade-nivel"} >
        <div className="drilldown " >
          <div
            className="text-end"
            style={{ minWidth: `${20 + ((props.linha.num_nivel == ModeloRecuperacaoClienteGranularidade.DESCRICAO.length - 1) ? (props.linha.num_nivel - 1) : (props.linha.num_nivel)) * 15}px` }}
          >
            {(props.linha.num_nivel < ModeloRecuperacaoClienteGranularidade.DESCRICAO.length - 2 || (props.linha.num_nivel == ModeloRecuperacaoClienteGranularidade.DESCRICAO.length - 2 && props.linha.num_quantidade >= 0)) && (
              <Button variant="light" size="sm" onClick={carregarDados} >
                {carregar ?
                  <Image src={spinner} className="baixar-icon" /> :
                  props.linha.flg_aberto ?
                    <FaMinusCircle /> :
                    <FaPlusCircle />
                }
              </Button>
            )}
          </div>

          <span className="ms-1 w-100" onDoubleClick={copiar}>
            {/* {props.linha.num_nivel} - {  ModeloRecuperacaoClienteGranularidade.DESCRICAO.length} - {props.linha.num_quantidade} - { ((props.linha.num_nivel == ModeloRecuperacaoClienteGranularidade.DESCRICAO.length - 1 && props.linha.num_quantidade <= 1) ? (props.linha.num_nivel - 1) : (props.linha.num_nivel))} */}
            {(props.linha as any)[ModeloRecuperacaoClienteGranularidade.DESCRICAO[props.linha.num_nivel]]?.toString()}
            {/* -{ props.linha.cod_familia_subgrupo_produto_capitao} */}
          </span>

          {/* {(!(props.linha.num_nivel == ModeloRecuperacaoClienteGranularidade.DESCRICAO.length - 1 && props.linha.quantidade <= 1) && props.linha.num_volume_excessivo > 0) &&
            <OverlayTrigger
              placement="top"
              overlay={(props) => (
                <Tooltip  {...props}>
                  Há produtos com volume excessivo!
                </Tooltip>
              )}
            >
              <span className="position-absolute width-12 height-12 bg-primary-500 rounded-circle top-0 right-0" />
            </OverlayTrigger>
          } */}
        </div >
      </td >

      {/* Demanda Sugerida (Kg) */}
      {carregandoValor(props.linha.num_volume_demanda, false, "num_volume_demanda")}
      {/* Preço Sugerido */}
      {carregandoValor(props.linha.num_preco_sugerido_porcentagem, true, "num_preco_sugerido_porcentagem")  } 
      {/* Projeção de Faturamento */}
      {carregandoValor(props.linha.num_volume_demanda * props.linha.num_preco_sugerido_porcentagem, true)}
      {/* Faturamento Recência */}
      {carregandoValor(Number.parseInt(props.linha.num_faturamento_recencia?.toString()), false, "num_faturamento_recencia")}
      {/* Faturamento Data da Última Compra */}
      <td>{moment().add(props.linha.num_min_faturamento_recencia * -1, "days").format("DD/MM/YYYY")} {add_edit("num_faturamento_recencia")} </td>
      {/* Faturamento Ticket Médio */}
      {carregandoValor(props.linha.num_faturamento_preco_ticket_medio, true, "num_faturamento_preco_ticket_medio")}
      {/* Cluster Recência */}
      {carregandoValor(Number.parseInt(props.linha.num_cluster_recencia?.toString()), false, "num_cluster_recencia")}
      {/* Cluster Ticket Médio */}
      {carregandoValor(props.linha.num_cluster_preco_ticket_medio, true, "num_cluster_preco_ticket_medio")}

    </>
  );
};
