import { ModeloBase } from "./modeloBase";

export class OrdemCompraModel extends ModeloBase {
  date_update?: Date;
  branch_code?: number;
  order_number?: number;
  product_code?: number;
  product_description?: string;
  sequence_number?: number;
  issue_date?: Date;
  supplier_code?: number;
  buyer_code?: number;
  observation?: string;
  order_quantity?: number;
  unit_price?: number;
  order_value?: number;
  total_quantity?: number;
}
