import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloCenario } from "../models/cenario";
import {
  ModeloPlantaIndustrialData,
  ModeloPlantaIndustrial,
  ModeloPlantaIndustrialTabela,
  ModeloPlantaIndustrialResultado,
} from "../models/plantaIndustrial";
import { Repositorio } from "./repositorio";

export class FiltroPlantaIndustrial {
  flg_aberto = true;
  editados = false;
  flg_mostrar_resultado = false;
  num_nivel = 0;
  flg_carregar_painel = false;
  flg_semanal_mensal = "";
  cod_acabamento?: string;
  cod_canal?: string;
  cod_cluster?: string;
  cod_diretoria?: string;

  cod_familia_subgrupo_produto_capitao?: string;
  cod_linha_produto_capitao?: string;

  des_periodo_analisado?: string;
  des_periodo_analisado_atual?: string;
  cod_produto?: string;
  cod_gerencia?: string;
  cod_produto_capitao?: string;
  cod_temperatura?: string;
  cod_tipo_produto?: string;
  cod_mercado?: string;
  cod_divisao_comercial?: string;

  ini_uf_destino?: string;

  cod_bandeira?: string;

  cod_usuario?: string;

  flg_ano_mes: boolean = false;
  cod_cenario: number = -1;
}

export class RepositorioPlantaIndustrial extends Repositorio<ModeloPlantaIndustrial> {
  constructor() {
    super(ConstanteGlobal.PLANTA_INDUSTRIAL);
  }

  download() {
    const filtro = {}
    return this.api.post(this.nomeRepositorio + "_download", filtro, {
      responseType: "arraybuffer",
    });
  }
  atualizarValor(des_atributo: string, des_valor: string, modelo: ModeloPlantaIndustrial) {
    return this.api.post(this.nomeRepositorio + "_atualizar_valor", { des_atributo, des_valor, ...modelo }).then((res) => {
      return res.data;
    });
  }

  call() {
    return this.api.post(this.nomeRepositorio + "/call").then((res) => {
      return res.data;
    });
  }

  getDate(data: FiltroPlantaIndustrial): Promise<ModeloPlantaIndustrialData[]> {
    return this.api.post(this.nomeRepositorio + "_date_post", data).then((res) => {
      return res.data;
    });
  }
  getModeloUltimoHorario(mensal: boolean) {
    return this.api.get(this.nomeRepositorio + "_modelo_ultimo_horario?mensal=" + mensal).then((res) => {
      return res.data;
    });
  }

  getTable(data: FiltroPlantaIndustrial): Promise<ModeloPlantaIndustrialTabela[]> {
    return this.api.post(this.nomeRepositorio + "_table_post", data).then((res) => {
      return res.data;
    });
  }

  getResultado(data: FiltroPlantaIndustrial): Promise<ModeloPlantaIndustrialResultado[]> {
    return this.api.post(this.nomeRepositorio + "_modelo_sugerido_mkt", data).then((res) => {
      return res.data;
    });
  }


  salvarPlantaIndustrialEdicao(cenario: ModeloCenario): Promise<number> {
    return this.api.post(this.nomeRepositorio + '_salvar_todos_cenario_post', cenario).then((res) => {
      return res.data
    })
  }

  baixarTodos(filtro: any) {
    return this.api.post(this.nomeRepositorio + "_baixar_tudo", filtro, {
      responseType: "arraybuffer",
    });
  }
  baixarSkuBandeira(filtro: any) {
    return this.api.post(this.nomeRepositorio + "_baixar_sku_bandeira", filtro, {
      responseType: "arraybuffer",
    });
  }

}
