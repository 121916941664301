import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from 'ag-grid-community';
import moment from "moment";

import "./index.scss";

import { ModeloPrecoBase, ModeloPrecoBaseGranularidade } from "../../core/models/precoBase";
import { formatarDecimal } from "../../core/utils/funcoes";
import { TipoFormatacao } from "../../core/config/tipoFormatacao";
import { AG_GRID_LOCALE_BR } from "../../core/config/AgGridReact";


interface Props {
    show: boolean;
    data?: ModeloPrecoBase
    subTitle?: string;
    onHide: () => void;
}

export const ModalBuyers: React.FC<Props> = (props) => {

    const [loading, setLoading] = React.useState(false);
    const [lista, setLista] = React.useState<ModeloPrecoBase[]>([]);
    const [title, setTitle] = React.useState<string>("");
    const gridRef = React.useRef<AgGridReact<ModeloPrecoBase>>(null);

    const [rowData, setRowData] = React.useState<any[]>([]);

    React.useEffect(() => {
        setLoading(true);
        if (props.show && props.data) {
            // setRowData([])
            showLoadingOverlay()

            // var title = ""
            // for (let index = 0; index <= props.data.num_nivel; index++) {
            //     title += (props.data as any)[ModeloPrecoBaseGranularidade.DESCRICAO[index]]?.toString() + " ";
            // }
            setTitle((props.data as any)[ModeloPrecoBaseGranularidade.DESCRICAO[props.data.num_nivel]]?.toString());
        }
        setTimeout(() => {
            setLoading(false);
            setRowData(generateFakeData(30));
        }, 1500);

    }, [props.show, props.data]);


    const CustomSkeleton = (props: { value: any; money?: boolean }) => {
        return (
            <>
                {loading ? (
                    <Skeleton />
                ) :
                    typeof props.value === 'object' && props.value instanceof Date ?
                        moment(props.value).format(TipoFormatacao.FORMATO_DATA)
                        : typeof props.value === 'number' ?
                            formatarDecimal(props.value, props.money)
                            : props.value
                }
            </>
        );
    };
    const columnDefs: ColDef[] = [
        { headerName: "Fornecedor", field: "comprador", minWidth: 150, flex: 1, floatingFilter: true, filter: "agTextColumnFilter", resizable: false, cellRenderer: CustomSkeleton },
        { headerName: "Produto", field: "produto", minWidth: 200, flex: 2, floatingFilter: true, filter: "agTextColumnFilter", resizable: false, cellRenderer: CustomSkeleton },
        { headerName: "Data", field: "data", minWidth: 120, flex: 1, floatingFilter: true, filter: "agDateColumnFilter", resizable: false, cellRenderer: CustomSkeleton },
        { headerName: "Quantidade", field: "quantidade", minWidth: 100, flex: 1, floatingFilter: true, filter: "agNumberColumnFilter", resizable: false, cellRenderer: CustomSkeleton },
        {
            headerName: "Preço", field: "preco", minWidth: 100, flex: 1, floatingFilter: true, filter: "agNumberColumnFilter", resizable: false, cellRenderer: (params: any) => (<CustomSkeleton value={params.value} money={true} />)
        },
        { headerName: "Total", field: "total", minWidth: 100, flex: 1, floatingFilter: true, filter: "agNumberColumnFilter", resizable: false, cellRenderer: CustomSkeleton },
    ];


    const generateFakeData = (numRows: number) => {
        const data = [];
        for (let i = 0; i < numRows; i++) {
            const quantity = Math.floor(Math.random() * 100) + 1;
            const price = (Math.random() * (18.36 - 15.50) + 15.50);
            const total = (quantity * price);
            data.push({
                comprador: `Fornecedor ${i + 1}`,
                produto: `Produto ${i + 1}`,
                data: new Date(),
                quantidade: quantity,
                preco: price,
                total: total,
            });
        }
        return data;
    };
    function showLoadingOverlay() {
        if (props.show && gridRef.current && gridRef.current.api) {
            gridRef.current.componentDidUpdate = () => {
                if (loading && gridRef.current && gridRef.current.api) {
                    gridRef.current.api.showLoadingOverlay();
                } else if (gridRef.current && gridRef.current.api) {
                    gridRef.current.api.hideOverlay();
                }
            };
        }
    }

    return (
        <>
            <Modal centered aria-labelledby="contained-modal-title-vcenter" size="lg" {...props}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center" >
                        Pedidos de compras:  {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ag-theme-quartz w-100" style={{ height: 500 }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            gridId="modalBuyers"
                            columnDefs={columnDefs}
                            embedFullWidthRows={true}
                            pagination={true}
                            paginationPageSize={20}
                            localeText={AG_GRID_LOCALE_BR}
                            overlayLoadingTemplate='<span class="">Carregando...</span>'
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => {
                        props.onHide();
                    }}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );




};
