import * as React from "react";
import { formatarDecimal, formatarPorcentagem } from "../../core/utils/funcoes";
import "./index.scss";
import { Badge, Spinner } from "react-bootstrap";

interface Props {
  valor: number | undefined;
  className?: string;
  minValue: number;
  maxValue: number;
}

const FatorDecisao: React.FC<Props> = (props) => {
  const { valor,  className, minValue, maxValue } = props;

  const calculateClass = () => {
    var min = minValue / 100;
    var max = maxValue / 100;
    if (valor === undefined) {
      return "v0";
    }
    if (valor == ((max+min)/2) || valor ==null) {
      return "v0";
    } else if (valor >= max) {
      return "v100";
    }

    const percentage = ((valor - min) / (max - min)) * 100;

    const roundedPercentage = Math.round(percentage / 25) * 25;
    if (maxValue == 50) {
      console.log(percentage);
      console.log(valor, `v-${100 - roundedPercentage}`);
    }
    return percentage < 50 ? `v-${100 - roundedPercentage}` : `v${roundedPercentage}`;
  };

  const renderedClass = (className || "") + " " + calculateClass();

  return (
    <div className={renderedClass}>
      <Badge pill bg="light" text="dark">
        {valor === undefined ? <Spinner size="sm" /> : formatarPorcentagem(valor)}
      </Badge>
    </div>
  );
};

export default FatorDecisao;
