import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importe os arquivos de tradução
import translationEN from './en.json';
import translationPT from './pt.json';

// Configurações do i18next
i18n
  .use(initReactI18next) // Adiciona o plugin do react-i18next
  // .use(LanguageDetector) // Detecta automaticamente o idioma do navegador
  .init({
    resources: {
      pt: {
        translation: translationPT, // Arquivo de tradução para o Português
      },
      en: {
        translation: translationEN, // Arquivo de tradução para o Inglês
      },
    },
    fallbackLng:  localStorage.getItem('language') || 'en', // Idioma padrão se o idioma detectado não estiver disponível
    debug: true, // Ativa logs para debug
    interpolation: {
      escapeValue: false, // Evita escapar valores HTML
    },
  });

export default i18n;
