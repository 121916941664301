import React from "react";

import AutenticaçãoRoutes from "./auth.routes";
import AppRoutes from "../routes/app.routes";
import { useAutenticação } from "../core/contexts/auth";

const Routes: React.FC = () => {
  // O app vai voltar para as rotas de autenticação
  const { signed, getUsuarioCookie } = useAutenticação();
  return signed || getUsuarioCookie() ? <AppRoutes /> : <AutenticaçãoRoutes />;

};

export default Routes;
