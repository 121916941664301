import React, { createContext, useState, useContext, useEffect } from "react";
import { ModeloBasico } from "../models/basico";
import { ModeloAcabamento } from "../models/acabamento";
import { ModeloBandeira } from "../models/bandeira";
import { ModeloCluster } from "../models/cluster";
import { ModeloDivisaoComercial } from "../models/divisaoComercial";
import { ModeloFamiliaSubgrupo } from "../models/familiaSubgrupo";
import { ModeloMercado } from "../models/mercado";
import { ModeloTemperatura } from "../models/temperatura";
import { ModeloTipoProduto } from "../models/tipoProduto";
import { ModeloUFDestino } from "../models/ufDestino";
import { RepositorioAcabamento } from "../repositories/acabamentoRepositorio";
import { RepositorioBandeira } from "../repositories/bandeiraRepositorio";
import { RepositorioCluster } from "../repositories/clusterRepositorio";
import { RepositorioDivisaoComercial } from "../repositories/divisaoComercialRepositorio";
import { RepositorioFamiliaSubgrupo } from "../repositories/familiaSubgrupoRepositorio";
import { RepositorioMercado } from "../repositories/mercadoRepositorio";
import { RepositorioTemperatura } from "../repositories/temperaturaRepositorio";
import { RepositorioTipoProduto } from "../repositories/tipoProdutoRepositorio";
import { RepositorioUFDestino } from "../repositories/ufDestinoRepositorio";
import { FiltroPeriodoAnalisado, RepositorioPeriodoAnalisado } from "../repositories/periodoAnalisadoRepositorio";
import { useToast } from "./toast";
import { TipoMensagem } from "../config/tipoMensage";
import { formatarPeriodo, mergeSortBandeira } from "../utils/funcoes";
import { AutenticaçãoProvider, useAutenticação } from "./auth";
import { useTranslation } from "react-i18next";

// O que o contexto irá passar para o outros componentes
interface FiltroContextoInterface {
  listaAcabamento: ModeloAcabamento[];
  listaBandeira: ModeloBandeira[];
  listaCluster: ModeloCluster[];
  listaDivisaoComercial: ModeloDivisaoComercial[];
  listaFamiliaSubgrupo: ModeloFamiliaSubgrupo[];
  listaMercado: ModeloMercado[];
  listaTemperatura: ModeloTemperatura[];
  listaTipoProduto: ModeloTipoProduto[];
  listaUFDestino: ModeloUFDestino[];
  listaPeriodoSemana?: ModeloBasico[];
  listaPeriodoMes?: ModeloBasico[];
  periodoCarregado: boolean;
}

// Como o objeto de contexto vai iniciar
const FiltroContexto = createContext<FiltroContextoInterface>({} as FiltroContextoInterface);

type Props = {
  children?: JSX.Element,
};
// Componente de contexto que irá por volta de todos os outros componentes
export const FiltroProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { mostrarToast } = useToast();
  const { usuario } = useAutenticação()
  const [listaAcabamento, setListaAcabamento] = useState<ModeloAcabamento[]>([]);
  const [listaCluster, setListaCluster] = useState<ModeloCluster[]>([]);
  const [listaDivisaoComercial, setListaDivisaoComercial] = useState<ModeloDivisaoComercial[]>([]);
  const [listaFamiliaSubgrupo, setListaFamiliaSubgrupo] = useState<ModeloFamiliaSubgrupo[]>([]);
  const [listaMercado, setListaMercado] = useState<ModeloMercado[]>([]);
  const [listaTemperatura, setListaTemperatura] = useState<ModeloTemperatura[]>([]);
  const [listaTipoProduto, setListaTipoProduto] = useState<ModeloTipoProduto[]>([]);
  const [listaUFDestino, setListaUFDestino] = useState<ModeloUFDestino[]>([]);
  const [listaBandeira, setListaBandeira] = useState<ModeloBandeira[]>([]);

  const [listaPeriodoSemana, setListaPeriodoSemana] = useState<ModeloBasico[]>();
  const [listaPeriodoMes, setListaPeriodoMes] = useState<ModeloBasico[]>();
  const [periodoCarregado, setPeriodoCarregado] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {

      try {
        await carregarPeriodo()

        new RepositorioAcabamento().getAll().then((res) => {
          setListaAcabamento(res);
        });
        new RepositorioBandeira().getAll().then((res) => {
          // const inicio = new Date();
          // const dadosOrdenados = res.sort((a: ModeloBandeira, b: ModeloBandeira) => a.des_bandeira.localeCompare(b.des_bandeira))
          const dadosOrdenados = mergeSortBandeira(res)
          // const fim = new Date();
          // console.log('Tempo de Execução:', fim.getTime() - inicio.getTime(), 'milissegundos');
          setListaBandeira(dadosOrdenados);
        });
        new RepositorioCluster().getAll().then((res) => {
          setListaCluster(res);
        });
        new RepositorioDivisaoComercial().getAll().then((res) => {
          setListaDivisaoComercial(res);
        });
        new RepositorioFamiliaSubgrupo().getAll().then((res) => {
          setListaFamiliaSubgrupo(res);
        });
        new RepositorioMercado().getAll().then((res) => {
          setListaMercado(res);
        });
        new RepositorioTemperatura().getAll().then((res) => {
          setListaTemperatura(res);
        });
        new RepositorioTipoProduto().getAll().then((res) => {
          setListaTipoProduto(res);
        });
        new RepositorioUFDestino().getAll().then((res) => {
          setListaUFDestino(res);
        });
      } catch (error) {
        console.error(error)
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      }
    }
    if (usuario) {
      fetchData()
    }
  }, [usuario])

  async function carregarPeriodo() {
    const filter = new FiltroPeriodoAnalisado();

    filter.flg_ano_mes = false;
    var res = await new RepositorioPeriodoAnalisado().getFilter(filter)
    var aux = formatarPeriodo(res.map(x => filter.flg_ano_mes ? x.des_ano_mes : x.num_semana_ano), filter.flg_ano_mes);
    setListaPeriodoSemana(aux);

    filter.flg_ano_mes = true;

    res = await new RepositorioPeriodoAnalisado().getFilter(filter)
    aux = formatarPeriodo(res.map(x => filter.flg_ano_mes ? x.des_ano_mes : x.num_semana_ano), filter.flg_ano_mes);
    setListaPeriodoMes(aux);

    setPeriodoCarregado(true);
  }

  return (
    <FiltroContexto.Provider
      value={{
        listaAcabamento,
        listaBandeira,
        listaCluster,
        listaDivisaoComercial,
        listaFamiliaSubgrupo,
        listaMercado,
        listaTemperatura,
        listaTipoProduto,
        listaUFDestino,
        listaPeriodoSemana,
        listaPeriodoMes,
        periodoCarregado
      }}
    >
      {children}
    </FiltroContexto.Provider>
  );
};

export function filtroContexto() {
  const contexto = useContext(FiltroContexto);

  return contexto;
}
