import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico, ModeloBasico } from "../models/basico";
import { ModeloDiretoria } from "../models/diretoria";
import { Repositorio } from "./repositorio";

export class FiltroDiretoria extends FiltroBasico<ModeloDiretoria> {
  cod_canal ?: string;

}

export class RepositorioDiretoria extends Repositorio<ModeloDiretoria> {
  constructor() {
    super(ConstanteGlobal.DIRETORIA);
  }
}
