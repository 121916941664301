import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico, ModeloBasico } from "../models/basico";
import { ModeloCidade } from "../models/cidade";
import { Repositorio } from "./repositorio";

export class FiltroCidade extends FiltroBasico<ModeloCidade> {
  cod_uf?: string;
}

export class RepositorioCidade extends Repositorio<ModeloCidade> {
  constructor() {
    super(ConstanteGlobal.CIDADE);
  }
}
