import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { ToastProvider } from './core/contexts/toast';
import Routes from './routes';
import { AutenticaçãoProvider } from './core/contexts/auth';
import { ModalProvider } from './core/contexts/modalContexto';
import { FiltroProvider } from './core/contexts/filtroContexto';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './App.scss';
import "./translation/i18n";

import { ModalSelectProvider } from './core/contexts/modalSelectContext';


function App() {
  return (
    <AutenticaçãoProvider>
      <ToastProvider>
        <ModalProvider>
        <ModalSelectProvider>
          <BrowserRouter>
            <ErrorBoundary>
              <FiltroProvider>
                <Routes />
              </FiltroProvider>
            </ErrorBoundary>
          </BrowserRouter>
          </ModalSelectProvider>
        </ModalProvider>
      </ToastProvider>
    </AutenticaçãoProvider>
  );
}

export default App;
