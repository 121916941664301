import { ConstanteGlobal } from "../config/constanteGlobal";
import { CatalogoPrecoBaseMinimumModel } from "../models/catalagoPrecoBaseMinimo";
import { Repositorio } from "./repositorio";


export class FilterCatalogoPrecoBaseMinimum {
  user_id: string = "";
  product_category_id: string = "";
  product_id: string = "";
  state_id: string = "";

  page = 1;
  pageSize = 60;
  total = 0;
}

export class CatalogoPrecoBaseMinimumRepositorio extends Repositorio<CatalogoPrecoBaseMinimumModel> {
  constructor() {
    super(ConstanteGlobal.BASE_PRICE_CATALOG_MINIMUM);
  }

  deleteAll(): Promise<Boolean> {
    return this.api.post(this.nomeRepositorio + "_delete").then((res: any) => {
      return res.data.success;
    });
  }
}
