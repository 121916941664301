import * as React from "react";
import { Spinner, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import TableRow from "../../components/TableRow";
import moment from "moment";
import { ModeloPrecoBase, ModeloPrecoBaseGranularidade } from "../../core/models/precoBase";
import { FiltroPrecoBase, RepositorioPrecoBase } from "../../core/repositories/precoBaseRepositorio";
import { ModalBuyers } from "./modalBuyers";
import { TableTr } from "./tableTr";
import { precoBaseContexto } from "../../core/contexts/precoBaseContexto";
import { useTranslation } from "react-i18next";
import { useToast } from "../../core/contexts/toast";
import { TipoFormatacao } from "../../core/config/tipoFormatacao";


interface Props {
  search: FiltroPrecoBase;
  open: boolean;
  loadingFirstTime: boolean;
  setLoadingFirstTime(valor: boolean): void;
}

export function updateHeightTable() {
  setTimeout(() => {
    const elementoTabela = document.getElementById("tabela")?.children[0] as HTMLElement;
    if (elementoTabela) {
      const alturaTabela = `calc(100vh - ${elementoTabela.getBoundingClientRect().top}px - 0px)`;
      elementoTabela.style.height = alturaTabela;
    }
  }, 200);
}

const PrecoBaseTable: React.FC<Props> = (props) => {
  const { lista, carregando, filtroNoContexto } = precoBaseContexto();
  const [carregandoPrimeiraVez, setCarregandoPrimeiraVez] = React.useState(true);
  const [lastTimeModel, setLastTimeModel] = React.useState<string>();
  const [modalBuyers, setModalBuyers] = React.useState<ModeloPrecoBase>();
  const { mostrarToast } = useToast();
  const { t } = useTranslation();

  function title(item: any) {
    return (
      ModeloPrecoBaseGranularidade.INTERFACE[item.num_nivel] +
      ": " +
      item[ModeloPrecoBaseGranularidade.DESCRICAO[item.num_nivel]]?.toString()
    );
  }

  React.useEffect(() => {
    updateHeightTable();
  }, [props.open]);

  React.useEffect(() => {
    if (carregando && carregandoPrimeiraVez) {
      setCarregandoPrimeiraVez(false)
    }
  }, [carregando]);

  React.useEffect(() => {
    new RepositorioPrecoBase().getModelLastTime(props.search.num_ano_semana).then(res => {
      if (res) {
        setLastTimeModel(res)
      }
    }).catch(() => {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    })
  }, [props.search.num_ano_semana]);


 

  return (
  
    <div id="tabela">
      <Table className="rounded " hover bordered responsive size="sm">
        <thead>
          <tr className="table-header">
            <TableRow classNameTh="width-200 granularidade">Granularidade</TableRow>
            <TableRow classNameTh="width-92">Estoque</TableRow>
            <TableRow classNameTh="width-92">Compra em Aberto</TableRow>


            <TableRow classNameTh="width-120">Tendência de Demanda</TableRow>
            <TableRow classNameTh="width-120">Tendência de Preço</TableRow>

            <TableRow classNameTh="width-92">Volume Vendido</TableRow>
            <TableRow classNameTh="width-120">Preço PMZ</TableRow>

            {/* <TableRow classNameTh="width-92">Preço Salvo</TableRow> */}
            {/* <TableRow classNameTh="width-92">Preços anteriores</TableRow> */}
            {/* <TableRow classNameTh="width-92">Preço real (D-1)</TableRow> */}
            <TableRow classNameTh="width-92">Preço do Concorrente</TableRow>
            <TableRow classNameTh="width-92">Preço Tabela Atual</TableRow>
            <TableRow classNameTh="width-92">Δ Preço <br /> Tabela X PMZ</TableRow>
            <TableRow classNameTh="width-92">Demanda IA (S+1)</TableRow>
            <TableRow classNameTh="width-120">Preço Sugerido IA (S+1)</TableRow>
            <TableRow classNameTh="width-92">Δ Preço <br /> IA X PMZ</TableRow>
            {/* <TableRow classNameTh="width-92">Δ Preço <br/> IA X Editado</TableRow> */}

            <TableRow classNameTh="width-120">Automatizar?</TableRow>
            <TableRow classNameTh="width-80">Aceitar?</TableRow>
            <TableRow classNameTh="width-160">Editar Preço</TableRow>
            <TableRow classNameTh="width-92">Δ Preço Editado X PMZ</TableRow>
            <TableRow classNameTh="width-92">Δ Preço Editado X IA</TableRow>

            {/* <TableRow classNameTh="width-80">Salvar</TableRow> */}
          </tr>
        </thead>
        {/* <tbody className="margin-top-60"> */}

        <tbody>
          {carregando ? (

            Array.from({ length: 9 }).map(
              (_, index) => (
                <tr className="height-42" key={"skeleton-tr" + index}>
                  {Array.from({ length: 18 }).map((_, index) => (
                    <td key={"skeleton-td" + index}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              )
            )

          ) : (
            lista.length == 0 ?
              <tr >
                <td colSpan={23} className="text-center">
                  {carregandoPrimeiraVez ? "Clique em buscar para carregar os dados!" : "Nenhum registro encontrado!"}
                </td>
              </tr> :

              lista.map((item, index) => (
                !item.flg_oculto &&
                <tr key={"tr-" + item.id + index} className={"font-size-" + (14 - item.num_nivel * 0) + (" nivel-" + (item.num_nivel + 1))} title={title(item)}>
                  <TableTr data={item} index={index} search={props.search} onModalBuyers={() => {
                    console.log("modal buyers")
                    setModalBuyers(item)
                  }} />
                </tr>
              ))
          )}
          <tr className=" border-0">
            <td colSpan={23} className={"text-start border-0 bs-white "}>
              {lastTimeModel === undefined ?
                <Spinner size="sm" /> :
                <>
                  Última atualização do banco de dados:
                  <b> {moment.utc(lastTimeModel).add(-3, 'hours').format(TipoFormatacao.FORMATO_DATA_HORA)}</b>
                </>
              }
            </td>
          </tr>

        </tbody>
      </Table>
      <ModalBuyers show={!!modalBuyers} data={modalBuyers} onHide={() => {
        setModalBuyers(undefined)
      }} />
    </div>
  );
};

export default PrecoBaseTable;

