import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloPlantaIndustrialEdicao } from "../models/plantaIndustrialEdicao";
import { Repositorio } from "./repositorio";

export class RepositorioPlantaIndustrialEdicao extends Repositorio<ModeloPlantaIndustrialEdicao> {
  constructor() {
    super(ConstanteGlobal.PLANTA_INDUSTRIAL_EDICAO);
  }

  delete(cod_usuario:number) {
    return this.api.delete(`${this.nomeRepositorio}_delete?cod_usuario=${cod_usuario}`)
  }
}
