import React, { useState } from "react";
import SelecionarBusca from "../../components/SelecionarBusca";
import { Row, Col } from "react-bootstrap";
import { FiltroCabecalho } from "../../components/FiltroCabecalho";
import { ModeloProduto } from "../../core/models/produto";
import { FiltroProduto, RepositorioProduto } from "../../core/repositories/produtoRepositorio";
import { filtroContexto } from "../../core/contexts/filtroContexto";
import { recuperacaoClienteContext } from "../../core/contexts/recuperacaoClienteContexto";
import { useToast } from "../../core/contexts/toast";
import { useTranslation } from "react-i18next";
import { ModeloRecuperacaoClienteListaTabela } from "../../core/models/recuperacaoCliente";
import { FiltroRecuperacaoCliente } from "../../core/repositories/recuperacaoClienteRepositorio";
import { ModeloUF } from "../../core/models/uf";
import { ModeloCidade } from "../../core/models/cidade";
import { FiltroCidade, RepositorioCidade } from "../../core/repositories/cidadeRepositorio";
import { RepositorioUF } from "../../core/repositories/ufRepositorio";

interface Props {
  titulo: string;
  filtro: FiltroRecuperacaoCliente;
  permissaoEditar: boolean;
  listaTabela: ModeloRecuperacaoClienteListaTabela;
  atualizarFiltro: (filtro: FiltroRecuperacaoCliente) => void;
  filtrarDados: (filtro: FiltroRecuperacaoCliente) => void;
  setListaTabela: (listaTabela: ModeloRecuperacaoClienteListaTabela) => void;
  mostrarCenarioNome: (mostrar: boolean) => void;
}

export default function FiltroPI(props: Props) {
  const { t } = useTranslation();

  const { mostrarToast } = useToast();

  const { lista } = recuperacaoClienteContext();
  const { listaBandeira, listaDivisaoComercial, listaUFDestino } = filtroContexto()
  const [listaProduto, setListaProduto] = useState<ModeloProduto[]>([]);
  const [listaUF, setListaUF] = useState<ModeloUF[]>([]);
  const [listaCidade, setListaCidade] = useState<ModeloCidade[]>([]);



  React.useEffect(() => {
    try {
      props.filtrarDados(props.filtro)
      carregarProduto();
      carregarUF();
      carregarCidade();
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }, []);


  function carregarCidade() {
    const filtro = new FiltroCidade();
    filtro.cod_uf = props.filtro.cod_uf;
    new RepositorioCidade().getFilter(filtro).then((res) => {
      setListaCidade(res);
    });
  }

  function carregarUF() {
    props.filtro.cod_cidade = ""
    new RepositorioUF().getAll().then((res) => {
      setListaUF(res);
    });
  }
  

  function carregarProduto() {
    props.filtro.cod_produto = ""
    const filtroProduto = new FiltroProduto();
    // filtroProduto.cod_familia_subgrupo = props.filtro.cod_familia_subgrupo_produto_capitao;
    // filtroProduto.cod_linha = props.filtro.cod_linha_produto_capitao;
    new RepositorioProduto().getFilter(filtroProduto).then((res) => {
      setListaProduto(res);
    });
  }


  return (
    <div className="stick">
      <FiltroCabecalho
        titulo={props.titulo}
        filtro={props.filtro}
        atualizarFiltro={props.atualizarFiltro}
        lista={lista}
        platasIndustrial={false}
        mostrarCenarioNome={props.mostrarCenarioNome}
      >
        <>
          <Row className="row-cols-2 row-cols-lg-7">
         

            <Col>
              <SelecionarBusca
                titulo="Divisão Comercial"
                optionLabel="des_divisao_comercial"
                optionValue="cod_divisao_comercial"
                className="flex-fill"
                multiple
                value={props.filtro.cod_divisao_comercial}
                options={listaDivisaoComercial}
                onChange={(value) => {
                  props.filtro.cod_divisao_comercial = value
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>
         
            <Col>
              <SelecionarBusca
                titulo="UF"
                optionLabel="des_uf"
                optionValue="cod_uf"
                multiple
                value={props.filtro.cod_uf}
                options={listaUF}
                onChange={(value) => {
                  props.filtro.cod_uf = value;
                  carregarCidade();
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>
            <Col>
              <SelecionarBusca
                titulo="Cidade"
                optionLabel="des_cidade"
                optionValue="cod_cidade"
                multiple
                value={props.filtro.cod_cidade}
                options={listaCidade}
                onChange={(value) => {
                  props.filtro.cod_cidade = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>
            <Col>
              <SelecionarBusca
                titulo="Cliente"
                optionLabel="des_bandeira"
                optionValue="cod_bandeira"
                multiple
                value={props.filtro.cod_bandeira}
                options={listaBandeira}
                onChange={(value) => {
                  props.filtro.cod_bandeira = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>
            <Col>
              <SelecionarBusca
                titulo="Produto"
                optionLabel="des_produto"
                optionValue="cod_produto"
                panelClassName="min-width-600"
                multiple
                logicaFiltroCodigo
                value={props.filtro.cod_produto}
                options={listaProduto}
                onChange={(value) => {
                  props.filtro.cod_produto = value;
                  props.atualizarFiltro(props.filtro)
                }}
              />
            </Col>

         
         
          </Row>
        </>
      </FiltroCabecalho>
    </div>
  );
}


