import { ConstanteGlobal } from "../config/constanteGlobal";
import { CatalogoPrecoBaseModel } from "../models/catalagoPrecoBase";
import { Repositorio } from "./repositorio";

export class FilterCatalogoPrecoBase {
  branch_code?: string;
  branch_type?: string;
  department_code?: string;
  category_code?: string;
  subcategory_code?: string;
  price_table_code?: string;
  product_code?: string;
  year_week: string = "";
  mesorregion_code?: string;
  flg_aberto = true;
  num_level = 0;
  cod_user?: number;
}

export class CatalogoPrecoBaseRepositorio extends Repositorio<CatalogoPrecoBaseModel> {
  constructor() {
    super(ConstanteGlobal.BASE_PRICE_CATALOG);
  }
  getYearWeekList() {
    return this.api.get(this.nomeRepositorio + "_year_week_list").then((res) => {
      return res.data;
    });
  }

  call() {
    return this.api.post(this.nomeRepositorio + "/call").then((res) => {
      return res.data;
    });
  }

  getModelLastTime(year_week: string) {
    return this.api.get(this.nomeRepositorio + "_model_last_time?year_week=" + year_week).then((res) => {
      return res.data;
    });
  }
}
