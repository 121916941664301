import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico, ModeloBasico } from "../models/basico";
import { ModeloPeriodoAnalisado } from "../models/periodoAnalisado";
import { Repositorio } from "./repositorio";

export class FiltroPeriodoAnalisado extends FiltroBasico<ModeloPeriodoAnalisado> {
  flg_ano_mes: boolean = false;
}

export class RepositorioPeriodoAnalisado extends Repositorio<ModeloPeriodoAnalisado> {
  constructor() {
    super(ConstanteGlobal.PERIODO_ANALISADO);
  }
}
