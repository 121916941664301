import { ConstanteGlobal } from "../config/constanteGlobal";
import { BranchModel } from "../models/branch";
import { Repository } from "./repository";


export class FilterBranch {
  id = "";
  description = "";

  sort: Partial<BranchModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class BranchRepository extends Repository<BranchModel> {
  constructor() {
    super(ConstanteGlobal.BRANCH);
  }
}
