import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloProduto } from "../models/produto";
import { FiltroBasico } from "../models/basico";
import { Repositorio } from "./repositorio";

export class FiltroProduto extends FiltroBasico<ModeloProduto> {
  cod_linha ?: string;
  cod_acabamento ?: string;
  cod_familia_subgrupo ?: string;
  cod_temperatura ?: string;
}

export class RepositorioProduto extends Repositorio<ModeloProduto> {
  constructor() {
    super(ConstanteGlobal.PRODUTO);
  }
}
