import React, { useState } from "react";
import { Table } from "react-bootstrap";
import TableRow from "../../components/TableRow";
import { formatarDecimal } from "../../core/utils/funcoes";
import Skeleton from "react-loading-skeleton";
import { ModeloPlantaIndustrialResultado } from "../../core/models/plantaIndustrial";
import { useTranslation } from "react-i18next";


interface Props {
  carregar: boolean;
  listaDefinicaoMkt: ModeloPlantaIndustrialResultado[];
}

export default function PlantaIndustrialPainelDefinicao(props: Props) {
  const { t } = useTranslation();

  const [tamanho,setTamanho] = useState(2);

  React.useEffect(() => {
    setTamanho(props.listaDefinicaoMkt.length)
  },[props.listaDefinicaoMkt])

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-1">
        <h5 className="mb-0">{t("pages.Definição Mkt")} </h5>
      </div>

      {/* <Table className="rounded mb-0" hover bordered striped responsive style={{ overflow: 'hidden', width: 'auto', minWidth: "350px" }}> */}
      <Table className="rounded mb-0" hover bordered striped responsive style={{ overflow: 'hidden' }}>
        <thead>
          <tr>
            <TableRow>{t("pages.Tipo de Negócio")}</TableRow>
            <TableRow>{t("pages.Toneladas")}</TableRow>
            <TableRow>{t("pages.Faturamento")}</TableRow>
          </tr>
        </thead>

        <tbody>

          {props.carregar ?
            Array.from({ length: tamanho }).map(
              (_, index) => (
                <tr className="height-26" key={"skeleton-tr" + index}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <td key={"skeleton-td" + index}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))
            :
            props.listaDefinicaoMkt.map((_, index) => (
              <tr key={"painelDefinicao" + index}>
                <td className="text-center">{_.des_canal}</td>
                <td>{formatarDecimal(_.num_volume_mkt_novo/1000)}</td>
                <td>{formatarDecimal(_.num_mkt_faturamento, true)}</td>
              </tr>
            ))
          }

        </tbody>

        <tfoot className="fw-bold">
          {props.carregar ?
            <tr className="height-26">
              <td> <Skeleton baseColor="white" /></td>
              <td> <Skeleton baseColor="white" /></td>
              <td> <Skeleton baseColor="white" /></td>
            </tr>
            :
            <tr >
              <td className="text-center">{t("pages.Total")} </td>
              <td>{formatarDecimal(props.listaDefinicaoMkt.reduce((acumulador, objeto) => acumulador + objeto.num_volume_mkt_novo, 0)/1000, false)}</td>
              <td>{formatarDecimal(props.listaDefinicaoMkt.reduce((acumulador, objeto) => acumulador + objeto.num_mkt_faturamento, 0), true)}</td>
            </tr>
          }
        </tfoot>
      </Table>
    </div>
  );
}
