import { ModeloBase } from "./modeloBase";

export const ModeloRecuperacaoClienteGranularidade = {
  CODIGO: ["cod_divisao_comercial", "cod_uf", "cod_cidade", "cod_bandeira", "cod_produto"],
  DESCRICAO: ["des_divisao_comercial", "des_uf", "des_cidade", "des_bandeira", "des_produto"],
  INTERFACE: ["Divisão Comercial", "UF", "Cidade", "Bandeira", "Produto"],
}

export class ModeloRecuperacaoCliente extends ModeloBase {
  id: string = "";
  flg_carregando_volume: boolean = false;
  flg_carregando_preco: boolean = false;
  edit: boolean = false;
  editado_usuario: "" | "preco" | "volume" = "";
  flg_aberto: boolean = false;
  oculta: boolean = false;
  flg_elasticidade?: boolean;
  num_nivel = 0;
  filhos = 0;
  num_quantidade = 0;

  cod_familia_subgrupo_produto_capitao: string = "";
  des_familia_subgrupo_produto_capitao: string = "";
  cod_linha_produto_capitao: string = "";
  des_linha_produto_capitao: string = "";
  cod_produto_capitao: string = "";
  des_produto_capitao: string = "";

  cod_canal: string = "";
  des_canal: string = "";
  cod_produto: string = "";
  des_produto: string = "";
  cod_gerencia: string = "";
  des_gerencia: string = "";
  cod_terceiro: string = "";
  des_terceiro: string = "";
  cod_empresa: string = "";
  des_empresa: string = "";
  cod_acabamento: string = "";
  des_acabamento: string = "";
  cod_temperatura: string = "";
  des_temperatura: string = "";


  quantidade: number = 0;

  editado = false;
  

  num_volume_demanda : number = 0;
  num_preco_sugerido_porcentagem : number = 0;

  num_min_faturamento_recencia : number = 0;
  num_faturamento_recencia : number = 0;
  num_faturamento_preco_ticket_medio : number = 0;
  num_min_cluster_recencia : number = 0;
  num_cluster_recencia : number = 0;
  num_cluster_preco_ticket_medio : number = 0;
  num_preco_ia: number = 0;
  

}


export class ModeloRecuperacaoClienteListaTabela {

  outros?: boolean = true;

  bar?: boolean = true;
  lif?: boolean = true;
  red?: boolean = true;
  nad?: boolean = true;
  agb?: boolean = true;
  mrb?: boolean = true;
}

export class ModeloRecuperacaoClienteData {
  date?: string;
  date_show: string = "";
}

export class ModeloRecuperacaoClienteTabela {
  table_price: string = "";
}

export class ModeloRecuperacaoClienteResultado {
  des_canal!: string;
  num_fator_decisao_sazonalidade!: number;
  num_fator_decisao_tendencia!: number;
  num_fator_decisao_elasticidade!: number;
  num_fator_decisao_giro!: number;
  num_mkt_faturamento!: number;

  num_volume_ia!: number;
  num_ia_faturamento!: number;

  num_preco_mkt_novo!: number;
  num_volume_mkt_novo!: number;
  

}