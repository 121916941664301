import React from "react";
import { useAutenticação } from "../../core/contexts/auth";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";

export const Sair: React.FC = () => {
    const { clearUsuarioState, signOut, getUsuarioAzure } = useAutenticação();
    const navigate = useNavigate();

    React.useEffect(() => {
        try {
            clearUsuarioState();
            getUsuarioAzure().then(azure => {
                signOut(azure != null);
            });
        } catch (error: any) {
            alert(typeof error === "string" ? error : error.message)
            navigate("/login");
        }
    }, []);

    return (
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Spinner animation="border" variant="primary" />
        </div>
    )
}