import * as React from "react";
import { Container } from "react-bootstrap";
import CatalogoPrecoBaseTable from "./table";
import { useEffect, useState } from "react";
import { FilterCatalogoPrecoBase } from "../../core/repositories/CatalagoPrecoBaseRepositorio";
import { Filter } from "./filter";
import { useToast } from "../../core/contexts/toast";
import "./index.scss";
import api from "../../core/services/api";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { catalogoPrecoBaseContexto } from "../../core/contexts/catalogoPrecoBaseContexto";
import { useAutenticação } from "../../core/contexts/auth";

var count: number = 0;

const BasePrice: React.FC = () => {
  const { mostrarToast } = useToast();
  const { usuario } = useAutenticação();
  const { t } = useTranslation();

  const { filtroNoContexto, carregarLista, atualizarInterface, atualizarFiltroContexto, setCarregando ,removerLista,adicionarLista} = catalogoPrecoBaseContexto();

  const [search, setSearch] = useState(new FilterCatalogoPrecoBase());
  const [loadingFirstTime, setLoadingFirstTime] = useState(true);
  const hasFetchedData = React.useRef(false); // Usando useRef para armazenar o estado de carregamento
  const [searchParams, setSearchParams] = useSearchParams();

  // const setCookie = (name: any, value: any, days: any) => {
  //   const expires = new Date(Date.now() + days * 86400000).toUTCString();
  //   document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  //   var c = new Cookies();
  //   c.set("my_cookie", "valorDoCookie", { path: "/", expires: new Date(Date.now() + days * 86400000) });
  // };

  useEffect(() => {
    if (hasFetchedData.current) {
      return;
    } // Verifica se os dados já foram buscados
    setCarregando(true);
    hasFetchedData.current = true; // Atualiza o estado de carregamento

    // searchParams.forEach((value, key) => {
    //   if(value != "undefined"){
    //     (search as any)[key] = value;
    //   }
    // });

    // searchData(search);
    
    getData(search).then(async () => {
      const num_nivel = searchParams.get("num_nivel");

      if(num_nivel){
        for (let index = 0; index < Number.parseInt(num_nivel); index++) {
          await adicionarLista(search, index);
        }
      }
    });
    // Chame essa função no seu código React para definir o cookie
    // setCookie("my_cookie", "valorDoCookie", 7); // Define um cookie que expira em 7 dias


  }, []);



  async function getData(search: FilterCatalogoPrecoBase) {

    // await api.get("/test");
    // var a = await api.get("base_price_catalog_post");
    // api.post("post_me1").then((res) => { });
    // api.get("get_me1").then((res) => { });

    // api.get("login-cookie").then((res) => {
    //   console.log(res.data);
    //   if(res.data && res.data.length > 0)
    //     alert("Cookie setado com sucesso!");
    // }  );

    // api.get("get-cookie").then((res) => {
    //   console.log(res.data);
    //   if(res.data && res.data.length > 0)
    //     alert("Cookie setado com sucesso!");
    // }  );
    console.log("getData");
    try {
      const _count = count++;

      if (_count === count - 1) {
        await carregarLista(search);
        atualizarInterface();
      }
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }

  function atualizarFiltro(newSearch: FilterCatalogoPrecoBase) {
    var a = {} as any;
    delete a.cod_user
    Object.keys(newSearch).forEach((key) => {
      if ((newSearch as any)[key] ) {
         a[key] = (newSearch as any)[key];
      }
    });
    setSearchParams(a);
    setSearch({ ...newSearch });
  }

  async function searchData(newSearch: FilterCatalogoPrecoBase) {
    console.log("searchData");
    // limparLista();
    setCarregando(true);

    newSearch.cod_user = usuario?.cod_usuario;
    newSearch.flg_aberto = false;
    setSearch({ ...newSearch });

    atualizarFiltroContexto(newSearch);

    getData(newSearch);
  }

  return (
    <div className="CatalagoBasePrice">
        <Filter filtro={search} atualizarFiltro={atualizarFiltro} filtrarDados={searchData} />
        <div>
          <CatalogoPrecoBaseTable
            search={filtroNoContexto}
            open={search.flg_aberto}
            loadingFirstTime={loadingFirstTime}
            setLoadingFirstTime={(value: boolean) => {
              setLoadingFirstTime(value);
            }}
          />
        </div>
    </div>
  );
};

export default BasePrice;
