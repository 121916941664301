import { ConstanteGlobal } from "../config/constanteGlobal";
import { CatalogoPrecoBaseEditModel } from "../models/catalagoPrecoBaseEdicao";
import { Repositorio } from "./repositorio";

export class FilterCatalogoPrecoBaseEdit {
  user_id: string = "";
  product_category_id: string = "";
  product_id: string = "";
  state_id: string = "";


  page = 1;
  pageSize = 60;
  total = 0;


}

export class CatalogoPrecoBaseEditRepositorio extends Repositorio<CatalogoPrecoBaseEditModel> {
  constructor() {
    super(ConstanteGlobal.BASE_PRICE_CATALOG_EDIT);
  }

 


  deleteAll(): Promise<Boolean> {
    return this.api.post(this.nomeRepositorio + "_delete").then((res: any) => {
      return res.data.success;
    });
  }
}
