import * as React from "react";

import { Button, Card, Container } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { useEffect, useState } from "react";
// import TabelaGerenciadorUsuario from "./table";

import "./index.scss";
import { TipoMensagem } from "../../core/config/tipoMensage";
import { ModeloUsuario } from "../../core/models/usuario";
import { FiltroUsuario, RepositorioUsuario } from "../../core/repositories/usuarioRepositorio";
import Filtro from "./filtro";
import TabelaGerenciadorUsuario from "./tabela";
import { ModeloCenario } from "../../core/models/cenario";
import { ModalGerenciadorUsuario } from "./modal";
import { useTranslation } from "react-i18next";

var count: number = 0;

const GerenciadorUsuario: React.FC = () => {
  const { t } = useTranslation();
  const { mostrarToast } = useToast();
  const [modalShow, setModalShow] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [lista, setLista] = useState<ModeloUsuario[]>([]);
  const [filtro, atualizarFiltro] = useState(new FiltroUsuario());

  const _user = new RepositorioUsuario();

  useEffect(() => {
    getList(filtro);
  }, []);

  function getList(filtro: FiltroUsuario) {
    filtro.num_pagina_atual = 1;
    atualizarFiltro({ ...filtro });
    carregandoList(filtro);
  }

  function mudarOrdenacao(filtro: FiltroUsuario) {
    atualizarFiltro({ ...filtro });
    carregandoList(filtro);
  }

  function limparFiltro() {

    const _filtro = new FiltroUsuario();
    _filtro.flg_aberto = filtro.flg_aberto
    atualizarFiltro(_filtro)

  }

  function carregandoList(filtro: FiltroUsuario) {
    const _count = count++;
    setCarregando(true);

    _user.getFilter(filtro).then((res) => {
      if (_count === count - 1) {
        filtro.num_total = res.length > 0 ? res[0].num_total||0 : 0;

        atualizarFiltro({ ...filtro });
        setLista(res);
        setCarregando(false);
      }
    }).catch(() => {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    });
  }

  return (
    <div className="GerenciadorUsuario ">
      <Filtro filtro={filtro} atualizarFiltro={filtro=>{ atualizarFiltro({ ...filtro })}} carregar={getList} />

      <div className="mesma-linha my-1">
        <div>
          <Button className="me-1" variant="outline-primary" size="sm" onClick={limparFiltro}              >
          {t("pages.Limpar")}
          </Button>
        </div>
        <div >

          <Button title= {t("pages.Criar novo usuário")}
            onClick={() => { setModalShow(true) }} size='sm' variant="outline-dark" >
           {t("pages.Novo")} 
          </Button>

        </div>
      </div>

      <div className="table-100 " >

        <TabelaGerenciadorUsuario
          filtro={filtro}
          atualizarOrdenacao={mudarOrdenacao}
          carregando={carregando}
          atualizarLista={(listaUp: ModeloUsuario[]) => {
            setLista([...listaUp]);
          }}
          lista={lista}
        />
      </div>


      <ModalGerenciadorUsuario
        show={modalShow}
        onCarregar={() => {
          getList(filtro)
        }}
        onHide={() => {
          setModalShow(false);
        }}
      />

    </div>
  );
};

export default GerenciadorUsuario;
