import { ConstanteGlobal } from "../config/constanteGlobal";
import {
  ModeloCentroDistribuicaoData,
  ModeloCentroDistribuicao,
  ModeloCentroDistribuicaoTabela,
  ModeloCentroDistribuicaoFatorDefinicao,
  ModeloCentroDistribuicaoResultadoIA,
} from "../models/centroDistribuicao";
import { Repositorio } from "./repositorio";

export class FiltroCentroDistribuicao {
  flg_aberto = true;
  editados = false;
  flg_mostrar_resultado = false;
  num_nivel = 0;
  flg_carregar_painel = false;

  cod_acabamento?: string;
  cod_empresa?: string;
  cod_cluster?: string;
  cod_diretoria?: string;
  cod_familia_subgrupo_produto_capitao?: string;
  cod_linha_produto_capitao?: string;
  des_periodo_analisado?: string;
  cod_produto?: string;

  cod_produto_capitao?: string;
  cod_temperatura?: string;
  cod_tipo_produto?: string;
  cod_mercado?: string;
  cod_divisao_comercial?: string;

  ini_uf_destino?: string;

  cod_bandeira?: string;
  flg_semanal_mensal?: string;

  cod_usuario?: number;

  flg_ano_mes = false;
  cod_cenario: number = -1;
}

export class RepositorioCentroDistribuicao extends Repositorio<ModeloCentroDistribuicao> {
  constructor() {
    super(ConstanteGlobal.CENTRO_DISTRIBUICAO);
  }

  atualizarValor(des_atributo: string, des_valor: string, modelo: ModeloCentroDistribuicao) {
    return this.api.post(this.nomeRepositorio + "_atualizar_valor", { des_atributo, des_valor, ...modelo }).then((res) => {
      return res.data;
    });
  }

  download() {
    const filtro = {}
    return this.api.post(this.nomeRepositorio + "_download", filtro, {
      responseType: "arraybuffer",
    });
  }

  call() {
    return this.api.post(this.nomeRepositorio + "/call").then((res) => {
      return res.data;
    });
  }

  getDate(data: FiltroCentroDistribuicao): Promise<ModeloCentroDistribuicaoData[]> {
    return this.api.post(this.nomeRepositorio + "_date_post", data).then((res) => {
      return res.data;
    });
  }

  getTable(data: FiltroCentroDistribuicao): Promise<ModeloCentroDistribuicaoTabela[]> {
    return this.api.post(this.nomeRepositorio + "_table_post", data).then((res) => {
      return res.data;
    });
  }

  getResultadoIA(data: FiltroCentroDistribuicao): Promise<ModeloCentroDistribuicaoResultadoIA[]> {
    return this.api.post(this.nomeRepositorio + "_resultado_ia_post", data).then((res) => {
      return res.data;
    });
  }

  getFatorDifinicao(data: FiltroCentroDistribuicao): Promise<ModeloCentroDistribuicaoFatorDefinicao[]> {
    return this.api.post(this.nomeRepositorio + "_fator_definicao_post", data).then((res) => {
      return res.data;
    });
  }
}
