import { Toast, ToastContainer, Container } from "react-bootstrap";
import React from "react";
import { useToast } from "../../core/contexts/toast";
import BarraNavegacao from "../BarraNavegacao";
import { Outlet } from "react-router-dom";
import 'moment/locale/pt'
import { FaInfoCircle, FaCheckCircle } from "react-icons/fa";
import "./index.scss";


const LayoutPrincipal: React.FC = () => {
  const { toast, limparToast } = useToast();

  return (
    <Container className="d-flex flex-column h-100 layout p-0">
      <BarraNavegacao />
      <main className="flex-shrink-0 px-1 pt-1 pb-0">
        <div className="layout-principal ">
          <Outlet />
          <ToastContainer position="bottom-end" className="p-3">
            <>
              {toast && (
                <Toast
                  onClose={() => limparToast()}
                   delay={6000}
                   autohide

                  className={"toast-" + toast.tipo
                    + (toast.tipo === "error" && " bg-danger ")
                    + (toast.tipo === "success" && " bg-info ")
                    + (toast.tipo === "info" && " bg-dark ")
                  }
                >
                    <Toast.Header>
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="text-light font-size-24 mx-1">

                          {toast.tipo === "error" && <FaInfoCircle />}
                          {toast.tipo === "info" && <FaInfoCircle />}
                          {toast.tipo === "success" && <FaCheckCircle />}

                        </label>

                        <label className="me-auto text-light">
                          {toast?.mensagem}
                        </label>
                      </div>
                    </Toast.Header>
                    

                </Toast>
              )}
            </>
          </ToastContainer>

        </div>
      </main>
    </Container>
  );
};


export default LayoutPrincipal;
