import { ModeloBase } from "./modeloBase";
import { ModeloPlantaIndustrialPlantas } from "./plantaIndustrialPlantas";

export const ModeloPlantaIndustrialGranularidade = {
  CODIGO: ["cod_canal", "cod_familia_subgrupo_produto_capitao", "cod_linha_produto_capitao", "cod_produto_capitao", "cod_produto", "cod_gerencia"],
  DESCRICAO: ["des_canal", "des_familia_subgrupo_produto_capitao", "des_linha_produto_capitao", "des_produto_capitao", "des_produto", "des_gerencia"],
  INTERFACE: ["Canal", "Família Subgrupo", "Linha", "Capitão", "Produto", "Regional"],
}

export class ModeloPlantaIndustrial extends ModeloBase {
  id: string = "";
  flg_carregando_volume: boolean = false;
  flg_carregando_preco: boolean = false;
  edit: boolean = false;
  editado_usuario: "" | "preco" | "volume" = "";
  flg_aberto: boolean = false;
  oculta: boolean = false;
  flg_elasticidade?: boolean;
  num_nivel = 0;
  filhos = 0;
  num_quantidade = 0;

  cod_familia_subgrupo_produto_capitao: string = "";
  des_familia_subgrupo_produto_capitao: string = "";
  cod_linha_produto_capitao: string = "";
  des_linha_produto_capitao: string = "";
  cod_produto_capitao: string = "";
  des_produto_capitao: string = "";

  cod_canal: string = "";
  des_canal: string = "";
  cod_produto: string = "";
  des_produto: string = "";
  cod_gerencia: string = "";
  des_gerencia: string = "";
  cod_terceiro: string = "";
  des_terceiro: string = "";
  cod_empresa: string = "";
  des_empresa: string = "";
  cod_acabamento: string = "";
  des_acabamento: string = "";
  cod_temperatura: string = "";
  des_temperatura: string = "";


  num_volume_venda: number  = 0;
  num_preco_venda: number  = 0;
  num_volume_ia: number = 0;
  num_preco_ia: number = 0;
  num_volume_carteira:  number = 0;
  num_preco_carteira:  number = 0;
  num_plano_producao_periodo_atual = 0;
  num_plano_producao_proximo_periodo = 0;
  num_saldo_estoque_inicial : number = 0;

  num_volume?: number = 0;
  num_preco?: number = 0;

  num_volume_mkt_novo?: number = 0;
  num_preco_mkt_novo?: number = 0;
  num_volume_mkt_original?: number = 0;
  num_preco_mkt_original?: number = 0;

  num_fator_decisao_sazonalidade?: number = 0;
  num_fator_decisao_tendencia?: number = 0;
  num_fator_decisao_elasticidade?: number = 0;
  num_fator_decisao_giro?: number = 0;


  des_ano_mes: string = "";
  num_semana_ano: string = "";
  quantidade: number = 0;

  editado = false;
  
  plantas?: ModeloPlantaIndustrialPlantas[];


}


export class ModeloPlantaIndustrialListaTabela {

  outros?: boolean = true;

  bar?: boolean = true;
  lif?: boolean = true;
  red?: boolean = true;
  nad?: boolean = true;
  agb?: boolean = true;
  mrb?: boolean = true;
}

export class ModeloPlantaIndustrialData {
  date?: string;
  date_show: string = "";
}

export class ModeloPlantaIndustrialTabela {
  table_price: string = "";
}

export class ModeloPlantaIndustrialResultado {
  des_canal!: string;
  num_fator_decisao_sazonalidade!: number;
  num_fator_decisao_tendencia!: number;
  num_fator_decisao_elasticidade!: number;
  num_fator_decisao_giro!: number;
  num_mkt_faturamento!: number;

  num_volume_ia!: number;
  num_ia_faturamento!: number;

  num_preco_mkt_novo!: number;
  num_volume_mkt_novo!: number;
  

}