import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import "primereact/resources/primereact.min.css";  
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { I18nextProvider } from 'react-i18next';
import i18n from './translation/i18n';
import "./translation/i18n";


ReactDOM.render(
  <I18nextProvider i18n={i18n} defaultNS={'translation'}>
  <App />
</I18nextProvider>
 ,
document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
