import { ConstanteGlobal } from "../config/constanteGlobal";
import {
  ModeloCentroDistribuicao,
} from "../models/centroDistribuicao";
import { Repositorio } from "./repositorio";

export class RepositorioCentroDistribuicaoImposto extends Repositorio<ModeloCentroDistribuicao> {
  constructor() {
    super(ConstanteGlobal.CENTRO_DISTRIBUICAO_IMPOSTO);
  }
}
