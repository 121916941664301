import { ModeloBase } from "./modeloBase";

export class ModeloPlantaIndustrialPlantas extends ModeloBase {
  cod_canal: string = "";
  cod_produto: string = "";
  cod_gerencia: string = "";
  cod_bandeira: string = "";
  cod_cluster: string = "";
  cod_empresa: number = 0;
  des_planta: string = "";

  num_imposto_perc: number = 0;
  num_frete: number = 0;
  num_preco: number = 0;

  cod_produto_capitao: string = "";
  cod_familia_subgrupo_produto_capitao: string = "";
  cod_linha_produto_capitao: string = "";

  num_semana_ano: string = "";
  des_ano_mes: string = "";

  carregando = true;
}
