import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloCenario } from "../models/cenario";
import { ModeloRecuperacaoCliente, ModeloRecuperacaoClienteData, ModeloRecuperacaoClienteTabela, ModeloRecuperacaoClienteResultado } from "../models/recuperacaoCliente";

import { Repositorio } from "./repositorio";

export class FiltroRecuperacaoCliente {
  flg_aberto = true;
  editados = false;
  num_nivel = 0;
  
  cod_usuario?: string;
  
  cod_divisao_comercial?: string;
  cod_uf?: string;
  cod_cidade?: string;
  cod_bandeira?: string;
  cod_produto?: string;

}

export class RepositorioRecuperacaoCliente extends Repositorio<ModeloRecuperacaoCliente> {
  constructor() {
    super(ConstanteGlobal.RECUPERACAO_CLIENTE);
  }

  download() {
    const filtro = {}
    return this.api.post(this.nomeRepositorio + "_download", filtro, {
      responseType: "arraybuffer",
    });
  }
  atualizarValor(des_atributo: string, des_valor: string, modelo: ModeloRecuperacaoCliente) {
    return this.api.post(this.nomeRepositorio + "_atualizar_valor", { des_atributo, des_valor, ...modelo }).then((res) => {
      return res.data;
    });
  }

  call() {
    return this.api.post(this.nomeRepositorio + "/call").then((res) => {
      return res.data;
    });
  }

  getDate(data: FiltroRecuperacaoCliente): Promise<ModeloRecuperacaoClienteData[]> {
    return this.api.post(this.nomeRepositorio + "_date_post", data).then((res) => {
      return res.data;
    });
  }
  getModeloUltimoHorario(mensal: boolean) {
    return this.api.get(this.nomeRepositorio + "_modelo_ultimo_horario?mensal=" + mensal).then((res) => {
      return res.data;
    });
  }

  getTable(data: FiltroRecuperacaoCliente): Promise<ModeloRecuperacaoClienteTabela[]> {
    return this.api.post(this.nomeRepositorio + "_table_post", data).then((res) => {
      return res.data;
    });
  }

  getResultado(data: FiltroRecuperacaoCliente): Promise<ModeloRecuperacaoClienteResultado[]> {
    return this.api.post(this.nomeRepositorio + "_modelo_sugerido_mkt", data).then((res) => {
      return res.data;
    });
  }


  salvarRecuperacaoClienteEdicao(cenario: ModeloCenario): Promise<number> {
    return this.api.post(this.nomeRepositorio + '_salvar_todos_cenario_post', cenario).then((res) => {
      return res.data
    })
  }

  baixarTodos(filtro: any) {
    return this.api.post(this.nomeRepositorio + "_baixar_tudo", filtro, {
      responseType: "arraybuffer",
    });
  }
  baixarSkuBandeira(filtro: any) {
    return this.api.post(this.nomeRepositorio + "_baixar_sku_bandeira", filtro, {
      responseType: "arraybuffer",
    });
  }

}
