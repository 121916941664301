import axios from "axios";

const api = axios.create({
  baseURL: (process.env.REACT_APP_BASE_BACK || "")+  "/api"
});


api.interceptors.request.use(
  function (config: any) {



    const cod_idioma = localStorage.getItem('language') == 'pt' ? 1 : 2
    config.params = {
      ...config.params,
      cod_idioma: cod_idioma,
    };

    if (config.method === "post" && config.data) {
      config.data["cod_idioma"] = cod_idioma
      Object.keys(config.data).forEach((res) => {
        if (config.data[res] === undefined || config.data[res] == null) {
          delete config.data[res];
        }
      });
    }
    return config;
  },
  function (erro: any) {
    return Promise.reject(erro);
  }
);

export default api;
