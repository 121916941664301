import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { TipoMensagem } from "../../core/config/tipoMensage";
import { useToast } from "../../core/contexts/toast";

import FiltroPI from "./filtro";
import { useAutenticação } from "../../core/contexts/auth";
import { atualizaTamanhoTabela } from "../../core/utils/funcoes";
import { FiltroSazonalidade } from "../../core/repositories/sazonalidadeRepositorio";
import { sazonalidadeContexto } from "../../core/contexts/sazonalidadeContexto";
import TabelaSazonalidade from "../../components/TabelaSazonalidade";
import "./index.scss";
import { useTranslation } from "react-i18next";


var contador: number = 0;

export default function Sazonalidade() {
  const { t } = useTranslation();
  const bodyRef = useRef(null);
  const { carregarLista, atualizarInterface, atualizarFiltroContexto, filtroNoContexto, setCarregando } = sazonalidadeContexto();
  const { mostrarToast } = useToast();
  const { usuario } = useAutenticação()
  const [filtro, setFiltro] = useState(new FiltroSazonalidade());
  const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false)

  React.useEffect(() => {
    carregarDados(filtro)
  }, []);

  React.useEffect(() => {
    // Seu código a ser executado quando a altura da página mudar para atualizar o tamanho da tabela
    const observer = new MutationObserver(atualizaTamanhoTabela);
    if (bodyRef.current) {
      observer.observe(bodyRef.current, { attributes: true, childList: true, subtree: true });
    }
    return () => {
      observer.disconnect();
    };
  });


  async function carregarDados(filtro: FiltroSazonalidade) {
    try {
      const _contador = contador++;
      if (_contador === contador - 1) {
        await carregarLista(filtro);
        atualizarInterface();
      }
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }

  function atualizarFiltro(novoFiltro: FiltroSazonalidade) {
    setFiltro({ ...novoFiltro });
  }

  async function filtrarDados(novoFiltro: FiltroSazonalidade) {
    setCarregando(true)
    novoFiltro.flg_aberto = false
    setFiltro({ ...novoFiltro });
    atualizarFiltroContexto({ ...novoFiltro })
    carregarDados(novoFiltro);
  }

  function limparFiltro() {
    const _filtro = new FiltroSazonalidade();
    _filtro.flg_aberto = filtro.flg_aberto;
    atualizarFiltro(_filtro)
  }

  return (
    <div className="sazonalidade" ref={bodyRef}>
      <FiltroPI
        titulo={t("pages.Sazonalidade")}
        filtro={filtro}
        atualizarFiltro={atualizarFiltro}
        permissaoEditar={permissaoEditar}
        filtrarDados={filtrarDados}
      />
      <div className="mesma-linha my-1">
        <div>
          <Button className="me-1" variant="outline-primary" size="sm" onClick={limparFiltro}              >
            {t("pages.Limpar")}
          </Button>
        </div>
        <div >
          <Button className="ms-1" variant="primary" size="sm"
            onClick={() => {
              filtrarDados(filtro)
            }}>
            {t("pages.Buscar")}
          </Button>
        </div>
      </div>

      <div className="table-100" >
        <TabelaSazonalidade
          filtro={filtroNoContexto}
          aberto={filtro.flg_aberto}
        />
      </div>


    </div>
  );
}
