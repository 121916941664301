import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico } from "../models/basico";
import { ModeloFamiliaSubgrupo } from "../models/familiaSubgrupo";
import { Repositorio } from "./repositorio";

export class FiltroFamiliaSubgrupo extends FiltroBasico<ModeloFamiliaSubgrupo> {}

export class RepositorioFamiliaSubgrupo extends Repositorio<ModeloFamiliaSubgrupo> {
  constructor() {
    super(ConstanteGlobal.FAMILIA_SUBGRUPO);
  }
}
