import { createContext, useState, useContext } from 'react';
import { ModeloSazonalidade, ModeloSazonalidadeGranularidade } from '../models/sazonalidade';
import { FiltroSazonalidade, RepositorioSazonalidade } from '../repositories/sazonalidadeRepositorio';

// O que o contexto irá passar para o outros componentes
interface SazonalidadeContextoInterface {
	lista: ModeloSazonalidade[] | [];
	carregando: boolean;
	carregandoFilho: boolean;
	filtroNoContexto: FiltroSazonalidade;
	carregarLista(filtro: FiltroSazonalidade): Promise<ModeloSazonalidade[]>;
	adicionarLista(filtro: FiltroSazonalidade, index: number): Promise<number>;
	removerLista(index: number): void;
	limparLista(): void;
	atualizarInterface(): void;
	atualizarUmaLinha(linha: ModeloSazonalidade, index: number): void;
	atualizarFiltroContexto(filtro: FiltroSazonalidade): void;
	setCarregando(carregando: boolean): void;
}

// Como o objeto de contexto vai iniciar
const SazonalidadeContexto = createContext<SazonalidadeContextoInterface>({} as SazonalidadeContextoInterface);

type Props = {
	children?: JSX.Element;
};

// Componente de contexto que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const SazonalidadeProvider: React.FC<Props> = ({ children }) => {
	const [carregando, setCarregando] = useState(false);
	const [carregandoFilho, setCarregandoFilho] = useState(false);

	var [lista, setLista] = useState<ModeloSazonalidade[]>([]);
	const [filtroNoContexto, setFiltroContexto] = useState(new FiltroSazonalidade());
	const [contador, setContador] = useState(0);

	const _sazonalidade = new RepositorioSazonalidade();


	function atualizarUmaLinha(linhaNova: ModeloSazonalidade, index: number) {
		lista[index] = linhaNova;
		atualizaLista(lista);
	}

	async function carregarLista(filtro: FiltroSazonalidade) {
		const _contador = contador + 1;
		setContador(_contador)
		setCarregando(true);

		const sazonalidadeRetorno = await _sazonalidade.getFilter(filtro, "_post")

		if (contador === _contador - 1) {
			//para abrir quando carrega trocar aberto para true
			if (filtro.num_nivel == 0) {
				sazonalidadeRetorno.map(x => { x.flg_aberto = false; x.num_nivel = 0; return x; });
			}
			const lista = [...sazonalidadeRetorno]
			atualizaLista(lista);
			setCarregando(false);
			return [...sazonalidadeRetorno]
		} else {
			return [...lista]
		}
	}

	function removerLista(index: number) {
		lista[index].flg_aberto = false;
		const nivel = lista[index].num_nivel;
		var indexFinal = index + 1

		while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
			const element = lista[indexFinal];
			element.oculta = true
			element.flg_aberto = false
			indexFinal++
		}

		atualizaLista(lista)
	}

	async function adicionarLista(filtroGeral: FiltroSazonalidade, index: number) {
		const linha = lista[index];
		setCarregandoFilho(true)
		linha.flg_aberto = true;
		try {
			if (linha.filhos > 0) {
				const nivel = lista[index].num_nivel;
				var indexFinal = index + 1

				while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
					const element = lista[indexFinal];
					// para mostrar somente os que estão um nível abaixo
					element.oculta = (nivel + 1) != lista[indexFinal].num_nivel
					indexFinal++
				}
				atualizaLista(lista)

			} else {
				const filtro = { ...filtroGeral }
				filtro.num_nivel = linha.num_nivel + 1

				for (let index = 0; index < filtro.num_nivel; index++) {
					(filtro as any)[ModeloSazonalidadeGranularidade.CODIGO[index]] = (linha as any)[ModeloSazonalidadeGranularidade.CODIGO[index]]
				}

				const response = await _sazonalidade.getFilter(filtro, "_post")

				// await new Promise((resolve) => setTimeout(resolve, 2000));
				response.map((item) => {
					item.num_nivel = linha.num_nivel + 1;
				});
				linha.filhos = response.length;
				lista.splice(index + 1, 0, ...response);
				atualizaLista(lista)
			}
			setCarregandoFilho(false)
			return linha.filhos;
		} catch (error) {
			setCarregandoFilho(false)
			return 0;
		}
	}


	function limparLista() {
		atualizaLista([])
	}

	function atualizarInterface() {
		setLista([...lista])
	}

	function atualizaLista(nova: ModeloSazonalidade[]) {
		setLista(nova)
		lista = nova;
	}


	function atualizarFiltroContexto(filtro: FiltroSazonalidade) {
		setFiltroContexto({ ...filtro })
	}

	return (
		// eslint-disable-next-line
		<SazonalidadeContexto.Provider
			value={{
				lista,
				carregando,
				carregandoFilho,
				filtroNoContexto,
				carregarLista,
				adicionarLista,
				removerLista,
				limparLista,
				atualizarInterface,
				atualizarUmaLinha,
				atualizarFiltroContexto,
				setCarregando
			}}
		>
			{children}
		</SazonalidadeContexto.Provider>
	);
};

export function sazonalidadeContexto() {
	const contexto = useContext(SazonalidadeContexto);

	return contexto;
}
