import React, { useEffect, useState } from "react";
import { useToast } from "../../core/contexts/toast";
import Image from "react-bootstrap/Image";
import spinner from "../../assets/icons/spinner-black-sm.svg";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { ModeloSazonalidade, ModeloSazonalidadeGranularidade } from "../../core/models/sazonalidade";
import { FiltroSazonalidade } from "../../core/repositories/sazonalidadeRepositorio";
import { sazonalidadeContexto } from "../../core/contexts/sazonalidadeContexto";
import { formatarDecimal, formatarPorcentagem } from "../../core/utils/funcoes";
import { useTranslation } from "react-i18next";


interface Props {
  linha: ModeloSazonalidade;
  index: number;
  filtro: FiltroSazonalidade;
}

// export default function TabelaTr(props: Props) {

export const TabelaTr: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { mostrarToast } = useToast();
  const [carregar, setCarregar] = useState(false);
  const { adicionarLista, removerLista, atualizarInterface } = sazonalidadeContexto();

  useEffect(() => {

  })


  function calculateClass(valor?: number) {
    const minValue = -10
    const maxValue = 10

    var min = minValue / 100;
    var max = maxValue / 100;
    if (valor === undefined) {
      return "v0";
    }
    if (valor == ((max + min) / 2) || valor == null) {
      return "v0";
    } else if (valor >= max) {
      return "v100";
    }
    const percentage = ((valor - min) / (max - min)) * 100;
    var roundedPercentage = Math.round(percentage / 25) * 25;
    roundedPercentage = roundedPercentage > 100 ? 100 : roundedPercentage
    roundedPercentage = roundedPercentage < 0 ? 0 : roundedPercentage
    return percentage < 50 ? `v-${100 - roundedPercentage}` : `v${roundedPercentage}`;
  };

  async function carregarDados() {
    try {
      setCarregar(true);
      if (props.linha.flg_aberto) {
        removerLista(props.index);
      } else {
        await adicionarLista(props.filtro, props.index)
      }
      atualizarInterface()
      setCarregar(false);
    } catch (error) {
      mostrarToast({
        titulo: "Erro",
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
      setCarregar(false);
    }
  }

  function copiar() {
    var label = ""
    for (let index = 0; index <= props.linha.num_nivel; index++) {
      label += `${ModeloSazonalidadeGranularidade.INTERFACE[index]?.toString()}:(${(props.linha as any)[ModeloSazonalidadeGranularidade.CODIGO[index]]?.toString()}) - ${(props.linha as any)[ModeloSazonalidadeGranularidade.DESCRICAO[index]]?.toString()}\n`;
    }
    const inputTemporario = document.createElement("textarea");
    inputTemporario.value = label;
    document.body.appendChild(inputTemporario);
    inputTemporario.select();
    document.execCommand("copy");
    document.body.removeChild(inputTemporario);
  }

  return (
    <>
      {/* Granularidade */}
      <td width={200} className={" bg-white text-start granularidade-nivel"} >
        <div className="drilldown " >
          <div
            className="text-end"
            style={{ minWidth: `${20 + ((props.linha.num_nivel == ModeloSazonalidadeGranularidade.DESCRICAO.length - 1 && props.linha.quantidade <= 1) ? (props.linha.num_nivel - 1) : (props.linha.num_nivel)) * 15}px` }}
          >
            {(props.linha.num_nivel < ModeloSazonalidadeGranularidade.DESCRICAO.length - 2 || (props.linha.num_nivel == ModeloSazonalidadeGranularidade.DESCRICAO.length - 2 && props.linha.quantidade > 0)) && (
              <Button variant="light" size="sm" onClick={carregarDados} >
                {carregar ?
                  <Image src={spinner} className="baixar-icon" /> :
                  props.linha.flg_aberto ?
                    <FaMinusCircle /> :
                    <FaPlusCircle />
                }
              </Button>
            )}
          </div>

          <span className="ms-1 w-100" onDoubleClick={copiar}>
            {(props.linha as any)[ModeloSazonalidadeGranularidade.DESCRICAO[props.linha.num_nivel]]?.toString()}
          </span>

        </div >
      </td >
      {window.location.hostname === 'localhost' &&

        <td className={"text-center "}  >
          {formatarDecimal(props.linha.num_volume_ia)}
        </td>
      }

      {Array.from({ length: 12 }).map((_, index) => (
        <td key={"linha_fator" + index} className={"text-center " + calculateClass((props.linha as any)["num_fator_decisao_sazonalidade_preco_" + (index + 1)])}  >
          {formatarPorcentagem((props.linha as any)["num_fator_decisao_sazonalidade_preco_" + (index + 1)])}
        </td>
      ))}
      <td className={"text-center " + calculateClass(props.linha.num_fator_decisao_sazonalidade_preco_1)}  >
        {formatarPorcentagem(props.linha.num_fator_decisao_sazonalidade_preco_1)}
      </td>
    </>
  );
};
