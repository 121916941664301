import React from "react";
import { useNavigate } from "react-router-dom";
import { useAutenticação } from "../../core/contexts/auth";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const LoginSuccess: React.FC = () => {
  const { getUsuarioAzure, getInfoByEmail, signOut, clearUsuarioState } = useAutenticação();
  const navigate = useNavigate();
  const { t } = useTranslation();

  React.useEffect(() => {
    try {
      getUsuarioAzure().then(async (res) => {
        if (res) {
          const usuario= await getInfoByEmail(res.des_email);
          if (usuario) {
            navigate("/catalago-preco-base");
          } else {
            alert(t("pages.Aconteceu algo inesperado!"));
            navigate("/logout");
          }
        } else {
          clearUsuarioState();
          signOut(false);
        }
      });
    } catch (error: any) {
      alert( error === 1 ? t("pages.Aconteceu algo inesperado!") : error.message);
      navigate("/login");
    }
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
};
