import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import TableRow from "../../components/TableRow";
import { formatarDecimal } from "../../core/utils/funcoes";
import Skeleton from "react-loading-skeleton";
import { ModeloCentroDistribuicaoResultadoIA } from "../../core/models/centroDistribuicao";
import { useTranslation } from "react-i18next";

interface Props {
  carregar: boolean;
  listaResultadoIA: ModeloCentroDistribuicaoResultadoIA[];
}

export default function CentroDistribuicaoPainelResultadoIA(props: Props) {
  const { t } = useTranslation();
  const [tamanho,setTamanho] = useState(2);

  useEffect(() => {
    setTamanho(props.listaResultadoIA.length)
  },[props.listaResultadoIA])

  return (
    <div >
      <div className="d-flex align-items-center justify-content-between mb-1">
        <h5 className="mb-0">{t("pages.Resultado IA")}</h5>
      </div>

      <Table className="rounded mb-0" hover bordered striped responsive style={{ overflow: 'hidden' }}>
        <thead>
          <tr>
            <TableRow>{t("pages.Empresa")}</TableRow>
            <TableRow>{t("pages.Toneladas")}</TableRow>
            <TableRow>{t("pages.Faturamento")}</TableRow>
          </tr>
        </thead>

        <tbody>
          {props.carregar ?
            Array.from({ length: tamanho }).map(
              (_, index) => (
                <tr className="height-26" key={"skeleton-tr" + index}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <td key={"skeleton-td" + index}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              )
            )
            : 
            props.listaResultadoIA.map((_, index) => (
              <tr key={"painelDefinicao" + index}>
                <td className="text-center">{_.ini_empresa}</td>
                <td>{formatarDecimal(_.num_ia_tonelada/1000)}</td>
                <td>{formatarDecimal(_.num_ia_faturamento,true)}</td>
              </tr>
            ))
          }
        </tbody>

        <tfoot className="fw-bold">
          {props.carregar ?
            <tr className="height-26">
              <td> <Skeleton baseColor="white" /></td>
              <td> <Skeleton baseColor="white" /></td>
              <td> <Skeleton baseColor="white" /></td>
            </tr>
            :
            <tr>
              <td className="text-center">{t("pages.Total")}</td>
              <td>{formatarDecimal(props.listaResultadoIA.reduce((acumulador, objeto) => acumulador + objeto.num_ia_tonelada, 0)/1000, false)}</td>
              <td>{formatarDecimal(props.listaResultadoIA.reduce((acumulador, objeto) => acumulador + objeto.num_ia_faturamento, 0), true)}</td>
            </tr>
          }
        </tfoot>
      </Table>
    </div>
  );
}
