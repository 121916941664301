import React, { useRef, useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import RecuperacaoClientePainelResultadoIA from "./painelResultadoIA";
import RecuperacaoClientePainelFatores from "./painelFatores";
import RecuperacaoClientePainelDefinicao from "./painelDefinicao";
import { recuperacaoClienteContext } from "../../core/contexts/recuperacaoClienteContexto";
import FiltroPI from "./filtro";
import { ModalCenarioNome } from "../../components/ModalCenario/modalCenarioNome";
import { RepositorioCenario } from "../../core/repositories/cenarioRepositorio";
import { atualizaTamanhoTabela, gerarTituloCenario } from "../../core/utils/funcoes";
import { useModal } from "../../core/contexts/modalContexto";
import { filtroContexto } from "../../core/contexts/filtroContexto";
import { ModeloBasico } from "../../core/models/basico";
import moment from "moment";
import "./index.scss";
import { ModalExportar } from "../../components/ModalExportar/modalExportar";
import { useAutenticação } from "../../core/contexts/auth";
import { useTranslation } from "react-i18next";
import { ModeloRecuperacaoClienteResultado, ModeloRecuperacaoClienteListaTabela } from "../../core/models/recuperacaoCliente";
import { FiltroRecuperacaoCliente, RepositorioRecuperacaoCliente } from "../../core/repositories/recuperacaoClienteRepositorio";
import TabelaRecuperacaoCliente from "../../components/TabelaRecuperacaoCliente";


var contador: number = 0;

export default function RecuperacaoClienteMockup() {
  const bodyRef = useRef(null);

  const { t } = useTranslation();

  const { lista, carregarLista, atualizarInterface, atualizarCenario, atualizarFiltroContexto, filtroNoContexto, setCarregando } = recuperacaoClienteContext();

  const { mostrarToast } = useToast();
  const { mostrarModal } = useModal();
  const { usuario } = useAutenticação()

  const [filtro, setFiltro] = useState(new FiltroRecuperacaoCliente());
  const [listaResultadoIA, setListaResultadoIA] = useState<ModeloRecuperacaoClienteResultado[]>([]);
  const [listaFatorDefinicao, setListaFatorDefinicao] = useState<ModeloRecuperacaoClienteResultado[]>([]);
  const [listaTabela, setListaTabela] = useState(new ModeloRecuperacaoClienteListaTabela());

  const [mostrarModalExportar, setModalExportar] = useState(false);
  const [mostrarCenarioNome, setMostrarCenarioNome] = useState(false);
  const [mostrarCenarioSalvar, setMostrarCenarioSalvar] = useState(false);
  const [mostrarCenarioSalvarAposNome, setMostrarCenarioSalvarAposNome] = useState(false);
  const [carregarBtnExportar, setCarregarBtnExportar] = useState(false);
  const [carregarTabelaResultadoIA, setCarregarTabelaResultadoIA] = useState(false);
  const [carregarTabelaFatorDefinicao, setCarregarTabelaFatorDefinicao] = useState(false);

  const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false)

  const _recuperacaoCliente = new RepositorioRecuperacaoCliente();

  React.useEffect(() => {

    // Seu código a ser executado quando a altura da página mudar para atualizar o tamanho da tabela
    const observer = new MutationObserver(atualizaTamanhoTabela);
    if (bodyRef.current) {
      observer.observe(bodyRef.current, { attributes: true, childList: true, subtree: true });
    }
    return () => {
      observer.disconnect();
    };
  });

  async function carregarDados(filtro: FiltroRecuperacaoCliente) {
    try {
      const _contador = contador++;
      if (_contador === contador - 1) {
        await carregarLista(filtro);
        atualizarInterface();
      }
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    }
  }



  function atualizarFiltro(novoFiltro: FiltroRecuperacaoCliente) {
    setFiltro({ ...novoFiltro });
  }

  async function filtrarDados(novoFiltro: FiltroRecuperacaoCliente) {
    setCarregando(true)
    // limparLista();
    novoFiltro.cod_usuario = usuario?.cod_usuario
    novoFiltro.flg_aberto = false

    setFiltro({ ...novoFiltro });
    atualizarFiltroContexto({ ...novoFiltro })
    carregarDados(novoFiltro);
  }



  function exportarDados() {
    setCarregarBtnExportar(true);

    _recuperacaoCliente.baixar(filtroNoContexto).then((req) => {
      var blob = new Blob([req.data], {
        type: req.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `planta_industrial_${new Date().toISOString()}.xlsx`;
      link.click();

      setCarregarBtnExportar(false);
    }).catch(() => {
      setCarregarBtnExportar(false);
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    });
  }

  function exportarTodosDados() {
    setCarregarBtnExportar(true);

    _recuperacaoCliente.baixarTodos(filtroNoContexto).then((req: { data: BlobPart; headers: { [x: string]: any; }; }) => {
      var blob = new Blob([req.data], {
        type: req.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `planta_industrial_sku_${new Date().toISOString()}.xlsx`;
      link.click();

      setCarregarBtnExportar(false);
    }).catch(() => {
      setCarregarBtnExportar(false);
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    });
  }
 

  function limparFiltro() {

    const _filtro = new FiltroRecuperacaoCliente();
    _filtro.flg_aberto = filtro.flg_aberto;

    atualizarFiltro(_filtro)

  }

  return (
    <div className="recuperacao-cliente" ref={bodyRef}>
      <FiltroPI
        titulo={t("components.Inteligência de Mercado")}
        filtro={filtro}
        atualizarFiltro={atualizarFiltro}
        permissaoEditar={permissaoEditar}
        filtrarDados={filtrarDados}
        listaTabela={listaTabela}
        setListaTabela={setListaTabela}
        mostrarCenarioNome={() => setMostrarCenarioNome(true)}
      />


      <div className="mesma-linha my-1">
        <div>
          <Button className="me-1" variant="outline-primary" size="sm" onClick={limparFiltro}>
            {t("pages.Limpar")}
          </Button>
          <Button className="me-1" variant="outline-dark" size="sm" >
            {t("pages.Gerar Relatório")}
          </Button>
          <Button className="me-1" variant="dark" size="sm" >
            {t("pages.Enviar Recomendação")}
          </Button>

        </div>

        <div >
        <Button className="ms-1" variant="primary" size="sm" 
            onClick={() => {
              filtrarDados(filtro)
            }}>
            {t("pages.Buscar")}
          </Button>
        </div>
      </div>

      <div className="table-100" >
        <TabelaRecuperacaoCliente
          filtro={filtroNoContexto}
          aberto={filtro.flg_aberto}
          salvouEdicao={() => {
            // carregarFatorDefinicao(filtroNoContexto)
          }}
        />
      </div>


      <ModalExportar
        show={mostrarModalExportar}
        planta={true}
        onHide={() => { setModalExportar(false) }}
        selecionar={(padrao: any) => {
          switch (padrao) {
            case 1:
              exportarDados()
              break;
            case 2:
              exportarTodosDados()
              break;
         
          }
          setModalExportar(false)
        }} />

    </div>
  );
}
