import { ConstanteGlobal } from "../config/constanteGlobal";
import { ModeloBasico } from "../models/basico";
import { ModeloPrecoBase, PrecoBaseDateModel, PrecoBaseTableModel } from "../models/precoBase";
import { Repositorio } from "./repositorio";


export class FiltroPrecoBase {
  // branch_code?: string = "1";
  // department_code?: string = "1";
  // category_code?: string = "1"	;
  // subcategory_code?: string = "00102400001";
  // price_table_code?: string;
  // product_id?: string;
  // num_ano_semana: string = "";
  // messorregion_code ?: string;
  // flg_aberto = true;
  // num_nivel = 0;
  // cod_user?: number;

  des_agencia: string= "";
  des_departamento: string = "";
  des_categoria: string = "";
  des_subcategoria: string= "";
  des_mesorregiao: string= "";
  des_produto: string = "";
  num_ano_semana: string = "";
  flg_aberto = true;
  num_nivel = 0;
  

//  AND num_ano_semana = 202426 
// AND branch_code = 1
// AND department_code = 1
// AND category_code = 1
// AND subcategory_code = 00102400001
// AND mesorregion_code = 2303
// AND product_code = 7645


  constructor() {
    // if (window.location.hostname === 'localhost') {
    //   this.branch_code = "1";
    //   this.department_code = "1";
    //   // this.category_code = "1";
    //   // this.subcategory_code = "00102400001";
    //   this.num_ano_semana = "202426";
    //   // this.messorregion_code = "2303";
    //   this.flg_aberto = true;
    //   this.num_nivel = 0;
    // }
   }
}

export class RepositorioPrecoBase extends Repositorio<ModeloPrecoBase> {
  constructor() {
    super(ConstanteGlobal.PRECO_BASE);
  }

  getYearWeekList(){
    return this.api.get(this.nomeRepositorio + "_num_ano_semana_list").then((res) => {
      return res.data;
  })}


  atualizarValor(des_atributo: string, des_valor: string, modelo: ModeloPrecoBase) {
    return this.api.post(this.nomeRepositorio + "_atualizar_valor", { des_atributo, des_valor, ...modelo }).then((res) => {
      return res.data;
    });
  }
  getLista(url:string):Promise<ModeloBasico[]> {
    return this.api.get(this.nomeRepositorio + "_"+url).then((res) => {
      return res.data;
  })}

  call() {
    return this.api.post(this.nomeRepositorio + "/call").then((res) => {
      return res.data;
    });
  }
  getDate(data: FiltroPrecoBase): Promise<PrecoBaseDateModel[]> {
    return this.api.post(this.nomeRepositorio + "_date_post", data).then((res) => {
      return res.data;
    });
  }
  getModelLastTime(num_ano_semana: string) {
    return this.api.get(this.nomeRepositorio + "_model_last_time?num_ano_semana=" + num_ano_semana).then((res) => {
      return res.data;
    });
  }

  getTable(data: FiltroPrecoBase): Promise<PrecoBaseTableModel[]> {
    return this.api.post(this.nomeRepositorio + "_table_post", data).then((res) => {
      return res.data;
    });
  }
}
