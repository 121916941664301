import { ModeloBase } from "./modeloBase";

export class ModeloPlantaIndustrialEdicao extends ModeloBase {
  dat_insercao?: string;
  cod_usuario?: string;
  des_nome?: string;
  cod_canal: string = "";
  cod_familia_subgrupo_produto_capitao: string = "";
  cod_linha_produto_capitao: string = "";
  cod_produto_capitao: string = "";
  cod_produto?: string;
  cod_gerencia?: string;
  num_volume_mkt_antes: number = 0;
  num_preco_mkt_antes: number = 0;
  num_volume_mkt_novo?: number = 0;
  num_preco_mkt_novo?: number = 0;
  des_ano_mes?: string;
  num_semana_ano?: string;
  flg_ativo: boolean = false;
  flg_elasticidade: boolean = true;

  cod_cenario: number = 0;
  cod_cluster ?: number;
  cod_terceiro_centralizador?: number;
}
