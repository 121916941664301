import * as React from "react";
import "./index.scss";
import { ModeloCentroDistribuicao } from "../../core/models/centroDistribuicao";
import CelulaShelf from "./celula";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { TipoMensagem } from "../../core/config/tipoMensage";
import { RepositorioCentroDistribuicao } from "../../core/repositories/centroDistrubuicaoRepositorio";
import { centroDistribuicaoContext } from "../../core/contexts/centroDistribuicaoContexto";
import { useToast } from "../../core/contexts/toast";
import { useTranslation } from "react-i18next";

interface Props {
  linha: ModeloCentroDistribuicao;
  index: number;
  className?: string;
}

const Shelf: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { atualizarUmaLinha } = centroDistribuicaoContext();
  const { mostrarToast } = useToast();


  async function atualizarValor(atributo: string) {
    const valor = prompt("Por favor, insira um valor:");
    if (valor) {
      const _centro = new RepositorioCentroDistribuicao();


      _centro.atualizarValor(atributo, valor, props.linha).then((atualizadas) => {
        (props.linha as any)[atributo] = valor
        atualizarUmaLinha(props.linha, props.index)
        console.log(atualizadas)
        mostrarToast({
          titulo: "Sucesso",
          mensagem: `${atualizadas} linha(s) atualizada(s)`,
          tipo: "success",
        });
      }).catch(() => {
        mostrarToast({
          titulo: "Erro",
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });

    }

  }
  function add_edit(atributo: string) {
    return (props.linha.num_nivel == 5 && localStorage.getItem("editando"))
      && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>
  }

  return (
    <>
      {props.linha.num_nivel < 3 ? (

        props.linha.num_shelf_resfriado_0_30 + props.linha.num_shelf_resfriado_30_40 + props.linha.num_shelf_resfriado_40 + props.linha.num_shelf_congelado < 0 ?
          <td className="text-center">
            <Spinner size="sm" />
          </td> :
          <td >
            <CelulaShelf nivel={3} valor={props.linha.num_shelf_resfriado_0_30 + props.linha.num_shelf_resfriado_30_40 + props.linha.num_shelf_resfriado_40} />
          </td>

      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id="button-tooltip" >
              <div className="text-start">
                <strong className="color0">{t("components.VERDE")}</strong> - {t("components.Idade do estoque menor que 30")}<br />
                <strong className="color1">{t("components.AMARELO")}</strong> - {t("components.Idade do estoque entre 30 e 40")}<br />
                <strong className="color2">{t("components.VERMELHO")}</strong> - {t("components.Idade do estoque maior que 40")}<br />
              </div>

            </Tooltip>
          }
        >
          <td>
            <CelulaShelf nivel={0} valor={props.linha.num_shelf_resfriado_0_30} /> {add_edit("num_shelf_resfriado_0_30")}
            <CelulaShelf nivel={1} valor={props.linha.num_shelf_resfriado_30_40} /> {add_edit("num_shelf_resfriado_30_40")}
            <CelulaShelf nivel={2} valor={props.linha.num_shelf_resfriado_40} /> {add_edit("num_shelf_resfriado_40")}
          </td>
        </OverlayTrigger>
      )}
    </>
  );
};

export default Shelf;
