import { ConstanteGlobal } from '../config/constanteGlobal';
import { FiltroBasico } from '../models/basico';
import { ModeloUsuario } from '../models/usuario';
import { Repositorio } from './repositorio';


export class FiltroUsuario extends FiltroBasico<ModeloUsuario> {
	flg_ativo: boolean = false;
	flg_aberto: boolean = false;
	des_nome: string = "";
}

export class RepositorioUsuario extends Repositorio<ModeloUsuario> {
	constructor() {
		super(ConstanteGlobal.USUARIO);
	}

	public mudarStatus(des_email: string, flg_ativo: boolean) {
		return this.api.post(`${this.nomeRepositorio}_mudar_status`, {
			des_email,
			flg_ativo: flg_ativo
		}).then((res) => {
			return res.data;
		});
	}

}
