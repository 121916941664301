import * as React from "react";
import { Spinner, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import TableRow from "../../components/TableRow";
import "react-loading-skeleton/dist/skeleton.css";
import { TableTr } from "./tableTr";
import moment from "moment";
import { useToast } from "../../core/contexts/toast";
import { ModalBuyers } from "./modalBuyers";
import { ModalEdits } from "./modalEdits";
import { CatalogoPrecoBaseModel, CatalogoPrecoBaseModelGranularidade } from "../../core/models/catalagoPrecoBase";
import { FilterCatalogoPrecoBase, CatalogoPrecoBaseRepositorio } from "../../core/repositories/CatalagoPrecoBaseRepositorio";
import { atualizaTamanhoTabela } from "../../core/utils/funcoes";
import { catalogoPrecoBaseContexto } from "../../core/contexts/catalogoPrecoBaseContexto";
import { useTranslation } from "react-i18next";
import { TipoFormatacao } from "../../core/config/tipoFormatacao";

interface Props {
  search: FilterCatalogoPrecoBase;
  open: boolean;
  loadingFirstTime: boolean;
  setLoadingFirstTime(valor: boolean): void;
}

export function updateHeightTable() {
  setTimeout(() => {
    atualizaTamanhoTabela();
  }, 200);
}

const CatalogoPrecoBaseTable: React.FC<Props> = (props) => {
  const { lista, carregando } = catalogoPrecoBaseContexto();
  const [carregandoPrimeiraVez, setCarregandoPrimeiraVez] = React.useState(true);
  const [lastTimeModel, setLastTimeModel] = React.useState<string>();
  const [modalBuyers, setModalBuyers] = React.useState<CatalogoPrecoBaseModel>();
  const [modalEditados, setModalEditados] = React.useState<CatalogoPrecoBaseModel>();
  const { t } = useTranslation();
  const { mostrarToast } = useToast();

  function title(item: any) {
    return CatalogoPrecoBaseModelGranularidade.INTERFACE[item.num_level] + ": " + item[CatalogoPrecoBaseModelGranularidade.DESCRICAO[item.num_level]]?.toString();
  }

  React.useEffect(() => {
    updateHeightTable();
  }, [props.open]);

  React.useEffect(() => {
    if (carregando && carregandoPrimeiraVez) {
      setCarregandoPrimeiraVez(false);
    }
  }, [carregando]);

  React.useEffect(() => {
    new CatalogoPrecoBaseRepositorio()
      .getModelLastTime(props.search.year_week)
      .then((res) => {
        if (res) {
          setLastTimeModel(res);
        }
      })
      .catch(() => {
     mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
      });
  }, [props.search.year_week]);

  return (
    <div id="tabela">
      <Table className="rounded " hover bordered responsive size="sm">
        <thead>
          <tr className="table-header">
            <TableRow classNameTh="width-200 granularidade">Granularidade</TableRow>
            <TableRow classNameTh="width-70">Estoque Disponível</TableRow>
            <TableRow classNameTh="width-70">Compra em Aberto</TableRow>

            <TableRow classNameTh="width-90">Tendência de Demanda</TableRow>
            <TableRow classNameTh="width-90">Tendência de Preço</TableRow>
            {/* <TableRow classNameTh="width-70">Margem <br /> Tendência de Preço</TableRow> */}

            <TableRow classNameTh="width-70">Volume Vendido</TableRow>
            <TableRow classNameTh="width-70">Preço PMZ</TableRow>

            <TableRow classNameTh="width-84">Preço do Concorrente</TableRow>
            <TableRow classNameTh="width-70">Preço Tabela Atual</TableRow>
            <TableRow classNameTh="width-70">
              {/* Δ Preço <br /> Tabela X PMZ */}
              Margem <br /> Preço Tabela
            </TableRow>
            <TableRow classNameTh="width-70">Demanda IA (S+1)</TableRow>
            <TableRow classNameTh="width-70">
              Preço Sugerido
              <br /> IA (S+1)
            </TableRow>
            <TableRow classNameTh="width-70">
              {/* Δ Preço <br /> IA X PMZ */}
              Margem <br /> Preço IA
            </TableRow>

            <TableRow classNameTh="width-90">Automatizar?</TableRow>
            <TableRow classNameTh="width-60">Aceitar?</TableRow>
            <TableRow classNameTh="width-120">Editar Preço</TableRow>
            <TableRow classNameTh="width-70">
              {/* Δ Preço Editado X PMZ */}
              Margem <br /> Preço Editado
            </TableRow>
            <TableRow classNameTh="width-60">Δ Preço Editado X IA</TableRow>
            <TableRow classNameTh="width-120">Editar Preço Mínimo</TableRow>
            <TableRow classNameTh="width-70">
              {/* Δ Preço Mínimo X PMZ */}
              Margem <br /> Preço Mínimo
            </TableRow>
          </tr>
        </thead>
        {/* <tbody className="margin-top-60"> */}

        <tbody>
          {carregando ? (
            Array.from({ length: 9 }).map((_, index) => (
              <tr className="height-42" key={"skeleton-tr" + index}>
                {Array.from({ length: 20 }).map((_, index) => (
                  <td key={"skeleton-td" + index}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))
          ) : lista.length == 0 ? (
            <tr>
              <td colSpan={23} className="text-center">
                {carregandoPrimeiraVez ? "Clique em buscar para carregar os dados!" : "Nenhum registro encontrado!"}
              </td>
            </tr>
          ) : (
            lista.map(
              (item, index) =>
                !item.oculta && (
                  <tr
                    key={"tr-" + item.id + index}
                    className={
                      "font-size-" +
                      (12 - item.num_level * 0) +
                      (item.is_all_replicated ? " nivel-yellow-" + (item.num_level + 1) : " nivel-" + (item.num_level + 1))
                    }
                    title={title(item)}
                  >
                    <TableTr
                      data={item}
                      index={index}
                      search={props.search}
                      onModalBuyers={() => {
                        console.log("modal buyers");
                        setModalBuyers(item);
                      }}
                      onModalEdit={() => {
                        console.log("modal edit");
                        setModalEditados(item);
                      }}
                    />
                  </tr>
                )
            )
          )}
          <tr className=" border-0">
            <td colSpan={23} className={"text-start border-0 bs-white "}>
              {lastTimeModel === undefined ? (
                <Spinner size="sm" />
              ) : (
                <>
                  Última atualização do banco de dados:
                  <b> {moment.utc(lastTimeModel).format(TipoFormatacao.FORMATO_DATA_HORA)}</b>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      <ModalBuyers
        show={!!modalBuyers}
        data={modalBuyers}
        search={props.search}
        onHide={() => {
          setModalBuyers(undefined);
        }}
      />
      <ModalEdits
        show={!!modalEditados}
        data={modalEditados}
        search={props.search}
        onHide={() => {
          setModalEditados(undefined);
        }}
      />
    </div>
  );
};

export default CatalogoPrecoBaseTable;
