import { ModeloBase } from "./modeloBase";

export const CatalogoPrecoBaseModelGranularidade = {
  CODIGO: ["branch_code", "department_code", "category_code", "subcategory_code", "mesorregion_code", "product_code"],
  DESCRICAO: [
    "branch_description",
    "department_description",
    "category_description",
    "subcategory_description",
    "mesorregion_description",
    "product_description",
  ],
  INTERFACE: ["Filial", "Departamento", "Categoria", "SubCategoria", "Região", "Produto"],
};

export class CatalogoPrecoBaseModel extends ModeloBase {
  id: number = 0;
  branch_code: number = 0;
  branch_description: string = "";
  branch_type: string = "";
  department_code: number = 0;
  department_description: string = "";
  mesorregion_code: number = 0;
  mesorregion_description: string = "";
  section_code: number = 0;
  section_description: string = "";
  category_code: number = 0;
  category_description: string = "";
  subcategory_code: string = "";
  subcategory_description: string = "";
  subcategory_status: string = "";
  product_code: number = 0;
  product_description: string = "";
  year_week: string = "";
  price_table: number = 0;
  demand_forecast: number = 0;
  new_price_optimizer: number = 0;
  price_zero_margin: number = 0;
  amount_sold: number = 0;
  stock_available: number = 0;
  stock_managed: number = 0;
  wght_avg_price: number = 0;
  total_pndg_qtt: number = 0;
  price_trend: number = 0;
  demand_trend: number = 0;
  wavg_price_lag1_trend: number = 0;
  wavg_price_lag2_trend: number = 0;
  wavg_price_lag3_trend: number = 0;
  amount_sold_lag1_trend: number = 0;
  amount_sold_lag2_trend: number = 0;
  amount_sold_lag3_trend: number = 0;
  competitor_mode_price: number = 0;
  competitor_median_price: number = 0;
  is_all_replicated: boolean = false;
  margin_executed_price: number = 0;

  price_edit?: number;
  price_minimum?: number;
  is_accepted: boolean = false;
  is_automated: boolean = false;
  is_mandatory: boolean = false;
  // is_salved: boolean = false;

  price_save?: number;
  edit_price_save?: number;
  minimum_price_save?: number;

  flg_aberto: boolean = false;
  edit: boolean = false;
  num_level: number = 0;
  quantity: number = 0;
  oculta: boolean = false;
  sons: number = 0;
  flg_salvando: boolean = false;
}
