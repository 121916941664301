import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { ModeloCenario, atualizarAtributosCenario } from '../../core/models/cenario';
import { formatarPeriodoData } from '../../core/utils/funcoes';
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean;
    cenario: ModeloCenario;
    des_periodo_analisado?: string;
    onHide: () => void;
    salvarCenario: () => void;
    editarNome: () => void;
}

export const ModalCenarioSalvar: React.FC<Props> = (props) => {
    const { t } = useTranslation();

    return (
        <Modal onHide={props.onHide} show={props.show} centered >
            <Modal.Header closeButton className="pb-0">
                <Modal.Title className='font-size-18 text-primary'>{t("components.Resumo do Cenário")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
                <Row>
                    <Col md={6} className="fw-bold font-size-16 line-height-24" >{t("components.Nome")}: {props.cenario.des_cenario} </Col>
                    <Col md={6} className="text-end pe-0">
                        <Button className="py-0" variant="outline-black" onClick={props.editarNome}> <span className="text-decoration-underline fw-bold">{t("components.Editar")} </span>  &nbsp;<FaEdit /> </Button>
                    </Col>
                    <hr className="my-1" />
                    <Col md={6} className="fw-bold font-size-14 line-height-24">{t("components.Período Analisado")}  </Col>
                    <Col md={6} className="text-end"> {props.des_periodo_analisado}</Col>
                    {/* <Col md={6} className="text-end">  {formatarPeriodoData((props.cenario.num_semana_ano?.toString() || props.cenario.des_ano_mes || ""), !props.cenario.num_semana_ano)}</Col> */}

                    <Col md={6} className="fw-bold font-size-14 line-height-24">{t("components.Quantidade de filtros")} </Col>
                    <Col md={6} className="text-end"> {props.cenario.num_filtro}</Col>

                    <Col md={6} className="fw-bold font-size-14 line-height-24">{t("components.Produtos Capitão Alterados")}</Col>
                    <Col md={6} className="text-end">{props.cenario.num_de_produtos_alterado_capitao}  </Col>
                    <Col md={6} className="fw-bold font-size-14 line-height-24">{t("components.Produtos Relativos Alterados")} </Col>
                    <Col md={6} className="text-end"> {props.cenario.num_de_produtos_alterado_relativo} </Col>
                </Row>
                <hr className="my-1" />
            </Modal.Body>
            <Modal.Footer className="pt-0">
                <Button variant="secondary" onClick={props.onHide}>
                    {t("components.Fechar")}
                </Button>
                <Button variant="primary" onClick={props.salvarCenario}>
                    {t("components.Salvar cenário")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
