import { createContext, useState, useContext, useEffect } from 'react';
// import Cookies from 'universal-cookie/es6';
import { ModeloCentroDistribuicao, ModeloCentroDistribuicaoGranularidade } from '../models/centroDistribuicao';
import { FiltroCentroDistribuicao, RepositorioCentroDistribuicao } from '../repositories/centroDistrubuicaoRepositorio';
import { ModeloCentroDistribuicaoEdicao } from '../models/centroDistribuicaoEdicao';
import { RepositorioCentroDistribuicaoEdicao } from '../repositories/centroDistribuicaoEdicaoRepositorio';
import { ModeloCenario } from '../models/cenario';
import { useAutenticação } from './auth';
import { RepositorioCentroDistribuicaoImposto } from '../repositories/centroDistrubuicaoImpostoRepositorio';

// O que o contexto irá passar para o outros componentes
interface CentroDistribuicaoContextoInterface {
	lista: ModeloCentroDistribuicao[] | [];
	cenario: ModeloCenario;
	carregando: boolean;
	carregandoFilho: boolean;
	filtroNoContexto: FiltroCentroDistribuicao;
	carregarLista(filtro: FiltroCentroDistribuicao): Promise<ModeloCentroDistribuicao[]>;
	adicionarLista(filtro: FiltroCentroDistribuicao, index: number): Promise<number>;
	removerLista(index: number): void;
	salvarEditados(): Promise<number>;
	limparLista(): void;
	atualizarInterface(): void;
	atualizarUmaLinha(linha: ModeloCentroDistribuicao, index: number): void;
	atualizarCenario(cenario: ModeloCenario): void;
	salvarLinhaEditada(produto: ModeloCentroDistribuicao, cod_cenario: number, index: number, flg_nivel_relacionado: boolean): Promise<void>;
	atualizarFiltroContexto(filtro: FiltroCentroDistribuicao): void;
	setCarregando(carregando: boolean): void;

}

// Como o objeto de contexto vai iniciar
const CentroDistribuicaoContexto = createContext<CentroDistribuicaoContextoInterface>({} as CentroDistribuicaoContextoInterface);

type Props = {
	children?: JSX.Element;
};

// Componente de contexto que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const CentroDistribuicaoProvider: React.FC<Props> = ({ children }) => {
	const [carregando, setCarregando] = useState(false);
	const [carregandoFilho, setCarregandoFilho] = useState(false);

	var [lista, setLista] = useState<ModeloCentroDistribuicao[]>([]);
	const [filtroNoContexto, setFiltroContexto] = useState(new FiltroCentroDistribuicao());
	const [cenario, setCenario] = useState<ModeloCenario>(new ModeloCenario());
	const [contador, setContador] = useState(0);

	const { usuario } = useAutenticação();

	const _centroDistribuicao = new RepositorioCentroDistribuicao();
	const _centroDistribuicaoEdicao = new RepositorioCentroDistribuicaoEdicao();

	useEffect(() => {
		(async () => {
		})();
		// eslint-disable-next-line
	}, []);

	function atualizarUmaLinha(linhaNova: ModeloCentroDistribuicao, index: number) {
		lista[index] = linhaNova;
		atualizaLista(lista);
	}

	async function carregarLista(filtro: FiltroCentroDistribuicao) {

		const _contador = contador + 1;
		setContador(_contador)
		setCarregando(true);
		const centroDistribuicaoRetorno = await _centroDistribuicao.getFilter(filtro, "_post")

		if (contador === _contador - 1) {
			//para abrir quando carrega trocar aberto para true
			if (filtro.num_nivel == 0) {
				centroDistribuicaoRetorno.map(x => { x.flg_aberto = false; x.num_nivel = 0; return x; });
			}

			const lista = [...centroDistribuicaoRetorno]
			atualizaLista(lista);
			setCarregando(false);

			await carregarDados(filtro)

			return [...centroDistribuicaoRetorno]
		} else {
			return [...lista]
		}


	}

	// funcao para carregar todos os elementos que esta relacionado com a linha
	async function carregarNivelRelacionado(index: number) {
		const produto = lista[index]
		for (let i = 0; i < lista.length; i++) {
			if (i != index) {
				var igual = true;
				const produtoAux = lista[i];
				for (let j = 0; j <= produtoAux.num_nivel && igual; j++) {
					if ((produtoAux as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[j]] != (produto as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[j]]) {
						igual = false
					}
				}
				if (igual) {
					produtoAux.num_fator_decisao_elasticidade = undefined
					produtoAux.num_fator_decisao_giro = undefined
					produtoAux.num_fator_decisao_sazonalidade = undefined
					produtoAux.num_fator_decisao_tendencia = undefined
					produtoAux.flg_elasticidade = undefined
					produtoAux.flg_carregando_preco = true;
					produtoAux.flg_carregando_volume = true;
					produtoAux.editado_usuario = ""
				}

			}
		}

		if (produto.flg_aberto) {
			const nivel = lista[index].num_nivel;
			var indexFinal = index + 1
			while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
				const produtoAux = lista[indexFinal];
				produtoAux.num_fator_decisao_elasticidade = undefined
				produtoAux.num_fator_decisao_giro = undefined
				produtoAux.num_fator_decisao_sazonalidade = undefined
				produtoAux.num_fator_decisao_tendencia = undefined
				produtoAux.flg_elasticidade = undefined
				produtoAux.flg_carregando_preco = true;
				produtoAux.flg_carregando_volume = true;
				produtoAux.num_preco_imposto = undefined;
				indexFinal++
			}
		}
		atualizarInterface()

		for (let i = 0; i < lista.length; i++) {
			const produtoAux = lista[i];

			if ((produtoAux.flg_carregando_preco || produtoAux.flg_carregando_volume)) {
				const filtroAux = { ...filtroNoContexto }

				for (let j = 0; j <= produtoAux.num_nivel; j++) {
					(filtroAux as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[j]] = (produtoAux as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[j]]
				}

				filtroAux.num_nivel = produtoAux.num_nivel
				filtroAux.cod_cenario = -1;
				const retorno = await _centroDistribuicao.getFilter(filtroAux, "_modelo_sugerido_mkt")

				if (retorno.length) {
					produtoAux.num_volume_mkt_novo = retorno[0].num_volume_mkt_novo;
					produtoAux.num_preco_mkt_novo = retorno[0].num_preco_mkt_novo;
					produtoAux.num_fator_decisao_elasticidade = retorno[0].num_fator_decisao_elasticidade || 0;
					produtoAux.num_fator_decisao_giro = retorno[0].num_fator_decisao_giro || 0;
					produtoAux.num_fator_decisao_sazonalidade = retorno[0].num_fator_decisao_sazonalidade || 0;
					produtoAux.num_fator_decisao_tendencia = retorno[0].num_fator_decisao_tendencia || 0;

					produtoAux.flg_carregando_preco = false;
					produtoAux.flg_carregando_volume = false;


					produtoAux.num_volume_mkt_novo = retorno[0].num_volume_mkt_novo || 0;
					produtoAux.num_preco_mkt_novo = retorno[0].num_preco_mkt_novo || 0;
					produtoAux.num_preco = retorno[0].num_preco_mkt_novo || 0;
					produtoAux.num_volume = retorno[0].num_volume_mkt_novo || 0;
					produtoAux.flg_elasticidade = retorno[0].flg_elasticidade;
					produtoAux.num_preco_imposto = undefined;
				
				} else {
					produtoAux.flg_carregando_preco = false;
					produtoAux.flg_carregando_volume = false;
					produtoAux.num_fator_decisao_elasticidade = 0;
					produtoAux.num_fator_decisao_giro = 0;
					produtoAux.num_fator_decisao_sazonalidade = 0;
					produtoAux.num_fator_decisao_tendencia = 0;
				}
				atualizarInterface()
			} else {
				produtoAux.flg_carregando_preco = false;
				produtoAux.flg_carregando_volume = false;
				produtoAux.num_fator_decisao_elasticidade = 0;
				produtoAux.num_fator_decisao_giro = 0;
				produtoAux.num_fator_decisao_sazonalidade = 0;
				produtoAux.num_fator_decisao_tendencia = 0;
				atualizarInterface()
			}
		}
	}
	async function salvarLinhaEditada(produto: ModeloCentroDistribuicao, cod_cenario: number, index: number, flg_nivel_relacionado: boolean) {
		if ((produto.num_preco_mkt_novo != produto.num_preco_mkt_original) || (produto.num_volume_mkt_novo != produto.num_volume_mkt_original)) {
			const item = new ModeloCentroDistribuicaoEdicao();
			item.cod_usuario = usuario?.cod_usuario;
			item.cod_empresa = produto.cod_empresa;
			item.cod_gerencia = produto.cod_gerencia;
			item.cod_bandeira = produto.cod_bandeira;
			item.cod_cluster = produto.cod_cluster;
			item.cod_divisao_comercial = produto.cod_divisao_comercial;

			item.cod_linha_produto_capitao = produto.cod_linha_produto_capitao;
			item.cod_familia_subgrupo_produto_capitao = produto.cod_familia_subgrupo_produto_capitao;
			item.cod_produto_capitao = produto.cod_produto_capitao;

			// item.cod_linha = produto.cod_linha_produto_capitao;
			item.cod_produto = produto.cod_produto || filtroNoContexto.cod_produto;
			item.cod_produto_capitao = produto.cod_produto_capitao;
			if (filtroNoContexto.flg_ano_mes) {
				item.des_ano_mes = filtroNoContexto.des_periodo_analisado;
			} else {
				item.num_semana_ano = filtroNoContexto.des_periodo_analisado;
			}

			//só considedera a flg_elasticidade quando é capitao
			item.flg_elasticidade = produto.num_nivel != 3 ? true : !!produto.flg_elasticidade;
			item.cod_linha_produto_capitao = produto.cod_linha_produto_capitao;
			item.cod_familia_subgrupo_produto_capitao = produto.cod_familia_subgrupo_produto_capitao;
			// item.cod_cenario = cod_cenario || filtroNoContexto.cod_cenario;
			item.cod_cenario = cod_cenario;

			if (produto.editado_usuario == "preco") {
				item.num_preco_mkt_novo = produto.num_preco_mkt_novo;
				item.num_volume_mkt_novo = undefined
				if (flg_nivel_relacionado) {
					produto.flg_carregando_preco = true;
					produto.num_preco = produto.num_preco_mkt_novo;
				}
			} else {
				item.num_volume_mkt_novo = produto.num_volume_mkt_novo;
				item.num_preco_mkt_novo = undefined
				if (flg_nivel_relacionado) {
					produto.flg_carregando_volume = true;
					produto.num_volume = produto.num_volume_mkt_novo;
				}
			}
			atualizaLista(lista)
			await _centroDistribuicaoEdicao.salvar(item);
			if (flg_nivel_relacionado) {
				await carregarNivelRelacionado(index)
			}
			carregarImposto(filtroNoContexto)
		}
	}

	async function salvarEditados() {
		var totalSalvos = 0;
		// para filtrar e trazer só produto

		for (let index = 0; index < lista.length; index++) {
			const produto = lista[index];

			if (produto.num_nivel > 2 && produto.editado_usuario && ((produto.num_preco_mkt_novo != produto.num_preco_mkt_original) || (produto.num_volume_mkt_novo != produto.num_volume_mkt_original))) {
				if (produto.flg_elasticidade == false) {
					const produto1 = { ...produto }
					const produto2 = { ...produto }
					produto.editado_usuario
					produto1.editado_usuario = "preco"
					produto2.editado_usuario = "volume"
					await salvarLinhaEditada(produto1, cenario.cod_cenario, index, false)
					await salvarLinhaEditada(produto2, cenario.cod_cenario, index, false)
				} else {
					await salvarLinhaEditada(produto, cenario.cod_cenario, index, false)
				}
				produto.flg_carregando_preco = false
				produto.flg_carregando_volume = false
				totalSalvos++;
			}
		}
		// atualizaLista
		return totalSalvos
	}

	function removerLista(index: number) {
		lista[index].flg_aberto = false;
		const nivel = lista[index].num_nivel;
		var indexFinal = index + 1

		while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
			const element = lista[indexFinal];
			element.oculta = true
			element.flg_aberto = false
			indexFinal++
		}

		atualizaLista(lista)
	}

	async function adicionarLista(filtroGeral: FiltroCentroDistribuicao, index: number) {

		try {
			const linha = lista[index];
			setCarregandoFilho(true)

			linha.flg_aberto = true;

			if (linha.filhos > 0) {
				const nivel = lista[index].num_nivel;
				var indexFinal = index + 1

				while (indexFinal < lista.length && nivel < lista[indexFinal].num_nivel) {
					const element = lista[indexFinal];
					// para mostrar somente os que estão um nível abaixo
					element.oculta = (nivel + 1) != lista[indexFinal].num_nivel
					indexFinal++
				}
				atualizaLista(lista)

			} else {
				const filtro = { ...filtroGeral }
				filtro.num_nivel = linha.num_nivel + 1

				for (let index = 0; index < filtro.num_nivel; index++) {
					(filtro as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[index]] = (linha as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[index]]
				}
				const response = await _centroDistribuicao.getFilter(filtro, "_post")
				response.map((item) => {
					item.num_nivel = linha.num_nivel + 1;
				});
				linha.filhos = response.length;
				lista.splice(index + 1, 0, ...response);

				await carregarDados(filtro)

				atualizaLista(lista)
			}
			setCarregandoFilho(false)
			return linha.filhos;
		} catch (error) {
			setCarregandoFilho(false)
			return 0;
		}

	}

	function filtroAuxAtributo(element: any, attr: string) {
		return lista.filter(x => (x as any)[attr] === undefined).find((x) => {
			var encontrado = true;
			for (let index = 0; index < ModeloCentroDistribuicaoGranularidade.CODIGO.length && encontrado; index++) {
				encontrado = (x as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[index]] == (element as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[index]]
			}
			return encontrado;
		})
	}

	function carregarDados(filtro: FiltroCentroDistribuicao) {
		//se nao tiver nenhuma selecionada, não carrega
		if (lista.length) {
			carregarGvv(filtro)
			carregarEstoque(filtro)
			carregarImposto(filtro)
			carregarTransferencia(filtro)
			carregarModeloSugeridoMkt(filtro)
		}
	}


	// async function carregarModelo(filtro: FiltroCentroDistribuicao) {
	// 	const res = await _centroDistribuicao.getFilter(filtro, "_todos_media")
	// 	res.forEach(element => {
	// 		var item = filtroAuxAtributo(element, "num_volSume_ia")
	// 		if (item) {
	// 			// item.num_preco_ia = element.num_preco_ia
	// 			item.num_volume_ia = element.num_volume_ia
	// 			// item.num_volume_mkt_novo = element.num_volume_ia
	// 		}
	// 	})
	// 	lista.filter(x => x.num_volume_ia === undefined && x.num_nivel == filtro.num_nivel).map(x => {
	// 		// x.num_preco_ia = 0
	// 		x.num_volume_ia = 0
	// 		return x
	// 	})
	// 	atualizarInterface()
	// }

	async function carregarModeloSugeridoMkt(filtro: FiltroCentroDistribuicao) {
		const res = await _centroDistribuicao.getFilter(filtro, "_modelo_sugerido_mkt")

		res.forEach(element => {
			var item = filtroAuxAtributo(element, "num_volume_ia")
			if (item) {
				item.num_volume_ia = element.num_volume_ia
				item.num_preco_ia = element.num_preco_ia
				item.num_volume_mkt_novo = element.num_volume_mkt_novo || 0
				item.num_preco_mkt_novo = element.num_preco_mkt_novo || 0
				item.num_volume_mkt_original = element.num_volume_mkt_novo || 0
				item.num_preco_mkt_original = element.num_preco_mkt_novo || 0

				item.num_fator_decisao_elasticidade = element.num_fator_decisao_elasticidade
				item.num_fator_decisao_giro = element.num_fator_decisao_giro
				item.num_fator_decisao_sazonalidade = element.num_fator_decisao_sazonalidade
				item.num_fator_decisao_tendencia = element.num_fator_decisao_tendencia
				item.flg_elasticidade = element.flg_elasticidade


			}
		})

		lista.filter(x => x.num_volume_ia === undefined && x.num_nivel == filtro.num_nivel).map(x => {
			x.num_volume_ia = 0
			x.num_preco_ia = 0
			x.num_volume_mkt_novo = 0
			x.num_preco_mkt_novo = 0
			x.num_volume_mkt_original = 0
			x.num_preco_mkt_original = 0
			x.num_fator_decisao_elasticidade = 0
			x.num_fator_decisao_giro = 0
			x.num_fator_decisao_sazonalidade = 0
			x.num_fator_decisao_tendencia = 0
			x.flg_elasticidade = true
			return x
		})

		atualizarInterface()
	}

	async function carregarTransferencia(filtro: FiltroCentroDistribuicao) {
		const res = await _centroDistribuicao.getFilter(filtro, "_transferencia")

		res.forEach(element => {
			var item = filtroAuxAtributo(element, "num_volume_reposicao")
			if (item) {
				item.num_volume_reposicao = element.num_volume_reposicao
				item.num_preco_reposicao = element.num_preco_reposicao
				// item.num_imposto_perc = element.num_imposto_perc || 0

				item.num_transferencia_preco_mkt_novo = element.num_transferencia_preco_mkt_novo
			}
		})

		lista.filter(x => x.num_volume_reposicao === undefined && x.num_nivel == filtro.num_nivel).map(x => {
			x.num_volume_reposicao = 0
			x.num_preco_reposicao = 0
			// x.num_imposto_perc = 0
			x.num_transferencia_preco_mkt_novo = 0
			return x
		})

		atualizarInterface()
	}

	async function carregarGvv(filtro: FiltroCentroDistribuicao) {
		const res = await _centroDistribuicao.getFilter(filtro, "_gvv")

		res.forEach(element => {
			var item = filtroAuxAtributo(element, "num_gvv")
			if (item) {
				item.num_gvv = element.num_gvv
			}
		})

		lista.filter(x => x.num_gvv === undefined && x.num_nivel == filtro.num_nivel).map(x => {
			x.num_gvv = 0
			return x
		})

		atualizarInterface()
	}

	async function carregarEstoque(filtro: FiltroCentroDistribuicao) {
		const res = await _centroDistribuicao.getFilter(filtro, "_estoque")

		res.forEach(element => {
			var item = filtroAuxAtributo(element, "num_volume_estoque_inicial")

			if (item) {
				// console.log(typeof(item.num_volume_estoque_inicial)=== undefined)
				item.num_volume_estoque_inicial = element.num_volume_estoque_inicial
				item.num_cpv_estoque_inicial = element.num_cpv_estoque_inicial
				item.num_shelf_resfriado_0_30 = element.num_shelf_resfriado_0_30
				item.num_shelf_resfriado_30_40 = element.num_shelf_resfriado_30_40
				item.num_shelf_resfriado_40 = element.num_shelf_resfriado_40
				item.num_shelf_congelado = element.num_shelf_congelado
			}
		})

		lista.filter(x => x.num_volume_estoque_inicial === undefined && x.num_nivel == filtro.num_nivel).map(x => {
			x.num_volume_estoque_inicial = 0
			x.num_cpv_estoque_inicial = 0
			x.num_shelf_resfriado_0_30 = 0
			x.num_shelf_resfriado_30_40 = 0
			x.num_shelf_resfriado_40 = 0
			x.num_shelf_congelado = 0
			return x
		})

		atualizarInterface()
	}

	// async function carregarImposto(filtro: FiltroCentroDistribuicao) {
	// 	const res = await new RepositorioCentroDistribuicaoImposto().getFilter(filtro)

	// 	res.forEach(element => {
	// 		const item = filtroAuxAtributo(element, "num_preco_imposto")
	// 		if (item) {
	// 			item.num_preco_imposto = element.num_preco_imposto
	// 		}
	// 	})

	// 	lista.filter(x => x.num_preco_imposto === undefined && x.num_nivel == filtro.num_nivel).map(x => {
	// 		x.num_preco_imposto = 0
	// 		return x
	// 	})

	// 	atualizarInterface()
	// }

	//retorna uma lista de atributos unicos
	function buscarPorNivelAux(itens: any[], atributo: string) {
		const uniqueValues = Array.from(new Set(itens.filter(x => x[atributo]).map(x => x[atributo])));
		return uniqueValues.toString();
	}

	function buscarPorNivel(nivel: number, filtro: FiltroCentroDistribuicao) {
		// para carregar somente os itens da lista que esta sem carregar 

		const itens = lista.filter(x => x.num_nivel === nivel && x.num_preco_imposto === undefined)

		if (itens.length > 0) {

			var filtroAux = { ... new FiltroCentroDistribuicao(), ...filtro }
			for (let index = 0; index <= nivel; index++) {
				(filtroAux as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[index]] = buscarPorNivelAux(itens, ModeloCentroDistribuicaoGranularidade.CODIGO[index])
			}

			filtroAux.num_nivel = nivel;
			return filtroAux;
		} else {
			return null;
		}
	}

	async function carregarImposto(filtro: FiltroCentroDistribuicao) {
		try {
			const _repositorio = new RepositorioCentroDistribuicaoImposto();
			//buscar para todos os niveis
			for (let index = 0; index < ModeloCentroDistribuicaoGranularidade.CODIGO.length; index++) {
				// verifica se tem alguma linha daquele nivel
				if (lista.filter(x => x.num_nivel === index).length > 0) {

					const filtroAux = buscarPorNivel(index, filtro)

					if (filtroAux) {
						// filtroAux.des_periodo_analisado = filtro.des_periodo_analisado
						const res = await _repositorio.getFilter(filtroAux)

						res.forEach(element => {
							const item = lista.find((x) => {
								var encontrado = x.num_nivel == index;
								for (let index = 0; index < ModeloCentroDistribuicaoGranularidade.CODIGO.length && encontrado; index++) {
									encontrado = (x as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[index]] == (element as any)[ModeloCentroDistribuicaoGranularidade.CODIGO[index]]
								}
								return encontrado;
							})

							if (item) {
								item.num_preco_imposto = element.num_preco_imposto
							}
						});
						lista.filter(x => x.num_nivel === index && x.num_preco_imposto === undefined).map(x => x.num_preco_imposto = 0)
					}
				}
				atualizarInterface()
			}
		} catch (error) {
			lista.map(x => x.num_preco_imposto = 0)
			atualizarInterface()
			return Promise.reject(error)
		}
	}

	function limparLista() {
		atualizaLista([])
	}

	function atualizarInterface() {
		setLista([...lista])
	}

	function atualizaLista(nova: ModeloCentroDistribuicao[]) {
		setLista(nova)
		lista = nova;
	}

	function atualizarCenario(cenario: ModeloCenario) {
		setCenario(cenario)
	}

	function atualizarFiltroContexto(filtro: FiltroCentroDistribuicao) {
		setFiltroContexto({ ...filtro })
	}

	return (
		// eslint-disable-next-line
		<CentroDistribuicaoContexto.Provider
			value={{
				lista,
				carregando,
				carregandoFilho,
				cenario,
				filtroNoContexto,
				carregarLista,
				adicionarLista,
				removerLista,
				salvarEditados,
				limparLista,
				atualizarInterface,
				atualizarUmaLinha,
				atualizarCenario,
				salvarLinhaEditada,
				atualizarFiltroContexto,
				setCarregando
			}}
		>
			{children}
		</CentroDistribuicaoContexto.Provider>
	);
};

export function centroDistribuicaoContext() {
	const contexto = useContext(CentroDistribuicaoContexto);

	return contexto;
}
