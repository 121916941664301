import * as React from "react";
import { MultiSelect, MultiSelectFilterEvent, MultiSelectProps } from "primereact/multiselect"
import { Dropdown } from 'primereact/dropdown';
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./index.scss";
import { FaFilter } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface Props {
  id?: string;
  titulo: string;
  options: any[];
  disabled?: boolean;
  className?: string;
  panelClassName?: string;
  value: any;
  optionLabel: string;
  optionValue: string;
  multiple: boolean;
  icon?: Element;
  logicaFiltroCodigo?: boolean;
  checkbox?: boolean;
  obrigatoriedade?: boolean;
  alterarCheckbox?: (value: boolean) => void;
  onChange: (value: string) => void;
  clear?: () => void;
}

const SelecionarBusca: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [valorSelecionado, setValorSelecionado] = React.useState<string[]>([])
  const [filtroCodigo, setFiltroCodigo] = React.useState(false);
  // const [filteredOptions, setFilteredOptions] = React.useState<any[]>(props.options);
  const [listaOrdenada, setListaOrdenada] = React.useState<any[]>(props.options);

  React.useEffect(() => {
    if (!props.value) {
      setValorSelecionado([])
    } else {
      if (valorSelecionado.toString() != props.value.toString()) {
        setValorSelecionado(props.value.split(",").map((x: any) => {
          var aux = props.options.find(y => y[props.optionValue].toString() == x)
          if (aux) {
            return aux[props.optionValue]
          }
        }))
      }
    }

  }, [props.value])

  React.useEffect(() => {
    setListaOrdenada(props.options)
  }, [props.options, filtroCodigo])

  function atualizaLista() {
    // Mova os itens selecionados para o início da lista
    const selectedItemsFirst = valorSelecionado.map((selectedItem) =>
      props.options.find((item) => item[props.optionValue] === selectedItem)
    );

    const unselectedItems = props.options.filter(
      (item) => !valorSelecionado.includes(item[props.optionValue])
    );

    setListaOrdenada([...selectedItemsFirst, ...unselectedItems]);
  };



  function mudarValor(e: any) {
    if (e.value) {
      props.onChange(e.value.toString())
      setValorSelecionado(e.value)
    } else {
      props.onChange("")
      setValorSelecionado([])
    }
  }


  return (
    <Form.Group className={"selecionar-busca mb-2 d-block position-relative " + (props.className || "") + " " + (props.icon && " icon-select")} >
      {
        props.titulo &&
        <>
          <div className="vertical-align ">
            <Form.Label className="font-size-14 line-height-24 relative mb-0">
              {props.titulo}
            </Form.Label>
            {props.logicaFiltroCodigo &&
              <Button onClick={() => { setFiltroCodigo(!filtroCodigo) }} variant={filtroCodigo ? "primary" : "outline-primary"} size="sm" className="p-0 m-0  ms-1 border-0 width-24 rounded-circle"><FaFilter /></Button>
            }
            {props.alterarCheckbox &&
              <OverlayTrigger
                overlay={
                  <Tooltip id="button-tooltip" >
                    <div className="text-start">
                      {t("components.Desconsiderar recolocações de Pedidos")}<br />
                      {t("components.Desconsiderar negociação assosciada a data avançada")}
                    </div>
                  </Tooltip>
                }>
                <Form.Check
                  type="checkbox"
                  className="p-0 m-0 ms-1 border-0 width-24"
                  checked={props.checkbox}
                  onChange={(event) => {
                    if (props.alterarCheckbox)
                      props.alterarCheckbox(event.currentTarget.checked)
                  }}
                />
              </OverlayTrigger>
            }
          </div>
        </>
      }
      {
        !props.multiple ? (
          <Dropdown
            id={props.id || props.titulo}
            className={props.className}
            disabled={props.disabled}
            placeholder={t("components.Selecionar")}
            optionLabel={props.optionLabel}
            optionValue={props.optionValue}
            value={props.value}
            options={props.options}
            onChange={e => props.onChange(e.value.toString())}
            emptyMessage={t("components.Nenhum resultado.")}
          />
        ) : (
          <> {props.options.length < 5000 ?
            <MultiSelect
              id={props.id || props.titulo}
              className={props.className}
              disabled={props.disabled}
              placeholder={props.logicaFiltroCodigo && filtroCodigo ? t("components.Selecionar por Código") : t("components.Selecionar")}
              filterPlaceholder={props.logicaFiltroCodigo ? filtroCodigo ? t("components.Digite aqui o código") : t("components.Digite aqui a descrição") : t("components.Digite aqui para filtrar")}
              optionLabel={props.optionLabel}
              optionValue={props.optionValue}
              value={valorSelecionado}
              options={listaOrdenada}
              onChange={mudarValor}
              onHide={atualizaLista}
              filter
              filterMatchMode={props.logicaFiltroCodigo && filtroCodigo ? "equals" : "contains"}
              filterBy={props.logicaFiltroCodigo && filtroCodigo ? props.optionValue : props.optionLabel}
              resetFilterOnHide
              dropdownIcon={valorSelecionado.length && <span className="p-dropdown-icon">{valorSelecionado?.length}</span>}
              maxSelectedLabels={1}
              selectedItemsLabel={valorSelecionado.length == 1 ? t("components.Selecionado") : t("components.Selecionados")}
              emptyFilterMessage={t("components.Nenhum resultado encontrado.")}
              emptyMessage={t("components.Nenhum resultado encontrado.")}
              panelClassName={props.panelClassName || ""}
              showClear
            /> :
            <MultiSelect
              id={props.id || props.titulo}
              value={valorSelecionado}
              options={listaOrdenada}
              optionLabel={props.optionLabel}
              optionValue={props.optionValue}
              filterPlaceholder={props.logicaFiltroCodigo ? filtroCodigo ? t("components.Digite aqui o código") : t("components.Digite aqui a descrição") : t("components.Digite aqui para filtrar")}
              filter
              filterMatchMode={props.logicaFiltroCodigo && filtroCodigo ? "equals" : "contains"}
              filterBy={props.logicaFiltroCodigo && filtroCodigo ? props.optionValue : props.optionLabel}
              onChange={mudarValor}
              onHide={atualizaLista}
              emptyFilterMessage={t("components.Nenhum resultado encontrado.")}
              emptyMessage={t("components.Nenhum resultado encontrado.")}
              dropdownIcon={valorSelecionado.length && <span className="p-dropdown-icon">{valorSelecionado?.length}</span>}
              selectedItemsLabel={valorSelecionado.length == 1 ? t("components.Selecionado") : t("components.Selecionados")}
              virtualScrollerOptions={{ itemSize: 43 }}
              maxSelectedLabels={1}
              placeholder={props.logicaFiltroCodigo && filtroCodigo ? t("components.Selecionar por Código") : t("components.Selecionar")}
              className={props.className}
              showClear
              panelClassName={props.panelClassName || ""}
              showSelectAll={false}
              resetFilterOnHide
            />
          }
          </>
        )
      }
    </Form.Group >
  );
};
export default SelecionarBusca;
