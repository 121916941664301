import { ConstanteGlobal } from "../config/constanteGlobal";
import { FiltroBasico, ModeloBasico } from "../models/basico";
import { ModeloCluster } from "../models/cluster";
import { Repositorio } from "./repositorio";

export class FiltroCluster extends FiltroBasico<ModeloCluster> {}

export class RepositorioCluster extends Repositorio<ModeloCluster> {
  constructor() {
    super(ConstanteGlobal.CLUSTER);
  }
}
