// @flow
import React, { useState, FC } from "react";
import { Row, Col, Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FiltroUsuario } from "../../core/repositories/usuarioRepositorio";
import { FiltroCabecalho } from "../../components/FiltroCabecalho";
import { useTranslation } from "react-i18next";


interface Props {
  filtro: FiltroUsuario;
  atualizarFiltro: (filtro: FiltroUsuario) => void;
  carregar: (filtro: FiltroUsuario) => void;
}
let handleFiltroNameTimeout: string | number | NodeJS.Timeout | undefined;

const Filtro: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [active, setActive] = useState("true");
  const [nomeCompleto, setNomeCompleto] = useState("");
  React.useEffect(() => {

    props.filtro.flg_ativo = active == "true";
    props.carregar(props.filtro);
  }, [active]);

  React.useEffect(() => {
    if (props.filtro.des_nome != nomeCompleto) {
      setNomeCompleto(props.filtro.des_nome)
    }
  }, [props.filtro.des_nome]);


  function handleFiltroName(event: any) {
    props.filtro.des_nome = event.target.value;
    props.carregar(props.filtro);
  }

  return (
    <>
      <div className="stick">
        <FiltroCabecalho
          titulo={t("pages.Usuários")}
          filtro={props.filtro}
          atualizarFiltro={props.atualizarFiltro}
          platasIndustrial={false}
        >
          <Row>

            <Form.Group as={Col} md={10}>
              <Form.Label>{t("pages.Nome")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("pages.Todos")}
                value={nomeCompleto}
                onChange={(e) => {
                  // Limpar o timer existente (se houver)
                  clearTimeout(handleFiltroNameTimeout);
                  setNomeCompleto(e.target.value)
                  // Configurar um novo timer
                  handleFiltroNameTimeout = setTimeout(() => {
                    handleFiltroName(e);
                  }, 1500);
                }}
              />
            </Form.Group>

            <Form.Group as={Col} md={{ span: 2 }}>
              <Form.Label className="font-size-14 form-label">
                {t("pages.Status")}
              </Form.Label>
              <Col md={12}>
                <ToggleButtonGroup value={active} type="radio" name="options2" className="w-100" onChange={(e) => setActive(e)}>
                  <ToggleButton id="tbg-radio-1" variant="outline-primary" value={"true"}>
                    {t("pages.Ativo")}
                  </ToggleButton>
                  <ToggleButton id="tbg-radio-2" variant="outline-primary" value={"false"}>
                    {t("pages.Inativo")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
            </Form.Group>

          </Row>
        </FiltroCabecalho>
      </div>


    </>

  );
};

export default Filtro;
