import { ModeloBase } from './modeloBase';

export class ModeloCenario extends ModeloBase {
  num_total:number =0;
  flg_editar: boolean = true;
  flg_alterado?: boolean = true;

  des_status: string = "";

  cod_cenario: number = -1;
  des_cenario: string = "";

  cod_usuario?: number;
  des_nome?: string;

  des_ano_mes?: string;
  num_semana_ano?: number;
  num_filtro?: number;
  num_de_produtos_alterado_capitao?: number;
  num_de_produtos_alterado_relativo?: number;
  flg_mostrar_resultado: boolean = false;
  flg_finalizado: boolean = false;
  flg_tela_planta_cd: boolean = false;
  flg_ativo: boolean = true;
  dth_insercao?: Date;
  dth_alteracao?: Date;
  des_filtro_empresa?: string;
  des_filtro_canal?: string;
  des_filtro_diretoria?: string;
  des_filtro_gerencia?: string;
  des_filtro_divisao_comercial?: string;
  des_filtro_mercado?: string;
  des_filtro_tipo_produto?: string;
  des_filtro_familia_subgrupo_produto_capitao?: string;
  des_filtro_linha_produto_capitao?: string;
  des_filtro_produto_capitao?: string;
  des_filtro_acabamento?: string;
  des_filtro_temperatura?: string;
  des_filtro_cluster?: string;
  des_filtro_bandeira?: string;
  des_filtro_planta?: string;
  des_filtro_produto?: string;
  des_filtro_uf_destino?: string;
}

export function atualizarAtributosCenario(cenarioAux:ModeloCenario, filtro:any){
  var cenario = {...cenarioAux}
  filtro.des_periodo_analisado = filtro.des_periodo_analisado?.replace(" (atual)", "")
  cenario.des_ano_mes = filtro.flg_ano_mes ? filtro.des_periodo_analisado : undefined;
  cenario.num_semana_ano = !filtro.flg_ano_mes ? Number.parseInt(filtro.des_periodo_analisado || "") : undefined;
  cenario.flg_mostrar_resultado = filtro.flg_mostrar_resultado;
  cenario.flg_tela_planta_cd = true;
  cenario.des_filtro_empresa = filtro.cod_empresa;
  cenario.des_filtro_canal = filtro.cod_canal;
  cenario.des_filtro_diretoria = filtro.cod_diretoria;
  cenario.des_filtro_gerencia = filtro.cod_gerencia;
  cenario.des_filtro_divisao_comercial = filtro.cod_divisao_comercial;
  cenario.des_filtro_mercado = filtro.cod_mercado;
  cenario.des_filtro_tipo_produto = filtro.cod_tipo_produto;
  cenario.des_filtro_familia_subgrupo_produto_capitao = filtro.cod_familia_subgrupo_produto_capitao;
  cenario.des_filtro_linha_produto_capitao = filtro.cod_linha_produto_capitao;
  cenario.des_filtro_produto_capitao = filtro.cod_produto_capitao;
  cenario.des_filtro_acabamento = filtro.cod_acabamento;
  cenario.des_filtro_temperatura = filtro.cod_temperatura;
  cenario.des_filtro_cluster = filtro.cod_cluster;
  cenario.des_filtro_bandeira = filtro.cod_bandeira;
  cenario.des_filtro_produto = filtro.cod_produto;
  cenario.des_filtro_uf_destino = filtro.ini_uf_destino;
  cenario.num_filtro = quantidadeFiltros(cenario);
  cenario.cod_cenario = cenario.cod_cenario || filtro.cod_cenario;

  return cenario
}

export function quantidadeFiltros(cenario:any){
 var count =  Object.keys(cenario).reduce((count, key) => {
   if ((key.includes("des_filtro") ) && cenario[key] ) {
      return count + 1;
    }
    return count;
  }, 0);

  return count+1;
}

