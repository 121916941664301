import { createContext, useState, useContext, useEffect } from "react";

import Cookies from 'universal-cookie';
import { ModeloUsuario } from "../models/usuario";
import { RepositorioUsuario } from "../repositories/usuarioRepositorio";
import auth from "../services/auth";

// O que o contexto irá passar para o outros componentes
interface AutenticaçãoContextoDado {
	signed: boolean;
	usuario: ModeloUsuario | null;
	loading: boolean;
	checkEmail(email: string): Promise<void>;
	signOut(redirect: boolean): void;
	getUsuarioCookie(): ModeloUsuario | null;
	getUsuarioAzure(): Promise<ModeloUsuario | null>;
	getInfoByEmail(email: string): Promise<ModeloUsuario | null>;
	setUsuarioCookie(usuario: any): void;
	clearUsuarioState(): void;
}

// Como o objeto de contexto vai iniciar
const AutenticaçãoContext = createContext<AutenticaçãoContextoDado>({} as AutenticaçãoContextoDado);

type Props = {
	children?: JSX.Element,
};

// Componente de contexto que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const AutenticaçãoProvider: React.FC<Props> = ({ children }) => {
	const [usuario, setUsuarioState] = useState<ModeloUsuario | null>(null);
	const [loading, setLoading] = useState(true);
	const cookies = new Cookies();

	useEffect(() => {

		(async () => {
			//   var manager = cookies.get("@RNauth:layout") as ManagerModel
			//   if (manager) {
			//     document.body.style.setProperty("--primary-color", manager.PrimaryColorHex);
			//     document.body.style.setProperty("--secondary-color", manager.SecondaryColorHex);
			//   }
			const usuarioCookie = getUsuarioCookie();
			if (usuarioCookie) {
				setUsuarioState(usuarioCookie);
			}
			const usuarioAzure = await getUsuarioAzure();
			if (usuarioAzure) {
				const usuarioInfo = await auth.getInfoByEmail(usuarioAzure?.des_email   );
				setUsuarioCookie(usuarioInfo);
			} else {
				clearUsuarioState();
				if (usuarioCookie)
					signOut(false);
			}
			setLoading(false);
		})();
		// eslint-disable-next-line
	}, []);


	function getUsuarioAzure():Promise<ModeloUsuario | null> {
		if (process.env.REACT_APP_BASE_BACK) {
			return new Promise((resolve) => resolve((getUsuarioCookie())));
		}else{
			return auth.current();
		}
	}

	async function getInfoByEmail(email: string) {
		const usuario = await auth.getInfoByEmail(email);
		if (usuario && usuario.des_email == cookies.get("@RNauth:email")) {
			cookies.remove("@RNauth:email");
			setUsuarioCookie(usuario);
			return usuario;
		} else {
			return null;
		}
	}


	function getUsuarioCookie() {
		const storagedUsuario = cookies.get("@RNauth:usuario");
		return storagedUsuario ? storagedUsuario : null;
	}
	function setUsuarioCookie(response: ModeloUsuario) {
		if (response) {
			const date = new Date();
			date.setFullYear(date.getUTCFullYear() + 1);
			cookies.set("@RNauth:usuario", response, {
				path: "/",
				httpOnly: false,
			});
			switch (response.cod_idioma) {
				case 1:
					localStorage.setItem('language', 'pt');
					break;
				case 2:
					localStorage.setItem('language', 'en');
			}
			setUsuarioState(response);
		}

	}
	async function checkEmail(email: string) {
		// Requisição a api que vai fazer com que o usuário logue na aplicação
		const response: any = await auth.checkEmail(email);
		if (response) {
			cookies.set("@RNauth:email", email, {
				path: "/",
				httpOnly: false
			});
		}
		// setUsuario(response);
		return response;
	}
	function clearUsuarioState() {
		setUsuarioState(null);
	}
	async function signOut(redirect = true) {
		// Limpa a informação no AsyncStorage
		cookies.remove("@RNauth:usuario");
		cookies.remove("StaticWebAppsAutenticaçãoCookie");
		cookies.remove("@RNauth:layout");
		// cookies.remove("remember_token");
		// cookies.remove("session");
		document.cookie = "";
		// setUsuarioState(null);
		if (redirect) {
			window.location.href = `/.auth/logout?post_logout_redirect_uri=${window.location.origin}/login`;
		}
	}

	return (
		// O estado se o usuário está logado ou não depende se algo dentro do estado "usuario"
		// Ou seja se existir algo dentro do usuarioele irá retornar true
		// eslint-disable-next-line
		<AutenticaçãoContext.Provider
			value={{
				signed: !!usuario,
				usuario,
				loading,
				checkEmail,
				signOut,
				getUsuarioCookie,
				getUsuarioAzure,
				clearUsuarioState,
				getInfoByEmail,
				setUsuarioCookie
			}}
		>
			{children}
		</AutenticaçãoContext.Provider>
	);
};

export function useAutenticação() {
	const context = useContext(AutenticaçãoContext);

	return context;
}
