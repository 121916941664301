import React, { useEffect, useRef, useState } from "react";
import Image from "react-bootstrap/Image";
import spinner from "../../assets/icons/spinner-black-sm.svg";
import { FaArrowDown, FaArrowUp, FaEdit, FaInfo, FaMinusCircle, FaPlusCircle, FaSave } from "react-icons/fa";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

import { NumericFormat } from "react-number-format";
import SwitchItem from "../../components/SwitchItem";
import { useToast } from "../../core/contexts/toast";
import { ModeloPrecoBase, ModeloPrecoBaseGranularidade } from "../../core/models/precoBase";
import { FiltroPrecoBase, RepositorioPrecoBase } from "../../core/repositories/precoBaseRepositorio";
import { formatarDecimal, formatarPorcentagem } from "../../core/utils/funcoes";
import { useTranslation } from "react-i18next";
import { precoBaseContexto } from "../../core/contexts/precoBaseContexto";

interface Props {
  data: ModeloPrecoBase;
  index: number;
  search: FiltroPrecoBase;
  // salvouEdicao(): void;
  onModalBuyers(): void;
}


export const TableTr: React.FC<Props> = (props) => {
  const { mostrarToast } = useToast();
  const { t } = useTranslation();

  const [carregar, setCarregar] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const { carregandoFilho, adicionarLista, removerLista, atualizarInterface, atualizarUmaLinha, salvarLinhaEditada } = precoBaseContexto();
  const [isSalved, setIsSalved] = useState(false);

  useEffect(() => {

  }, []);
  async function carregarDados() {
    try {
      setCarregar(true);
      if (props.data.flg_aberto) {
        removerLista(props.index);
      } else {
        await adicionarLista(props.search, props.index);
      }
      atualizarInterface();
      setCarregar(false);
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
      setCarregar(false);
    }
  }



  function desabilitarCarregamento() {
    // const carregaPos = ["num_volume_ia", "num_preco_ia", "num_volume_mkt_novo", "num_preco_mkt_novo", "num_plano_producao_periodo_atual",  "num_saldo_estoque_inicial"]

    // var plantasCarregando = props.plantas.filter(x => x.selecionada).map((item, index) => {
    //   return (props.linha.plantas == null || !props.linha.plantas.some(x => x.cod_empresa == item.cod_empresa) || props.linha.plantas.find(x => x.cod_empresa == item.cod_empresa)?.carregando)
    // }).some(x => x == true)
    return carregandoFilho

  }

  async function atualizarValor(atributo: string) {
    const valor = prompt(`Por favor, insira um valor(${atributo}):`);
    if (valor) {
      const _centro = new RepositorioPrecoBase();
      props.data.num_ano_semana = props.search.num_ano_semana;
      _centro.atualizarValor(atributo, valor, props.data).then((atualizadas) => {
        if (atualizadas) {
          (props.data as any)[atributo] = valor
          atualizarUmaLinha(props.data, props.index)
        }
        mostrarToast({
          titulo: "Sucesso",
          mensagem: `${atualizadas} linha(s) atualizada(s)`,
          tipo: "success",
        });
      }).catch(err => {
        mostrarToast({
          titulo: "Erro",
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      });
    }
  }

  async function automateChange(checked: boolean, item: ModeloPrecoBase, index: number) {
    console.log("automateChange", checked, item, index);
    try {
      item.flg_automatizado = checked;
      setIsSalved(true);
      if (checked) {
        props.data.flg_aceito = false;
        props.data.num_preco_salvo = props.data.num_preco_sugerido_ia;
        props.data.num_preco_edicao =  props.data.num_preco_sugerido_ia;
      }
      const element = document.getElementById("checkbox" + index);
      if (element) {
        (element as any).checked = false;
      }
      await salvarLinhaEditada(props.data, index);
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    } finally {
      setIsSalved(false);
    }
  }
  function savePriceEdit() {

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(async () => {
      console.log("savePriceEdit", props.data);
      try {
        setIsSalved(true);
        props.data.num_preco_edicao = props.data.num_preco_salvo;
        await salvarLinhaEditada(props.data, props.index);
      } catch (error) {
        mostrarToast({
          titulo: t("pages.Erro!"),
          mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
          tipo: "error",
        });
      } finally {
        setIsSalved(false);
      }
    }, 1000);
  }
  async function isAcceptedChange(checked: boolean, index: number) {
    try {
      console.log("isAcceptedChange", checked, props.data, index);
      setIsSalved(true);
      // props.data.is_salved =true
      if (checked) {
        props.data.flg_aceito = true;
        props.data.flg_automatizado = false;
        props.data.num_preco_salvo = props.data.num_preco_sugerido_ia;
        props.data.num_preco_edicao = props.data.num_preco_sugerido_ia;
      } else {
        props.data.flg_aceito = false;
      }
      await salvarLinhaEditada(props.data, index);
    } catch (error) {
      mostrarToast({
        titulo: t("pages.Erro!"),
        mensagem: t("pages.Aconteceu algo inesperado, por favor tente novamente mais tarde!"),
        tipo: "error",
      });
    } finally {
      setIsSalved(false);
    }


  }
  function CalcPercent(item1?: any, item2?: any) {
    if (item1 && item2) {
      item1 = Number.parseFloat(item1);
      item2 = Number.parseFloat(item2);
      return (item1 - item2) / item2;
    } else {
      return 0;
    }
  }

  function carregandoValor(valor: any, money: boolean = false, atributo = "") {
    if (valor === undefined) {
      return <Spinner size="sm" />
    }
    return <>

      {typeof valor === 'number' ? formatarDecimal(valor, money) : valor}
      {(props.data.num_nivel == 5 && localStorage.getItem("editando") && atributo)
        && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>}
    </>
  }



  function EditarTexto(valor: number, atributo = "") {

    return <>{valor}
      {(localStorage.getItem("editando") && atributo)
        && <label onClick={() => { atualizarValor(atributo) }} className="cursor-pointer">&nbsp;<FaEdit /></label>}
    </>
  }


  function copyText() {

    var label = ""
    for (let i = 0; i <= props.data.num_nivel; i++) {
      label += `${ModeloPrecoBaseGranularidade.INTERFACE[i]?.toString()}:(${(props.data as any)[ModeloPrecoBaseGranularidade.CODIGO[i]]?.toString()}) - ${(props.data as any)[ModeloPrecoBaseGranularidade.DESCRICAO[i]]?.toString()}\n`;
    }
    label += `\n AND ${"num_ano_semana = " + props.search.num_ano_semana + ""} \n`
    for (let i = 0; i <= props.data.num_nivel; i++) {
      label += `AND ${ModeloPrecoBaseGranularidade.CODIGO[i]?.toString()} = ${(props.data as any)[ModeloPrecoBaseGranularidade.CODIGO[i]]?.toString()}\n`;
    }
    const inputTemporario = document.createElement("textarea");
    inputTemporario.value = label;
    document.body.appendChild(inputTemporario);
    inputTemporario.select();
    document.execCommand("copy");
    document.body.removeChild(inputTemporario);

  }
  function varicaoPreco(preco1?: number, preco2?: number) {
    if (!preco1 || !preco2) {
      return "-"
    }
    var a = CalcPercent(preco1, preco2)
    if (a > 0) {
      return <>
        <span style={{ color: "green" }}>{formatarPorcentagem(a)}</span>
        <FaArrowUp color="green" />
      </>
        ;
    } else if (a < 0) {
      return <>
        <span style={{ color: "red" }}>{formatarPorcentagem(a)}</span>
        <FaArrowDown color="red" />
      </>
    } else {
      return <span>{formatarPorcentagem(a)}</span>;
    }

  }

  return (
    <>
      {/* Granularidade */}
      <td width={200} className={(props.data.flg_editado ? "is-edit " : "") + "  text-start granularidade-nivel" + (" nivel-" + (props.data.num_nivel + 1))}>
        <div className="drilldown ">
          <div
            className="text-end"
            style={{
              marginLeft: `${20 +
                (props.data.num_nivel == ModeloPrecoBaseGranularidade.DESCRICAO.length - 1 && props.data.quantity <= 1 ? props.data.num_nivel - 1 : props.data.num_nivel) * 15}px`,
            }}
          >
            {/* {(props.data.num_nivel > 2) &&
              <div className="type-of-product">
                {props.data.flg_obrigatorio ? 'Obrigatório' : 'Substituto'}
              </div>
            } */}

            {(props.data.num_nivel < ModeloPrecoBaseGranularidade.DESCRICAO.length - 2 ||
              (props.data.num_nivel == ModeloPrecoBaseGranularidade.DESCRICAO.length - 2 &&
                props.data.quantity > 0)) && (
                <Button className="no-border transparent d-flex align-items-center justify-content-center width-28 height-28" variant="light" size="sm" onClick={carregarDados} disabled={desabilitarCarregamento()}>
                  {carregar ?
                    <Image src={spinner} className="baixar-icon" />
                    : props.data.flg_aberto ?
                      <FaMinusCircle /> : <FaPlusCircle />
                  }
                </Button>
              )}
          </div>

          <span className="ms-1 w-100" onDoubleClick={copyText}>
            {/* {(props.data.num_nivel == 5 || props.data.num_nivel == 0) && (props.data as any)[ModeloPrecoBaseGranularidade.CODIGO[props.data.num_nivel]]?.toString() + " - "} */}
            {
              EditarTexto(
                (props.data as any)[ModeloPrecoBaseGranularidade.DESCRICAO[props.data.num_nivel]] ?
                  (props.data as any)[ModeloPrecoBaseGranularidade.DESCRICAO[props.data.num_nivel]]?.toString() : ("Vazio - " +
                    (props.data as any)[ModeloPrecoBaseGranularidade.CODIGO[props.data.num_nivel]]?.toString()), ModeloPrecoBaseGranularidade.DESCRICAO[props.data.num_nivel])
            }
            {/* -{ props.data.cod_familia_subgrupo_produto_capitao} */}
          </span>
        </div>
      </td>

      {/* Estoque */}
      <td>{carregandoValor(props.data.num_estoque, false, "num_estoque")}</td>
      <td>
        <div className="position-relative">
          {carregandoValor(props.data.num_estoque_compradores, false, "num_estoque_compradores")}<br />
          {carregandoValor(props.data.num_preco_compradores, true, "num_preco_compradores")}
          <Button className="rounded-circle info-compradores" size="sm" onClick={props.onModalBuyers}          >
            <FaInfo />
          </Button>
        </div>
      </td>


      {/* Tendência de Demanda */}
      <td>
        <b>S-1: </b>
        {carregandoValor(formatarPorcentagem(props.data.num_tendencia_demanda_s1), false, "num_tendencia_demanda_s1")}
        {props.data.num_tendencia_demanda_s1 > 0 ?
          <FaArrowUp color="green" />
          : props.data.num_tendencia_demanda_s1 < 0 ?
            <FaArrowDown color="red" />
            : <></>
        }

      </td>

      {/* Tendência de Preço */}
      <td>

        <b>S-1: </b>
        {carregandoValor(formatarPorcentagem(props.data.num_tendencia_preco_s1), false, "num_tendencia_preco_s1")}
        {props.data.num_tendencia_preco_s1 > 0 ?
          <FaArrowUp color="green" />
          : props.data.num_tendencia_preco_s1 < 0 ?
            <FaArrowDown color="red" />
            : <></>
        }

      </td>

      {/* Volume Vendido */}
      <td>{carregandoValor(props.data.num_volume_vendido, false, "num_volume_vendido")}</td>

      {/* Preço PMZ */}
      <td>{carregandoValor(props.data.num_preco_sem_margem, true, "num_preco_sem_margem")}</td>

      {/* Preço do Concorrente */}
      <td>
        {carregandoValor(props.data.num_preco_concorrente_media, true, "num_preco_concorrente_media")}
      </td>

      {/* Preço Tabela Atual */}
      <td>{carregandoValor(props.data.num_preco_atual_tabela, true, "num_preco_atual_tabela")}</td>

      {/* Margem do Preço Atual */}
      <td> {varicaoPreco(props.data.num_preco_atual_tabela, props.data.num_preco_sem_margem)}</td>

      {/* Demanada IA */}
      <td>{carregandoValor(props.data.num_demanda_sugerida_ia, false, "num_demanda_sugerida_ia")} </td>

      {/* Preço Sugerido IA */}
      <td>{carregandoValor(props.data.num_preco_sugerido_ia, true, "num_preco_sugerido_ia")}</td>

      {/* Variação de Preço */}
      <td> {varicaoPreco(props.data.num_preco_sugerido_ia, props.data.num_preco_sem_margem)}</td>

      {/* Automatizar */}
      <td className="text-center">
        {props.data.num_nivel >= 4 ? (
          <SwitchItem
            disabled={isSalved}
            checked={props.data.flg_automatizado}
            onChange={(event) => automateChange(event, props.data, props.index)}
          />
        ) : (
          "-"
        )}
      </td>

      {/* Aceitar */}
      <td className="text-center">
        {/* {props.data.num_preco_edicao} |
        {props.data.quantity} <br /> {props.data.quantity_math} */}
        {props.data.num_nivel >= 4 ? (
          <label className="checkbox">
            <input
              type="checkbox"
              id={"checkbox" + props.index}
              disabled={props.data.flg_automatizado || isSalved}
              checked={props.data.flg_aceito}
              onChange={(event) => {
                isAcceptedChange(event.currentTarget.checked, props.index);
              }}
            />
            <span className="checkmark"></span>
          </label>
        ) : (
          "-"
        )}
      </td>

      {/* Editar Preço */}
      <td className="text-center">
        {props.data.num_nivel >= 4 ? (
          <div className="position-relative">
            {/* {isSalved ? <>
              <NumericFormat
                disabled={true}
                type="tel"
                className="form-control"
              />
              <span className="input-icon">
                <Spinner size="sm" />
              </span>
            </> :
              <> */}
            <NumericFormat
              disabled={props.data.flg_automatizado || props.data.flg_aceito || isSalved}
              type="tel"
              className="form-control"
              decimalSeparator={","}
              thousandSeparator={"."}
              prefix={"R$ "}
              decimalScale={2}
              id={"numberFormat" + props.index}
              value={props.data.num_preco_edicao}
              onValueChange={(event: { floatValue: number | undefined; }) => {

                const num_preco_edicao = Math.round(((props.data.num_preco_edicao || 0) + Number.EPSILON) * 100) / 100;

                if (event.floatValue !== num_preco_edicao && props.data.num_preco_salvo != num_preco_edicao) {
                  props.data.num_preco_salvo = event.floatValue ? event.floatValue : 0;
                  savePriceEdit();
                }
              }}
              maxLength={14}
            />
            <span className="input-icon">
              <FaSave />
            </span>
          </div>
        ) :
          formatarDecimal(props.data.num_preco_edicao, true)
        }
      </td>
      {/* Δ Preço Editado X PMZ */}
      <td>
        <OverlayTrigger placement="top"
          overlay={
            <Tooltip className="show">
              <b>Preço Editado: </b> {formatarDecimal(props.data.num_preco_edicao, true)} <br />
              <b>Preço PMZ: </b> {formatarDecimal(props.data.num_preco_sem_margem, true)}
            </Tooltip>
          }>
          <div>
            {varicaoPreco(props.data.num_preco_edicao, props.data.num_preco_sem_margem)}
          </div>
        </OverlayTrigger>
      </td>
      {/* Δ Preço Editado X IA */}
      <td>
        <OverlayTrigger placement="top"
          overlay={
            <Tooltip className="show">
              <b>Preço Editado: </b> {formatarDecimal(props.data.num_preco_edicao, true)} <br />
              <b>Preço Sugerido IA: </b> {formatarDecimal(props.data.num_preco_sem_margem, true)}
            </Tooltip>
          }>
          <div>
            {varicaoPreco(props.data.num_preco_edicao, props.data.num_preco_sugerido_ia)}
          </div>
        </OverlayTrigger>
      </td>
    </>
  );
};


